import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import ApiClient from './ApiClient'

type MedicamentGetParams = {
  name?: string
  active_principle?: string
  nregister?: string
  specie?: string
}
class MedicamentService {
  public static getMedicamentList = async (medicamentParams: MedicamentGetParams, callback?: Function) => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, 'medicament', medicamentParams, callback)
    return response
  }

  public static getMedicament = async (id: number, callback?: Function) => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, `medicament/${id}`, undefined, callback)
    return response
  }
}

export default MedicamentService
