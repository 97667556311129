import { PermissionsAndroid, Platform } from 'react-native'
import FS from '../../libraries/react-native-fs'
import axios from 'axios'
//@ts-ignore
import { downloadFromBlobHibrid } from './functions/download'
//@ts-ignore
import { downloadFromUrlHibrid } from './functions/download'
import { check, request, PERMISSIONS, RESULTS } from 'react-native-permissions'

export const base64ToBlob = (base64: string) => {
  const pdfData = base64
  var byteNumbers = new Array(pdfData.length)
  for (var i = 0; i < pdfData.length; i++) {
    byteNumbers[i] = pdfData.charCodeAt(i)
  }
  var byteArray = new Uint8Array(byteNumbers)
  var blob = new Blob([byteArray], { type: 'pdf' })
  return blob
}

export const blobToBase64 = async (blob: any): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const result: string = reader.result as string
      const res = result.split('base64,')?.[1]
      resolve(res as string)
    }
    reader.readAsDataURL(blob)
  })
}

export const utf8ToBase64 = (utf8String: any) => {
  const Buffer = require('buffer').Buffer
  let encoded = new Buffer(utf8String).toString('base64')
  return encoded
}

export const createObjectURLFromBase64 = async (
  base64String: string,
  fileType?: string
): Promise<string> => {
  if (Platform.OS !== 'web') {
    const filePath = `${FS.DocumentDirectoryPath}/example.${fileType}`

    await FS.writeFile(filePath, base64String, 'base64')

    const objectURL = `file://${filePath}`
    return objectURL
  } else {
    var blob = base64ToBlob(base64String)
    return URL.createObjectURL(blob)
  }
}

export const downloadFromBlob = async (blob: any, fileName: string) => {
  await downloadFromBlobHibrid(blob, fileName)
}

export const downloadFromUrl = async (url: string, filePath: any) => {
  await downloadFromUrlHibrid(url, filePath)
}
