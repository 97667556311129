import { MutableRefObject, useRef } from 'react'
import { StateWrapperRef } from '../type'
import { useFocusCallback } from '../../../../hooks/useFocusCallback'

interface UseStateWrapperReloadConfiguration {
  reloadWhenFocus?: boolean
}

interface UseStateWrapperReloadReturn {
  ref: MutableRefObject<StateWrapperRef | undefined>
  reload: () => void
}

/**
 * Hook for managing reloading functionality for a state wrapper.
 *
 * @param {UseStateWrapperReloadConfiguration} [config] - The configuration options for the reload behavior.
 * @property {boolean} config.reloadWhenFocus - Determines whether to trigger a reload when the component gains focus. 
 * @returns {Object} An object containing the ref and reload function.
 * @property {React.MutableRefObject<StateWrapperRef>} ref - The mutable ref to the state wrapper.
 * @property {Function} reload - The function to trigger a reload of the state wrapper.
 */
export const useStateWrapperReload = (
  config?: UseStateWrapperReloadConfiguration
): UseStateWrapperReloadReturn => {
  const ref = useRef<StateWrapperRef>()
  const reload = () => {
    ref.current?.reload?.()
  }

  useFocusCallback(() => {
    config?.reloadWhenFocus && reload()
  })
  return { ref, reload }
}
