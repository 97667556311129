import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

interface Params {
  minWidthPerItem: number
  itemsPerRow: number
}
export const useAdaptativeItemsPerRow = ({ minWidthPerItem = 100, itemsPerRow = 1 }: Params) => {
  const calculateItemsPerRow = (width: number) => {
    const maxItems = Math.floor(width / minWidthPerItem) || 1
    return Math.min(maxItems, itemsPerRow)
  }
  const [itemsInRow, setItemsInRow] = useState(calculateItemsPerRow(Dimensions.get('window').width))

  const handleDimensionsChange = ({ window }: any) => {
    const { width } = window
    setItemsInRow(calculateItemsPerRow(width))
  }

  useEffect(() => {
    Dimensions.addEventListener('change', handleDimensionsChange)
  }, [])
  return itemsInRow
}
