import prescriptionI18nKeys from './i18n/keys'
import addPrescriptionI18nKeys from './i18n/keys'

export const optionsDropdownUsePrescription = (
  t: any
): { label: string; value: string }[] => [
  {
    label: t(addPrescriptionI18nKeys.INPUT_USE_PLACEHOLDER_PRESCRIPTION),
    value: '',
  },
  {
    label: t(addPrescriptionI18nKeys.INPUT_USE_OPTION_NORMAL_USE_PRESCRIPTION),
    value: 'Normal',
  },
]
export const optionsRadioButtonFrecuenceTreatment = (t: any) => [
  {
    value: '',
    label: t(addPrescriptionI18nKeys.INPUT_USE_PLACEHOLDER_PRESCRIPTION),
  },
  {
    value: 'Exceptional',
    label: 'Excepcional',
  },
  {
    value: 'Ordinary',
    label: 'Ordinario',
  },
]

export const optionsDropdownDispensableType = (
  t: any
): { label: string; value: string }[] => [
  {
    value: 'Dispensable',
    label: t(addPrescriptionI18nKeys.INPUT_OPTION_DISPENSABLE),
  },
  {
    value: 'No Dispensable',
    label: t(addPrescriptionI18nKeys.INPUT_OPTION_NO_DISPENSABLE),
  },
]

export const optionsTreatmentClass = (t: any) => [
  {
    label: t(addPrescriptionI18nKeys.INPUT_USE_PLACEHOLDER_PRESCRIPTION),
    value: '',
  },

  {
    value: 'Therapeutic',
    label: t(prescriptionI18nKeys.INPUT_CLASS_THEATMENT_OPTIONS_TERAPEUTIC),
  },
  {
    value: 'Metaphylactic',
    label: t(prescriptionI18nKeys.INPUT_CLASS_THEATMENT_OPTIONS_METAFILATIC),
  },
  {
    value: 'Prophylactic',
    label: t(prescriptionI18nKeys.INPUT_CLASS_THEATMENT_OPTIONS_PROFILACTIC),
  },
]

export const requiredFieldsPrescription = (t: any) => ({
  medicament: t(addPrescriptionI18nKeys.MEDICINE),
  med_quantity: t(addPrescriptionI18nKeys.INPUT_PLACEHOLDER_PACKAGING),
  caution_comment: t(
    addPrescriptionI18nKeys.INPUT_DESCRIPTION_LABEL_ADVICE_REGA
  ),
  treatment_duration: t(
    addPrescriptionI18nKeys.INPUT_PLACEHOLDER_DURATION_TREATMENT
  ),
  prescription_use: t(addPrescriptionI18nKeys.INPUT_USE_LABEL_PRESCRIPTION),
  expiration_date: t(addPrescriptionI18nKeys.INPUT_LABEL_EXPIRATION_DATE),
})
