import { useContext } from 'react'
import AnimalService from '../../services/AnimalService'
import { wrapService } from '../../utils/service'
import { DataContext } from '../../context/Data.context'
import CaseService from '../../services/CaseService'
import BreederService from '../../services/BreederService'
import PrescriptionService from '../../services/PrescriptionService'
import MedicamentService from '../../services/MedicamentService'

/**
 * A custom hook for accessing various services with caching capabilities.
 *
 * @returns {Object} An object containing wrapped service instances.
 * @property {typeof AnimalService} AnimalService - A wrapped instance of the AnimalService with caching.
 * @property {typeof CaseService} CaseService - A wrapped instance of the CaseService with caching.
 * @property {typeof BreederService} BreederService - A wrapped instance of the BreederService with caching.
 */
export const useServices = () => {
  const { removeCache, getData } = useContext(DataContext)

  return {
    AnimalService: wrapService(AnimalService, {
      cache: getData,
      updateCache: removeCache,
    }) as typeof AnimalService,
    CaseService: wrapService(CaseService, {
      cache: getData,
      updateCache: removeCache,
    }) as typeof CaseService,
    BreederService: wrapService(BreederService, {
      cache: getData,
      updateCache: removeCache,
    }) as typeof BreederService,
    PrescriptionService: wrapService(PrescriptionService, {
      cache: getData,
      updateCache: removeCache,
    }) as typeof PrescriptionService,
    MedicamentService: wrapService(MedicamentService, {
      cache: getData,
      updateCache: removeCache,
    }) as typeof MedicamentService,
  }
}
