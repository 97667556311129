import { TextStyle, ViewStyle } from 'react-native'

const styles = {
  title: {
    marginBottom: 30,
    fontWeight: 'normal'
  } as TextStyle,
  description: {
    marginBottom: 30
  } as TextStyle,
  input: {
    marginBottom: 30
  },
  link: {
    marginBottom: 60
  },
}
export default styles
