import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Breeder } from '../interfaces/Animal'
import ApiClient from './ApiClient'

class BreederService {
  public static getBreedersList = async () => {
    const breedersGetResponse: Breeder[] = await ApiClient.get(
      BACKEND_BASE_URL_V1,
      `breeders`
    )
    return breedersGetResponse
  }
  public static getBreeder = async (id: number) => {
    const breedersGetResponse: Breeder = await ApiClient.get(
      BACKEND_BASE_URL_V1,
      `breeders/${id}`
    )
    return breedersGetResponse
  }
}

export default BreederService
