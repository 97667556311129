/* eslint-disable camelcase */
import axios from 'axios'
//@ts-ignore
import AuthService from './authService'
import { objectToQuery } from '../utils/api'
import { CallBackParams } from '../utils/service'
import FS from '../libraries/react-native-fs'
import {
  base64ToBlob,
  createObjectURLFromBase64,
  utf8ToBase64,
} from '../utils/attachedFile'
import { Platform } from 'react-native'
export class ApiClientError extends Error {
  statusCode: number
  //@ts-ignore
  message: string
  name: string

  constructor(status: number, body: string) {
    super()

    if (body) {
      this.message = body
    }

    this.statusCode = status
    this.name = 'ApiClientError'
  }
}

class ApiClient {
  public static get(
    baseUrl: string,
    rute: string,
    data?: { [key: string]: any },
    callback?: any
  ) {
    return this._handleFetch(
      baseUrl,
      data ? `${rute}/?${objectToQuery(data)}` : rute,
      {
        method: 'GET',
      },
      callback
    )
  }

  private static formatData(data: any, headers: any) {
    let auxData: any = undefined
    if (data && headers['Content-Type'] == 'multipart/form-data') {
      auxData = new FormData()
      Object.entries(data).forEach(([key, value]: any) => {
        value && auxData.append(key, value)
      })
    } else if (data) {
      auxData = JSON.stringify(data)
    }
    return auxData
  }
  public static post(
    baseUrl: string,
    rute: string,
    data: any,
    callback?: any,
    headers: any = {}
  ) {
    const formedData = this.formatData(data, headers)
    return this._handleFetch(
      baseUrl,
      rute,
      {
        method: 'POST',

        //@ts-ignore
        data: formedData,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      },
      callback
    )
  }

  public static patch(
    baseUrl: string,
    rute: string,
    data: any,
    callback?: any,
    headers:any ={}
  ) {
    return this._handleFetch(
      baseUrl,
      rute,
      {
        method: 'PATCH',
        //@ts-ignore
        data: data ?? null,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      },
      callback
    )
  }

  public static put(
    baseUrl: string,
    rute: string,
    data: any,
    callback?: CallBackParams,
    headers: any = {}
  ) {
    const formedData = this.formatData(data, headers)
    return this._handleFetch(
      baseUrl,
      rute,
      {
        method: 'PUT',
        //@ts-ignore
        data: formedData ?? null,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      },
      callback
    )
  }

  public static delete(
    baseUrl: string,
    rute: string,
    callback?: CallBackParams
  ) {
    return this._handleFetch(
      baseUrl,
      rute,
      {
        method: 'DELETE',
      },
      callback
    )
  }

  // eslint-disable-next-line no-undef
  private static async _handleFetch(
    baseUrl: string,
    rute: string,
    options: RequestInit,
    callback?: CallBackParams
  ) {
    try {
      const url = `${baseUrl}/${rute}`
      const { credential }: any = await AuthService.getAuthInfo()
      const cacheData = (
        options.method === 'GET' ? await callback?.cache?.(rute) : undefined
      ) as any
      const data =
        cacheData ??
        (await axios
          .request(
            // @ts-ignore
            {
              ...options,
              url,
              headers: {
                ...options.headers,
                ...(credential && { Authorization: `Basic ${credential}` }),
              },
            }
          )
          .then((response) => {
            if (response.status < 200 || response.status >= 300) {
              throw new ApiClientError(response.status, response.data)
            } else return response.data
          }))

      options.method !== 'GET' && (await callback?.updateCache?.(rute))
      callback?.onSuccess?.(data)
      return data
    } catch (error) {
      if (callback?.onError) {
        callback?.onError?.(error as any)
      } else {
        throw error
      }
    }
  }


  public static async getDownloadBlob(url: string) {
    const { credential }: any = await AuthService.getAuthInfo()
    const newData = await axios.get(url, {headers:{Authorization: `Basic ${credential}`}}).then((response: any) => {
      var blob = base64ToBlob(response.data)
      return blob
    })
    return newData
  }
}

export default ApiClient
