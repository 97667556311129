import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import EvidenceDetail from '.'
import { getCurrentTranslation } from '../../utils/i18n'

const ScreenName = SCREEN.EvidenceDetail

i18next.addResourceBundle('es', ScreenName, es)

const EvidenceDetailConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: EvidenceDetail,
  options: { title: getCurrentTranslation({ es }).TITLE },
}

export default EvidenceDetailConfig
