import IZFormat from '../IZFormat'
import inputSearchAnimalIdentifierI18nKeys from './i18n/keys'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import localeES from './i18n/es'
import AnimalIDOption from './components/AnimalIDOption'
import { bundleName } from './constants'
import { FormatContext } from '../IZFormat/context/format.context'
import { ContextReRendsAvoiderWrapper } from '../../../utils/context'
//@ts-ignore
import { Props } from './types'
import { Animal } from '../../../interfaces/Animal'

/**
 * AnimalIdentifierInputSearch is a React component that provides an input field for searching animal identifiers and updates the FormatContext's animal_id with the selected value.
 *
 * @param {Object} props - The component's properties.
 * @param {ViewStyle} [props.style] - Optional styling for the component.
 * @param {any} [props.initialValue] - The initial value for the input field.
 * @param {string} [props.value] - The current value of the input field.
 * @param {boolean} [props.editable] - Determines if the input field is editable. Defaults to true.
 * @param {boolean} [props.multiline] - Determines if the input field supports multiline input.
 * @param {Function} [props.labelOptionTransform] - A function to transform the label of options in the input.
 * @param {string} [props.label] - The label for the input field.
 */
const AnimalInputIdentifier = (props: Props & { contextData?: any }) => {
  const animalId = props.contextData?.format?.animal_id ?? props.initialValue ?? []

  const rega = props.contextData?.format?.rega_id
  const valueExtraction = (animal: Animal) => ({
    ...animal,
    value: animal?.id!,
    label: animal?.identifier,
  })
  const formatedAnimalId = animalId.map(valueExtraction)
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  return (
    <IZFormat.InputSearch
      {...props}
      options={props.options.filter((animal: Animal) => !rega?.id || animal.breeder.id === rega?.id)}
      value={formatedAnimalId}
      name={'animal_id'}
      multiline
      renderOptions={(animal: any) => <AnimalIDOption animal={animal} t={t} />}
      key={`generalInformationInputs-${'animal_id'}`}
      placeholder={t(inputSearchAnimalIdentifierI18nKeys.INPUT_PLACEHOLDER)}
      label={props.label ?? t(inputSearchAnimalIdentifierI18nKeys.INPUT_ANIMAL_ID)}
      footer={props.footer}
    />
  )
}

const rerenderParamsListExtractor = (contextData: any) => {
  return [contextData.format.rega_id, contextData.format.animal_id]
}

export default ContextReRendsAvoiderWrapper(AnimalInputIdentifier, {
  Context: FormatContext,
  rerenderParamsListExtractor,
})
