//@ts-ignore
import IZStickyButton from '../../components/atomic-components/StickyButton'
import MedicalCasesList from './components/MedicalCasesList'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../constants/screens'
import medicalCasesI18nKeys from './i18n/keys'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import { Platform } from 'react-native'

export default function MedicalCases({ navigation }: any) {
  const { t } = useTranslation(SCREEN.MedicalCases)

  return (
    <IZScreenContainer
      headerHeight={Platform.OS == 'web' ? 220 : 120}
      footer={
        <IZStickyButton
          icon={'plus'}
          onPress={() => navigation.navigate(SCREEN.NewCase)}
        >
          {t(medicalCasesI18nKeys.BUTTON_NEW_CASE)}
        </IZStickyButton>
      }
    >
      <MedicalCasesList />
    </IZScreenContainer>
  )
}
