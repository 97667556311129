import { Button as BaseButton, ButtonProps } from 'react-native-paper'
import { Button as RNButton, View } from '../../../../libraries/react-native'
import style from '../styled'
import { ColorKeys, getColorByBackground, getThemeColor } from '../../../../utils/colors'
import ButtonText from './ButtonText'


const IZStickyButton = (props: ButtonProps & {textStyle?:any}) => {
  const themeColor =  getThemeColor(ColorKeys.primary)

  // @ts-ignore
  const Button = RNButton as typeof BaseButton
  return (
    <View style={style.buttonContainer}>
      <Button
        {...props}
        mode="contained"
        style={style.button}
        labelStyle={{ fontSize: 24 }}
        theme={{
          colors: {
            primary:themeColor,
          },
          ...props.theme,
        }}
      >
        <ButtonText
          style={{
            color: getColorByBackground(ColorKeys.text,themeColor),
            ...props.textStyle
          }}
        >
          {props.children}
        </ButtonText>
      </Button>
    </View>
  )
}

export default IZStickyButton
