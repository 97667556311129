import { Platform } from 'react-native'
import CheckBoxNative from './index.native'
let CheckBoxHibrid

if (Platform.OS === 'web') {
  CheckBoxHibrid = require('./index.web').default
} else {
  CheckBoxHibrid = require('./index.native').default
}

export default CheckBoxHibrid as typeof CheckBoxNative
