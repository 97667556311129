import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { encode } from '../utils/encode'
import ApiClient from './ApiClient'
import CookieService from './CookieService'

export interface AuthParams {
  username: string
  password: string
  remember?: boolean
}

type AuthenticateResponse = {
  refresh: string
  access: string
  user_id: number
  username: string
  email: string
}

class AuthService {
  public static authenticate = async (params: AuthParams) => {
    params.remember = params.remember ?? false

    const authenticateResponse: AuthenticateResponse = await ApiClient.post(
      BACKEND_BASE_URL_V1,
      'api-token-auth',
      params
    )
    try {
      const { access, username, refresh, user_id, email } = authenticateResponse

      const password = params.password

      const credentials = `${username}:${password}`

      const base64Credentials = encode(credentials)

      const { CREDENTIAL, TOKEN, USERNAME, REFRESH_TOKEN, EMAIL, USER_ID } =
        CookieService.LABELS
      if (!!username && !!password) {
        await CookieService.storeData(
          CREDENTIAL,
          base64Credentials,
          params.remember
        )
      }
      access && (await CookieService.storeData(TOKEN, access, params.remember))
      username &&
        (await CookieService.storeData(USERNAME, username, params.remember))

      refresh &&
        (await CookieService.storeData(REFRESH_TOKEN, refresh, params.remember))
      email && (await CookieService.storeData(EMAIL, email, params.remember))
      user_id &&
        (await CookieService.storeNumericData(
          USER_ID,
          user_id,
          params.remember
        ))
    } catch (error) {}

    return authenticateResponse
  }

  public static logout =()=>{
    CookieService.removeAllCookies()
  }

  public static getAuthInfo = async (): Promise<
    Partial<AuthenticateResponse> & { credential?: string }
  > => {
    try {
      const { CREDENTIAL, TOKEN, USERNAME, USER_ID, REFRESH_TOKEN, EMAIL } =
        CookieService.LABELS

      const username = await CookieService.getData(USERNAME)
      const credential = await CookieService.getData(CREDENTIAL)
      const access = await CookieService.getData(TOKEN)
      const user_id = await CookieService.getNumericData(USER_ID)
      const refresh = await CookieService.getData(REFRESH_TOKEN)
      const email = await CookieService.getData(EMAIL)

      return { refresh, access, credential, user_id, username, email }
    } catch (error) {
      return {}
    }
  }
}

export default AuthService
