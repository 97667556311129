import { useState } from 'react'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import { TouchableOpacity, View } from '../../../libraries/react-native'
import { IconButton } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Modal } from 'react-native'
import styles from '../styles'
import Medication from './Medication'
import prescriptionI18nKeys from '../i18n/keys'

interface Props {
  medication: any
  index: number
  t: (s: string) => string
}
const TreatmentPrescribedOption = (props: Props) => {
  const [visible, setVisible] = useState(false)
  return (
    <View
      style={{
        ...styles.card,
        backgroundColor:
          props.index % 2 == 0 ? getThemeColor(ColorKeys.background) : getThemeColor(ColorKeys.softBackground),
      }}
    >
      <IconButton icon='information-outline' style={styles.iconButton} size={20} onPress={() => setVisible(true)} />
      <DescriptionText>{props.medication.national_code} - {props.medication.presentation_name}</DescriptionText>

      <Modal visible={visible} transparent={false}>
        <View style={styles.modalContent}>
          <View style={styles.optionContainer}>
            <View style={styles.optionHeaderLeftContainer}>
              <IconButton
                icon='information-outline'
                style={styles.iconButton}
                size={20}
                onPress={() => setVisible(true)}
              />
              <DescriptionText>{props.t(prescriptionI18nKeys.MEDICATION_MORE_INFO_TITLE)}</DescriptionText>
            </View>
            <TouchableOpacity style={styles.closeButton} onPress={() => setVisible(false)}>
              <DescriptionText>x</DescriptionText>
            </TouchableOpacity>
          </View>
          <Medication medication={props.medication} t={props.t} />
        </View>
      </Modal>
    </View>
  )
}

export default TreatmentPrescribedOption
