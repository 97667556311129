import TextStyled from '..'
import { TouchableOpacity } from '../../../../libraries/react-native'
import { ColorKeys, getThemeColor } from '../../../../utils/colors'

const Link = (props: any) => {
  return (
    <TouchableOpacity onPress={props.onPress} style={props.style}>
      <TextStyled
        {...props}
        style={{
          color: getThemeColor(ColorKeys.primary),

          fontSize: 14,
          lineHeight: 20,
          letterSpacing: 1,
          ...props.style,
        }}
      />
    </TouchableOpacity>
  )
}

export default Link
