import { Dimensions, Platform, TextStyle, ViewStyle } from 'react-native'

const paddingLeftScreen = 16
const sectionTextFontSize = 16
const styles = {
  title: {
    fontSize: sectionTextFontSize,
  } as TextStyle,
  buttonContainer:{
    marginTop: 30,
  } as ViewStyle,
  inputContainer: {
  } as ViewStyle,
  input: {
    marginTop: Platform.OS === 'web' ? 0 : 30,
    marginRight: Platform.OS === 'web' ? 30 : 0,
  } as ViewStyle,
  diagnosis: {
    fontSize: sectionTextFontSize,
    marginTop: 50,
    marginBottom: 40,
  } as ViewStyle,
  pillContent: {
    paddingLeft: paddingLeftScreen,
  } as ViewStyle,
  pill: {
    marginRight: 15,
    marginTop: 10,
  } as ViewStyle,
  description: {},
  description_date: {
    fontSize: 16,
    letterSpacing: 1,
  } as TextStyle,
  button_add_diagnosis: {
    marginTop: 34,
  } as ViewStyle,
  button_save: {
    marginTop: 50,
    marginBottom: 30,
  } as ViewStyle,
}
export default styles
