const localeES = {
  BUTTON_NEW_CASE: 'Nuevo caso',
  TEXT_DIAGNOSIS: 'Diagnóstico: {{diagnosis}}',
  SEARCH_PLACEHOLDER: 'Buscar',
  LINK_NEW_ANIMAL: 'Añadir nuevo animal o lote',
  GET_CASE_ERROR: 'Ha habido un error a la hora de obtener la lista de casos',
  TEXT_REGA: 'Ganaderia: {{rega_id}}',
  TEXT_ID: 'Animal/es: {{animal_identifier}}',
  TEXT_BATCH_ID: 'Lote: {{batch_id}}',
  TEXT_CASES_LIST: 'Mis Casos',
  BUTTON_LOGOUT: 'Cerrar Sesión',
}

export default localeES
