import React, { useState } from 'react'
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
  AccordionList,
  //@ts-ignore
} from 'accordion-collapse-react-native'
import { DescriptionText } from '../../atomic-components/Text/variants'
import IZCard from '../../atomic-components/IZCard'
import { styles } from './style'
import { Icon } from '../../../libraries/react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Platform, ViewStyle } from 'react-native'
import { getPrioritaryItem } from '../../../utils/objects'

interface Props {
  style?: ViewStyle
  headerTitle?: string
  children?: JSX.Element | (JSX.Element | false)[] | false
  isExpanded?: boolean
  initialExpanded?: boolean
  styleContainer?: ViewStyle
}
const IZCollapse = (props: Props) => {
  const [isInternalyExpanded, setIsInternalyExpanded] = useState(undefined)
  const isExpanded = getPrioritaryItem({
    value: props.isExpanded,
    initialValue: props.initialExpanded,
    stateValue: isInternalyExpanded,
  })
  return (
    <Collapse
      style={props.style}
      isExpanded={isExpanded}
      onToggle={(isExpanded: any) => setIsInternalyExpanded(isExpanded)}
    >
      {props.headerTitle && (
        <CollapseHeader>
          <IZCard style={styles.cardContainer} borderColor={getThemeColor(ColorKeys.primary)}>
            <DescriptionText>{props.headerTitle}</DescriptionText>
            <Icon style={{ marginLeft: Platform.OS === 'web' ? 0 : -10 }} icon={isExpanded ? 'menu-up' : 'menu-down'} />
          </IZCard>
        </CollapseHeader>
      )}
      <CollapseBody style={{ ...styles.contentContainer, ...props.styleContainer }}>{props.children}</CollapseBody>
    </Collapse>
  )
}

export default IZCollapse
