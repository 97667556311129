import React from 'react'
import { View } from '..'
import { ViewProps, ViewStyle } from 'react-native'

type Props = ViewProps & { style?: any; source: any }
const ImageBackgroundWeb = (props: Props) => {
  return (
    // @ts-ignore
    <div
      {...props}
      style={
        {
          backgroundImage: `url(${props.source.uri ?? props.source})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top right',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
        } as React.CSSProperties
      }
    />
  )
}

export default ImageBackgroundWeb
