import i18next from "i18next"

/**
 * Gets the current translation object based on the selected language.
 *
 * @param {Object} languageMap - An object that maps language keys to translation objects.
 * @returns {Object} The translation object for the current language.
 */
export const getCurrentTranslation = <T>(languageMap: Record<string, T>):T => {
  type languageMapType = keyof typeof languageMap
  const languageKeys = Object.keys(languageMap) as languageMapType[]
  const i18nLanguage = i18next.language
  const auxcurrentLanguage = languageKeys.includes(i18nLanguage)
    ? i18nLanguage
    : 'es'

  const currentLanguage = languageMap[auxcurrentLanguage]

  return currentLanguage
}
