import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import AddAnimal from '.'

const ScreenName = SCREEN.AddAnimal

i18next.addResourceBundle('es', ScreenName, es)

const languageMap = { es }
type languageMapType = keyof typeof languageMap
const languageKeys: languageMapType[] = Object.keys(
  languageMap
) as languageMapType[]
const i18nLanguage: any = i18next.language

const auxcurrentLanguage: languageMapType = languageKeys.includes(i18nLanguage)
  ? i18nLanguage
  : 'es'

const currentLanguage = languageMap[auxcurrentLanguage]
const AddAnimalConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: AddAnimal,
  options: { headerTitle: currentLanguage.TITLE, headerBackTitleVisible: true },
}

export default AddAnimalConfig
