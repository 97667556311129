const localeES = {
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFAULT: '---',
  INPUT_TYPE_DIAGNOSIS_OPTION_LABEL: 'Tipo de diagnóstico',
  INPUT_TYPE_DIAGNOSIS_OPTION_SYMPTOMATIC: 'Sintomático',
  INPUT_TYPE_DIAGNOSIS_OPTION_INDIRECT: 'Indirecto',
  INPUT_TYPE_DIAGNOSIS_OPTION_ETIOPATHOGENIC: 'Etiopatogénico',
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFINITIVE: 'Definitivo',
  INPUT_TYPE_DIAGNOSIS_OPTION_DIRECT: 'Directo',
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFERRED: 'Diferido (Necropsia)',
}

export default localeES
