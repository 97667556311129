import { Dimensions, TextStyle, ViewStyle } from 'react-native'

const styles = {
  container: {
    padding: 20,
    paddingTop: 40
  } as ViewStyle,
  checkBoxContainer: {
    paddingHorizontal: 14,
  },
  inputStyle: {
    marginTop: 10,
  },
  radioButtonContainer: {
    justifyContent: 'space-between',
    paddingHorizontal: 14,
    marginTop: 42,
  } as ViewStyle,
  nAnimalsInputStyle: {
  },
  buttonStyle: {
  },
}
export default styles
