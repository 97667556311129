import { KeyboardAvoidingView, Platform, StyleSheet, ViewStyle, Dimensions } from 'react-native'
import { useContext } from 'react'
import { ModalContext, ModalContextProps } from '../../../context/Modal.context'
import { Modal } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Icon, View } from '../../../libraries/react-native'
import { DescriptionText } from '../../atomic-components/Text/variants'
export default function ModalWrapper() {
  const isWeb = Platform.OS === 'web'
  const { modal, hideModal }: ModalContextProps = useContext(ModalContext)
  const { onCancel, Component, isVisible, modalContainerStyle, modalContainerProps, modalStyle } = modal
  const { Component: AuxComponent, title, mode = 'fullscreen', headerStyle = {}, ...modalProps } = modal
  const modalStyleDefault = {
    backgroundColor: getThemeColor(ColorKeys.background),
    padding: 20,
    borderRadius: 10,
    justifyContent: 'flex-start',
    maxHeight: Dimensions.get('window').height - 200,
  }
  const handleOnCancel = () => {
    onCancel?.()
    hideModal()
  }

  const handleOnAccept = (propsResponse?: any) => {
    modal.onAccept?.(propsResponse)
    hideModal()
  }
  return (
    <Modal
      style={{ marginTop: 72, paddingHorizontal: 20 }}
      visible={isVisible}
      onDismiss={handleOnCancel}
      contentContainerStyle={{ ...modalStyleDefault, ...modalContainerStyle } as ViewStyle}
      //@ts-ignore
      modalContainerStyle={{ ...modalStyle } as ViewStyle}
      {...modalContainerProps}
    >
      <KeyboardAvoidingView behavior={'height'} style={{ maxHeight: Dimensions.get('window').height - 100 }}>
        <View>
          <View
            style={{
              borderRadius: 10,
              flexDirection: 'row',
              alignSelf: 'flex-end',
              justifyContent: 'space-between',
              marginHorizontal: 24,
              marginBottom: 18,
              width:'100%',
              ...headerStyle,
            }}
          >
            {title ? <DescriptionText style={{marginLeft:20}}>{title}</DescriptionText> : <View />}
            <View>
              <Icon icon={'close'} onPress={handleOnCancel} style={{ zIndex: 1 }} />
            </View>
          </View>
          {!!Component ? <Component {...modalProps} onCancel={handleOnCancel} onAccept={handleOnAccept} /> : <></>}
        </View>
      </KeyboardAvoidingView>
    </Modal>
  )
}
