import { Platform } from 'react-native'
import { View } from 'react-native'
import { getThemeColor, ColorKeys } from '../../../utils/colors'
import { Button } from 'react-native-paper'
import IZAdaptiveContainer from '../../../components/containers/IZAdaptiveContainer'
import styles from '../styles'

export type ButtonControlProps = {
  onPress: () => void
  mode: 'text' | 'outlined' | 'contained' | 'elevated' | undefined
  text: string
}

type ButtonsControlProps = {
  buttons: ButtonControlProps[]
}

const ButtonsControl = ({ buttons }: ButtonsControlProps) => {
  return (
    <View style={styles.buttonsControlContainer}>
      {buttons.map((button, index) => (
        <Button
          key={index}
          mode={button.mode}
          onPress={button.onPress}
          style={styles.buttonControl({ button, length: buttons.length })}
        >
          {button.text}
        </Button>
      ))}
    </View>
  )
}

export default ButtonsControl
