import React from 'react'
import IZFormat from '../../../components/templates/IZFormat'
import addDiagnosisI18nKeys from '../i18n/keys'
import { SCREEN, STACKS } from '../../../constants/screens'
import useMessage from '../../../hooks/useMessage'
import { useServices } from '../../../hooks/useServices/useServices'
import AnimalService from '../../../services/AnimalService'
import addAnimalI18nKeys from '../i18n/keys'
import styles from '../styles'
import { toArray } from '../../../utils/objects'

interface Props {
  t: (s: string) => string
  navigation: any
  animalSelected:any
  route?: any
}
const SaveButton = (props: Props) => {
  const { showMessage } = useMessage()
  const { AnimalService } = useServices()

  const onHandlePressSaveAnimal = async (format: any) => {
    try {
      const animal = await AnimalService.createAnimal(format)
      showMessage({
        content: props.t(addAnimalI18nKeys.ADD_ANIMAL_SUCCESS),
        type: 'success',
      })
      setTimeout(() => {
        props.navigation.navigate(
          //@ts-ignore
          props.route?.params?.lastScreen ?? SCREEN.MedicalCases,
          {
            animalSelected: { ...props.animalSelected, animal_id: toArray(animal) },
          }
        )
      }, 100)
    } catch (error) {
      showMessage({
        content: props.t(addAnimalI18nKeys.ADD_ANIMAL_ERROR),
        type: 'error',
      })
    }
  }
  return (
    <IZFormat.Button
      style={styles.buttonStyle}
      onPress={onHandlePressSaveAnimal}
    >
      {props.t(addAnimalI18nKeys.BUTTON_ADD)}
    </IZFormat.Button>
  )
}

export default SaveButton
