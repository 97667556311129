import React, { useEffect } from 'react'
//@ts-ignore
import useDeepLinking from '../../../hooks/useDeepLinking'
import { SCREEN, STACKS } from '../../../constants/screens'
import { useRoute } from '@react-navigation/native'

export const DeepLinkingWrapper = (Component: (props: any) => JSX.Element) => (props: any) => {
  const onHandleUrl = async (encodedOpenidUri: string) => {
    if (encodedOpenidUri.includes('password_reset') && props.route.name !== SCREEN.UpdatePassword) {
      props.navigation.navigate(STACKS.Auth, {
        screen: SCREEN.UpdatePassword,
        params: {
          token: encodedOpenidUri.split('password_reset/')[1].split('&')[0],
        },
      })
    }
  }
  useDeepLinking({ onHandleUrl, currentScreen: props.route.name })
  return <Component {...props} />
}
