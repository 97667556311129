import { ViewStyle } from 'react-native'
const styles = {
  container: {
    padding: 20,
    justifyContent: 'space-between',
  } as ViewStyle,
  contentContainer: {
    paddingBottom: 30,
    justifyContent: 'space-between',
  } as ViewStyle,
  buttonContainer: {
    marginVertical: 15,
  } as ViewStyle,
}
export default styles
