import React, { useContext, useEffect } from 'react'
import { FormatContext } from '../context/format.context'
import IZDropdown, {
  IZDropdownProps,
} from '../../../atomic-components/IZDropdown'
import { useAutoFill } from '../hooks/useAutoFill'
import FormWrapper from '../wrappers/FormWrapper'

const FormatDropDown = (props: IZDropdownProps & { name: string }) => {
  const FormDropdown = FormWrapper(IZDropdown, {
    nameParamOnChange: 'onChange',
  })
  return <FormDropdown {...props} />

}

export default FormatDropDown
