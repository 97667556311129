import { useEffect, useState } from 'react'
import { Animal, Breeder } from '../../../../interfaces/Animal'
import { getValueExtracted } from '../../../atomic-components/IZInputSearch/utils/valueExtractor'

export const useUpdateRega = (props: any) => {
  const animal_id = props.contextData?.format?.animal_id
  const rega_id: Breeder = props.contextData?.format?.rega_id
  const setFormat = props.contextData.setFormat
  const [regaId, setRegaId] = useState<any>(rega_id)

  useEffect(() => {
    ;(async () => {
      try {
        const animal: Animal = animal_id[0]
        if (animal && animal.breeder?.id !== rega_id?.id) {
          const rega = animal?.breeder
          const formatedRega = getValueExtracted(rega, props.valueExtraction)
          setRegaId(formatedRega)
          setFormat((currentState: any) => ({
            ...currentState,
            rega_id: formatedRega,
          }))
        }
      } catch (error) {}
    })()
  }, [])
  return regaId
}
