import React, { useState, useEffect } from 'react'
import useMessage from '../../../hooks/useMessage'
import { ViewStyle } from 'react-native'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
interface Props {
  wrapperStyle: ViewStyle
  visible: boolean
  duration: number
  onDismiss: () => void
  style: ViewStyle
  children: string
}
const Snackbar = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const snackbarContentStyleDefault = {
    position: 'fixed',
    bottom: isVisible ? '20px' : '-200px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#333',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    transition: 'bottom 0.3s ease-in-out',
    width: '40%'
  }
  useEffect(() => {
    if (props.visible) {
      setIsVisible(true)
      const timeout = setTimeout(() => {
        setIsVisible(false)
        props.onDismiss()
      }, props.duration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [props.visible])

  return (
    <div
      style={
        {
          ...snackbarContentStyleDefault,
          ...props.style
        } as any
      }
    >
      <DescriptionText style={{ alignContent: 'center' }} color={getThemeColor(ColorKeys.invertedText)}>
        {props.children}
      </DescriptionText>
    </div>
  )
}

export default Snackbar
