import { useContext, useEffect } from 'react'
import { FormatContext } from '../context/format.context'

export const useAutoFill = (name: string, initialValue: any) => {
  const { format, setFormat } = useContext(FormatContext)

  useEffect(() => {
    name &&
      !format[name] &&
      initialValue !== undefined &&
      setFormat((format: any) => ({ ...format, [name]: initialValue }))
  }, [])
}
