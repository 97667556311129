import i18next from '../../i18n.config'
import { SCREEN } from "../../constants/screens"
import { navigationScreensConfigType } from "../../navigation/types"
import es from "./i18n/es"
import RecoverAccess from '.'

const ScreenName = SCREEN.RecoverAccess

i18next.addResourceBundle("es", ScreenName, es)

const RecoverAccessConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: RecoverAccess,
  options: { headerShown: false },
}

export default RecoverAccessConfig
