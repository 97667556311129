import TextStyled from "..";
//@ts-ignore
import { TextStyle } from "react-native";
import { TextProps } from "react-native-paper";

const DescriptionText = (
  props: TextProps<any> & { color?: string; bold?: boolean }
) => {
  return (
    <TextStyled
      {...props}
      style={
        {
          fontSize: 14,
          lineHeight: 20,
          letterSpacing: 1,

          ...(props.style as TextStyle),
        } as TextStyle
      }
    />
  );
};

export default DescriptionText;
