import React, { useEffect } from 'react'

const FilePickerButton = {
  pick: async() => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '*/*' 

    return await  new Promise((resolve)=>{
        fileInput.addEventListener('change', (e) => {
          resolve(fileInput.files)
        })
    
        fileInput.click()

    })

  }, 
}

export default FilePickerButton
