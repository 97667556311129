import { useContext, useState } from 'react'
import {
  DescriptionText,
  ErrorText,
  Link,
} from '../../components/atomic-components/Text/variants'
import { Image, TouchableOpacity } from '../../libraries/react-native'
import styles from './styles'
import IZInput from '../../components/atomic-components/IZInput'
import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import loginI18nKeys from './i18n/keys'
import IZButton from '../../components/atomic-components/Button'
//@ts-ignore
import Logotype from '../../assets/images/logotype.png'
import AuthContainer from '../../components/containers/AuthContainer'
import AuthService from '../../services/authService'
import { DataContext } from '../../context/Data.context'
import { Linking } from 'react-native'

interface Props {
  navigation: any
}
export default function Login(props: Props) {
  const [format, setFormat] = useState({
    user: '',
    password: '',
  })
  const [isLogin, setIsLogin] = useState(false)
  const [isError, setIsError] = useState(false)
  const { t } = useTranslation(SCREEN.Login)
  const { fetchAllData } = useContext(DataContext)

  const onHandleButtonPress = async () => {
    setIsError(false)
    setIsLogin(true)
    try {
      const authenticationResponse = await AuthService.authenticate({
        username: format.user,
        password: format.password,
      })
      if (authenticationResponse.access) {
        await fetchAllData()
        props.navigation.navigate(STACKS.Main)
      } else {
        setIsError(true)
      }
    } catch {
      setIsError(true)
    }
    setIsLogin(false)
  }

  const onHandleForgetPasswordLinkPress = () => {
    props.navigation.navigate(SCREEN.RecoverAccess)
  }
  const onHandlePrivacyPolicy = () => {
    props.navigation.navigate(SCREEN.PrivacyPolicy)
  }

  const onHandleTermsAndConditions = () => {
    props.navigation.navigate(SCREEN.TermsAndConditions)
  }
  const onHandleChangeText = (name: string) => (text: string) => {
    setFormat({ ...format, [name]: text })
    setIsError(false)
  }

  return (
    <AuthContainer>
      <Image style={styles.image} source={Logotype} alt="logo" />
      <IZInput
        value={format.user}
        onChangeText={onHandleChangeText('user')}
        placeholder={t(loginI18nKeys.USER)}
        label={t(loginI18nKeys.USER)}
        style={styles.input}
      />
      <IZInput
        secureTextEntry
        value={format.password}
        style={styles.input}
        onChangeText={onHandleChangeText('password')}
        placeholder={t(loginI18nKeys.PASSWORD)}
        label={t(loginI18nKeys.PASSWORD)}
      />
      {isError && (
        <ErrorText style={styles.errorText}>{t(loginI18nKeys.ERROR)}</ErrorText>
      )}

      <Link
        style={styles.forgetPasswordContainer}
        onPress={onHandleForgetPasswordLinkPress}
      >
        {t(loginI18nKeys.TEXT_FORGET_PASSWORD)}
      </Link>
      <Link onPress={onHandlePrivacyPolicy} style={{ marginTop: 8 }}>
        {t(loginI18nKeys.LINK_PRIVACY_POLICY)}
      </Link>
      <Link onPress={onHandleTermsAndConditions} style={{ marginTop: 4 }}>
        {t(loginI18nKeys.LINK_TERMS_AND_CONDITIONS)}
      </Link>
      <IZButton
        style={styles.button}
        onPress={onHandleButtonPress}
        loading={isLogin}
      >
        {t(loginI18nKeys.BUTTON_LOGIN)}
      </IZButton>
    </AuthContainer>
  )
}
