import { InputSearchModalProps } from '../../IZInput/types'

export const getPrincipalProps = (props: any) => {
  const { style, multiline, value, initialValue, valueExtraction, placeholder, ...restProps } = props
  const principalProps = {
    style,
    multiline,
    value,
    initialValue,
    valueExtraction,
    placeholder,
  }
  return { principalProps, restProps }
}

export const getModalProps = (props: any) => {
  const {
    renderOptions,
    footer,
    searchFilter,
    optionsContainerStyle,
    headerStyle,
    headerTitle,
    isOptionsLoading,
    headerScheme,
    ...restProps
  } = props
  const modalProps: InputSearchModalProps = {
    renderOptions,
    footer,
    searchFilter,
    isOptionsLoading,
    optionsContainerStyle,
    headerStyle,
    headerTitle,
    headerScheme,
  }
  return { modalProps, restProps }
}
