type CacheProps = {
  name: string
  method: 'GET' | 'POST' | 'PUT'
}
export type CallBackParams = {
  cache?: (x: any) => void
  onError?: (x: string) => void
  onSuccess?: (x: string) => void
  updateCache?: (x: string) => void
}

export const wrapService = <T>(Service: T, callback?: CallBackParams) => {
  const wrapServiceEntries = Object.entries(Service as any).map(
    ([key, value]: any) => {
      const wrappedFunction = (...args: any) => {
        return value(...args, callback)
      }
      return [key, wrappedFunction]
    }
  )
  return Object.fromEntries(wrapServiceEntries)
}
