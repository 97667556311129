import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import MedicalCases from '.'
import HeaderPrincipal from './components/HeaderPrincipal'
import { Dimensions, Platform } from 'react-native'

const ScreenName = SCREEN.MedicalCases

i18next.addResourceBundle('es', ScreenName, es)
const isMobileWeb =
  Dimensions.get('window').width < 690 && Platform.OS === 'web'
const MedicalCasesConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: MedicalCases,
  options: {
    headerShown: true,
    headerBackVisible: false,
    headerTitle: HeaderPrincipal,
   ...(isMobileWeb ? {headerStyle:  { height: 150 } as any}:{})
  },
}

export default MedicalCasesConfig
