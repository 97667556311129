const localeES = {
  TITLE: 'Prescripción',
  FILTERS_MODAL_TITLE: 'Filtros de búsqueda',
  FILTERS_MODAL_CLEAN: 'Limpiar',
  FILTERS_MODAL_SAFE: 'Buscar',
  TITLE_MODAL: 'Buscador de medicamento',
  COLLAPSE_TREATMENT_PRESCRIBE: 'Tratamiento a prescribir',
  COLLAPSE_INFO_COMPLETE: 'Info. a completar',
  COLLAPSE_VETERINARIAN: 'Veterinario/a',
  COLLAPSE_BREEDER: 'Ganadería',
  COLLAPSE_DISPENSER: 'Distribuidora farmacéutica',
  MEDICINE: 'Medicamento',
  PRESCRIPTION_DATA_N_PRESCRIPTION: 'Nº. Prescripción:',
  PRESCRIPTION_DATA_LAST_UPDATE: 'Fecha última actualización:',
  PRESCRIPTION_DATA_EXPIRATION: 'Válido hasta:',
  BUTTON_RELOAD: 'Recargar',
  INPUT_SEARCH_DESCRIPTION_MEDICINE: 'Busca el medicamento a prescribir',
  INPUT_SEARCH_PLACEHOLDER_MEDICINE: 'Buscar medicamento',
  INPUT_USE_LABEL_PRESCRIPTION: '*Uso de la Receta',
  INPUT_USE_PLACEHOLDER_PRESCRIPTION: '---',
  INPUT_USE_DESCRIPTION_PRESCRIPTION: 'Selecciona una opción',
  INPUT_USE_OPTION_NORMAL_USE_PRESCRIPTION: 'Receta de uso normal',
  INPUT_USE_OPTION_FEED_PRESCRIPTION: 'Receta de piensos',
  INPUT_USE_OPTION_MASTER_FORMULA_PRESCRIPTION: 'Fórmula magistral',
  INPUT_USE_OPTION_AUTOVACCINE_PRESCRIPTION: 'Autovacuna',
  INPUT_LABEL_FRECUENCE_TREADMENT: 'Frecuencia del tratamiento',
  INPUT_LABEL_IS_VALID_DISPENSING: 'Prescripción para tratamiento crónico',
  INPUT_PLACEHOLDER_PACKAGING: '*Envases',
  INPUT_DESCRIPTION_PACKAGING: 'Indica la cantidad de envases a prescribir',
  INPUT_PLACEHOLDER_DURATION_TREATMENT: '*Duración del tratamiento',
  INPUT_DESCRIPTION_DURATION_TREATMENT:
    'Escribe el número de días del tratamiento',
  INPUT_PLACEHOLDER_PACKAGE_PERCENTAGE: 'Porcentaje',
  INPUT_LABEL_PACKAGE_PERCENTAGE: '*Porcentaje',
  CREATE_PRESCRIPTION_MESSAGE_ERROR_PERCENTAGE:
    'El porcentaje de uso de los envases no puede ser mayor al 100%',
  INPUT_DESCRIPTION_PACKAGE_PERCENTAGE:
    'Indica el porcentaje de uso de los envases a emplear',
  INPUT_LABEL_TREATMENT_CLASS: '*Clase de tratamiento',
  INPUT_LABEL_TREATMENT_TYPE_PRESCRIPTION: '*Tipo de prescripción',
  INPUT_DESCRIPTION_LABEL_ADVICE_REGA: '*Comentario',
  INPUT_PLACEHOLDER_ADVICE_REGA: 'Advertencia a la ganadería',
  INPUT_DESCRIPTION_ADVICE_REGA: 'Añade un comentario',
  INPUT_LABEL_EXPIRATION_DATE: '*Valido hasta',
  INPUT_PLACEHOLDER_EXPIRATION_DATE: 'Fecha',
  INPUT_LABEL__ADD_CLEANING_PLAN: 'Añadir Plan de higiene integral',
  INPUT_DESCRIPTION__EXPIRATION_DATE: 'Indica la fecha de expiración',
  INPUT_LABEL__ADD_ANTIBIOGRAM: 'Añadir Antibiograma',
  COLLAPSE_MORE_INFO: 'Más info. del medicamento',
  COLLAPSE_INFO_ANIMAL: 'Info. del Animal o lote',
  TREAMENT_PRESCRIBED_LABEL_ACTIVE_PRINCIPLE_MEDICATION: 'Principio activo',
  TREAMENT_PRESCRIBED_LABEL_INDICATIONS_MEDICATION: 'Indicación',
  TREAMENT_PRESCRIBED_LABEL_AFFECTION_MEDICATION: 'Afección',
  TREAMENT_PRESCRIBED_LABEL_NREGISTER_MEDICATION: 'Número de registro',
  TREAMENT_PRESCRIBED_LABEL_NAME_MEDICATION: 'Nombre del medicamento',
  TREAMENT_PRESCRIBED_LABEL_COMPOSITION: 'Composición',
  TREAMENT_PRESCRIBED_LABEL_ATC_CODE: 'ATC Code',
  TREAMENT_PRESCRIBED_LABEL_UNITY_COMPOSITION: 'U. Composición',
  TREAMENT_PRESCRIBED_LABEL_UNITY_DOSIS: 'U. Dosis',
  TREAMENT_PRESCRIBED_LABEL_CONCENTRATION: 'Concentración',
  TREAMENT_PRESCRIBED_LABEL_ADMINISTRATION: 'Vía Administrativa',
  TREAMENT_PRESCRIBED_LABEL_TIME_WAIT: 'Tiempo de espera',
  TREAMENT_PRESCRIBED_LABEL_VALID_UNTIL: 'Válido hasta',
  MEDICATION_MORE_INFO_TITLE: 'Info del medicamento',
  MEDICATION_MORE_INFO_LABEL_LABORATORY: 'Laboratorio',
  MEDICATION_MORE_INFO_LABEL_CONTRAINDICATIONS: 'Contraindicaciones',
  MEDICATION_MORE_INFO_LABEL_FARMACEUTIC_FORM: 'Forma farmaceutica',
  MEDICATION_MORE_INFO_LABEL_DISPOSITIVE: 'Dispositivo',
  MEDICATION_MORE_INFO_LABEL_WARNINGS: 'Reacciones adversas',
  MEDICATION_MORE_INFO_LABEL_SITUATION: 'Situación',
  MEDICATION_MORE_INFO_LABEL_ADVERSE_REACTIONS: 'Reacciones adversas',
  MEDICATION_MORE_INFO_LABEL_EXTRA_INFO: 'Info extra',
  ANIMAL_INFO_DESCRIPTION:
    'Prescripción realizada para los animales o el lote de animales identificado por:',
  ANIMAL_INFO_SPECIE: 'Especie',
  CREATE_PRESCRIPTION_MESSAGE_SUCCESSFUL:
    'La prescripción ha sido creada correctamente',
  EDIT_PRESCRIPTION_MESSAGE_SUCCESSFUL:
    'La prescripción ha sido editada correctamente',
  CREATE_PRESCRIPTION_MESSAGE_ERROR:
    'Ha habido un error a la hora de crear la prescripción',
  CREATE_PRESCRIPTION_MESSAGE_ERROR_TREADMENT_DURATION_FORMAT:
    'ERROR: "Duración del tratamiento" deberia ser un numero',
  CREATE_PRESCRIPTION_MESSAGE_ERROR_MED_CUANTITY_EXCEDED:
    'ERROR: "Se ha excedido en el numero de medicamentos, maximo 999',
  ERROR_REQUIRED_FIELDS: 'Los siguientes campos son requeridos: ',
  BUTTON_SAVE: 'Guardar',
  BUTTON_SIGN: 'Firmar y enviar al PresVet',
  BUTTON_SHARE: 'Compartir',
  BUTTON_DOWNLOAD: 'Descargar',
  BUTTON_EXIT: 'Salir',
  BUTTON_GO_BACK: 'Volver al detalle',
  TREAMENT_PRESCRIBED_LABEL_NATIONAL_CODE: 'Código Nacional',
  MEDICATION_EMPTY_STATE:
    'No se ha encontrado ningun resultado, para ver los medicamentos introduzca al menos 3 letras del nombre del medicamento',
  LOAD_LAST_PRESCRIPTION: 'Cargar datos de última prescripción',
  URL_COPY_DONE: 'El enlace se ha copiado de forma correcta en el portapapeles',
  SIGNATURE_DATA__NAME_AND_LASTNAME: 'Nombre y Apellidos',
  SIGNATURE_DATA__LICENSE_NUMBER: 'Nº. Colegiado',
  SIGNATURE_DATA__DNI: 'DNI',
  SIGNATURE_DATA__CIF: 'CIF',
  SIGNATURE_DATA__REGA: 'REGA',
  SIGNATURE_DATA__SIGNATURE: 'Firma',
  SIGNATURE_DATA__SIGNATURE_NAME: 'Nombre del firmante',
  SIGNATURE_DATA__ADDRESS: 'Dirección',
  SIGNATURE_DATA__BLOCKCHAIN_NETWORK: 'Blockchain red',
  SIGNATURE_DATA__BLOCKCHAIN_URL: 'Blockchain url',
  SIGNATURE_DATA__IPFS: 'IPFS',
  MODAL_REMOVE_PRESCRIPTION_TITLE: 'Borrar prescripción',
  MODAL_REMOVE_PRESCRIPTION_DESCRIPTION:
    '¿Deseas borrar esta prescripción? Al hacerlo, no podrá adquirirse dicho medicamento. Esta acción no puede deshacerse',
  MODAL_REMOVE_PRESCRIPTION_BUTTON_CANCEL: 'Cancelar',
  MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE: 'Borrar',
  MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE_SUCCESS:
    'Se ha borrado correctamente la prescripción',
  MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE_ERROR:
    'Ha habido un error a la hora de borrar la prescripción',
  INPUT_OPTION_DISPENSABLE: 'Dispensable',
  INPUT_OPTION_NO_DISPENSABLE: 'No dispensable',
  DISPENSATION_TYPE: 'Tipo de dispensación',
  INPUT_CLASS_THEATMENT_OPTIONS_TERAPEUTIC: 'Terapéutico',
  INPUT_CLASS_THEATMENT_OPTIONS_METAFILATIC: 'Metafilático',
  INPUT_CLASS_THEATMENT_OPTIONS_PROFILACTIC: 'Profiláctico',
  DOWNLOAD_SUCCESS: 'El documento se ha descargado correctamente',
  DOWNLOAD_ERROR: 'Ha habido un error al descargar el documento',
  SIGNATURE_CALL_ERROR:
    'Ha habido un error a la hora de firmar y enviar a Presvet',
  SIGNATURE_CALL_SUCCESS: 'Se ha enviado la firma correctamente',
  PRESVET_DATA_ERRORS: 'Errores:',
  PRESVET_DATA_INCIDENCES: 'Incidencias:',
  CHECKBOX_TERMS_AND_CONDITIONS_LABEL:
    'Declaro que los tratamientos con antimicrobianos se prescriben conforme al artículo 107.3 y 4 del Reglamento (UE) 2019/6, de medicamentos veterinarios (para el caso de medicamentos antimicrobianos).',
  PRESVET_STATE_Draft: 'Borrador',
  PRESVET_STATE_PresvetSend: 'Enviada a PresVet',
  PRESVET_STATE_Signed: 'Enviada a PresVet',
  PRESVET_STATE_PresvetOk: 'Correcta en PresVet',
  PRESVET_STATE_PresvetError: 'Error/incidencia en PresVet',
  PRESVET_STATE_PresvetRejected: 'Rechazada en PresVet',
  PRESVET_STATE_Prescribed: 'Prescrita',
  PRESVET_STATE_Dispensed: 'Dispensada ',
  PRESVET_STATE_Expired: 'Expirada',
  PRESVET_STATE_Canceled: 'Cancelada',
}

export default localeES
