import { Dimensions, Platform, TextStyle, ViewStyle } from 'react-native'

const styles = {
  container: {
    padding: 20,
    width: Dimensions.get('window').width,
    height: '100%',
  } as ViewStyle,
  text: {
    marginTop: 10,
  } as TextStyle,
  input: {
    marginTop: 10,
  } as ViewStyle,
}
export default styles
