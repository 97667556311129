const localeES = {
  TITLE: 'Política de Privacidad',
  TEXT: `

  Última actualización: 06/02/2024 
  
  Consuvet es una aplicación desarrollada por por Manuel Fernandez con NIF 29087953Z para proporcionar herramientas de gestión de casos clínicos y prescripción de medicamentos a veterinarios. Esta política de privacidad describe cómo se recopila, utiliza y comparte la información personal de los usuarios de Consuvet. Al utilizar esta aplicación, usted acepta las prácticas descritas en esta política de privacidad. 
  
  Información recopilada 
  
  * Información proporcionada por el usuario: Al darse de alta como usuario en Consuvet, se le puede solicitar que proporcione cierta información personal, como su nombre, dirección de correo electrónico, número de teléfono y detalles de la práctica veterinaria. 
  * Información de uso: Recopilamos información sobre cómo interactúa con la aplicación, como las acciones que realiza dentro de la aplicación y el dispositivo que utiliza para acceder a ella. 
  * Información de prescripción: Cuando emite una prescripción a través de Consuvet, recopilamos detalles sobre el medicamento prescrito y el caso clínico y datos de la ganadería a la que se prescribe. 
  
  Uso de la información 
  
  * La información recopilada se utiliza para los siguientes propósitos: 
  * Facilitar el uso de la aplicación y proporcionar los servicios ofrecidos. 
  * Elaborar conclusiones generales sobre casos clínicos generados. 
  * Personalizar y mejorar la experiencia del usuario. 
  * Emitir prescripciones de manera oficial y transparente a través de Presvet. 
  * Registrar las prescripciones en el Presvet de acuerdo a la normativa vigente 
  * Registrar las prescripciones en blockchain utilizando la infraestructura de Alastria para garantizar la trazabilidad y la auditoría de las mismas. 
  * Cumplir con los requisitos legales y normativos aplicables. 
  
  Compartir información 
  
  * Proveedores de servicios: Podemos compartir información con proveedores de servicios que nos ayudan a operar, mantener y mejorar Consuvet. Estos proveedores de servicios están obligados a proteger la confidencialidad de su información. 
  * Investigación: La información sobre casos clínicos y su evolución, de forma pseudonimizada, puede ser usada en investigación para poder extraer conclusiones globales sobre enfermedades y casos clínicos veterinarios. 
  * Cumplimiento legal: Podemos divulgar información cuando creemos de buena fe que es necesario para cumplir con la ley, proteger nuestros derechos legales o responder a una solicitud legal válida. 
  * Consentimiento: Podemos compartir información con su consentimiento. 
  
  Seguridad de la información 
  
  Tomamos medidas razonables para proteger la información personal contra pérdida, uso indebido, acceso no autorizado, divulgación, alteración o destrucción. 
  

  Acceso y control de la información 
  
  Usted tiene derecho a acceder, corregir o eliminar su información personal en cualquier momento. Puede hacerlo a través poniéndose en contacto vía mail con Consuvet a través de esta dirección manuel.fernandez@consuvet.com. 
  

  Cambios en la política de privacidad 
  
  Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Le notificaremos sobre cualquier cambio significativo en la forma en que tratamos la información personal enviando una notificación a la dirección de correo electrónico que proporcionó o publicando un aviso destacado en nuestra aplicación. 
  
  
  Contacto 
  
  Si tiene alguna pregunta sobre esta política de privacidad, comuníquese con nosotros a través de manuel.fernandez@consuvet.com. 
  
   `
};

export default localeES;
