import React, { useContext, useEffect } from 'react'
import { FormatContext } from '../context/format.context'
import IZRadioButtonGroup, {
  IZRadioButtonGroupProps,
} from '../../../atomic-components/IZRadioButtonGroup'
import { useAutoFill } from '../hooks/useAutoFill'

/**
 * FormatRadioButton is a formatted radio button group component that can be used to update formatting settings.
 *
 * @component
 * @param {IZRadioButtonGroupProps} props - The props for configuring the FormatRadioButton component.
 * @param {string} [props.name] - An optional name for the radio button group used to identify it in formatting settings.
 * @param {Function} [props.onValueChange] - An optional callback function that is called when the radio button value changes.
 * It receives the new selected value as a parameter.
 * @returns {JSX.Element} A formatted radio button group element.
 */
const FormatRadioButton = (
  props: IZRadioButtonGroupProps & {
    name?: string
  }
) => {
  const { setFormat } = useContext(FormatContext)

  // useAutoFill(props.name!, props.buttons[0]?.label)

  const onHandleRadioButtonValueChange = (newValue: string) => {
    props.onValueChange?.(newValue)
    props.name &&
      setFormat?.((format: any) => ({ ...format, [props.name!]: newValue }))
  }

  return (
    <IZRadioButtonGroup
      {...props}
      onValueChange={onHandleRadioButtonValueChange}
    />
  )
}

export default FormatRadioButton
