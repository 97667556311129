import React from 'react'
import IZCard from '../../../components/atomic-components/IZCard'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import styles from '../styles'
import { TouchableOpacity } from '../../../libraries/react-native'
import { Prescription } from '../../../interfaces/Prescription'

interface Props {
  prescription: Prescription
  onPress: (prescription: Prescription) => void
  t: (s:string)=>string
}
const PrescriptionCard = (props: Props, index: number) => {
  return (
    <TouchableOpacity onPress={() => props.onPress(props.prescription)}>
      <IZCard key={`prescription-${index}`}>
        <DescriptionText style={styles.cardDateText}>{props.prescription.prescription_date}</DescriptionText>
        <DescriptionText>{props.t(`PRESVET_STATE_${props.prescription.prescription_status}`)}</DescriptionText>
      </IZCard>
    </TouchableOpacity>
  )
}

export default PrescriptionCard
