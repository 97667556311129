import { ViewStyle } from 'react-native'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import { View } from '../../../libraries/react-native'
import styles from '../styles'

const MedicationData = (props: { value: string; label: string, style?: ViewStyle }) => {
  return (
    <View style={{...props.style}}>
      {props.value && (
        <>
          <DescriptionText style={styles.treatmentMedicationLabel}>{props.label}</DescriptionText>
          <DescriptionText style={styles.treatmentMedicationContent}>{props.value}</DescriptionText>
        </>
      )}
    </View>
  )
}

export default MedicationData
