import React, { createContext, useState, ReactNode } from 'react'


const SearchContext = createContext<{
  searchText: string
  setSearchText: (text: string) => void
}>({} as any)


function SearchProvider(props: { children: ReactNode }) {
  const [searchText, setSearchText] = useState<string>("")

  return (
    <SearchContext.Provider
      value={{ searchText, setSearchText }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
