import React from 'react'
import Navigation from './navigation'
import { ModalProvider } from './context/Modal.context'
import ModalWrapper from './components/wrappers/ModalWrapper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { MessageProviderWrapper } from './context/UserMessage.context'
import { Provider as PaperProvider, TextInput } from 'react-native-paper'
import SnackbarMessage from './components/atomic-components/Snackbar'
import useCachedResources from './hooks/useCachedResources'
import { SearchProvider } from './context/search.context'
import { DefaultTheme } from 'react-native-paper'
import { Icon } from './libraries/react-native'
import { Platform, StatusBar } from 'react-native'
import { DataProvider } from './context/Data.context'
import { ColorKeys, getThemeColor } from './utils/colors'
import ModalWrapperSecondary from './components/wrappers/ModalWrapperSecondary'

const App = () => {
  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: getThemeColor(ColorKeys.primary),
    },
  }
  const isLoadingComplete = useCachedResources()
  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <SafeAreaProvider>
        <StatusBar barStyle='dark-content' backgroundColor={getThemeColor(ColorKeys.primary)} />
        <DataProvider>
          <ModalProvider>
            <MessageProviderWrapper>
              <SearchProvider>
                <PaperProvider
                  theme={theme}
                  settings={
                    Platform.OS === 'web'
                      ? {
                          icon: (iconProps: any) => {
                            return <Icon {...iconProps} icon={iconProps.name} />
                          },
                        }
                      : undefined
                  }
                >
                  <Navigation />
                  <ModalWrapper />
                  <ModalWrapperSecondary />
                  <SnackbarMessage />
                </PaperProvider>
              </SearchProvider>
            </MessageProviderWrapper>
          </ModalProvider>
        </DataProvider>
      </SafeAreaProvider>
    )
  }
}

export default App
