import axios, { AxiosResponse } from 'axios'
import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import AuthService from './authService'
import { Evidence } from '../interfaces/Evidence'

class EvidenceService {
  private static credential: string | null = null;

  private static async ensureCredentials() {
    if (!EvidenceService.credential) {
      const { credential }: any = await AuthService.getAuthInfo()
      EvidenceService.credential = credential
    }
  }

  public static getEvidenceList = async (case_id: string): Promise<Evidence[]> => {
    await EvidenceService.ensureCredentials()

    try {
      const response = await axios.get(
        BACKEND_BASE_URL_V1 + '/evidence/',
        {
          headers: {
            'Authorization': `Basic ${EvidenceService.credential}`,
            'Content-Type': 'application/json',
          },
          params: { case_id },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  public static createEvidence = async (formData: FormData) => {
    await EvidenceService.ensureCredentials()

    try {
      const response = await axios.post(
        BACKEND_BASE_URL_V1 + '/evidence/',
        formData,
        {
          headers: {
            'Authorization': `Basic ${EvidenceService.credential}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  public static deleteEvidence = async (id: number) => {
    await EvidenceService.ensureCredentials()

    try {
      const response = await axios.delete(
        `${BACKEND_BASE_URL_V1}/evidence/${id}/`,
        {
          headers: {
            'Authorization': `Basic ${EvidenceService.credential}`,
            'Content-Type': 'application/json',
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  };

}

export default EvidenceService