import React, { useContext, useEffect } from 'react'
import IZCheckBox, {
  IZCheckBoxProps,
} from '../../../atomic-components/IZCheckBox'
import { FormatContext } from '../context/format.context'
import { useAutoFill } from '../hooks/useAutoFill'

/**
 * FormatCheckBox is a formatted checkbox component that can be used to update formatting settings.
 *
 * @component
 * @param {IZCheckBoxProps} props - The props for configuring the FormatCheckBox component.
 * @param {string} [props.name] - An optional name for the checkbox used to identify it in formatting settings.
 * @param {Function} [props.onChange] - An optional callback function that is called when the checkbox state changes.
 * It receives the new checked state as a parameter.
 * @returns {JSX.Element} A formatted checkbox element.
 */
const FormatCheckBox = (props: IZCheckBoxProps) => {
  const { setFormat } = useContext(FormatContext)

  const onHandleCheckboxPress = (checked: boolean) => {
    props.name &&
      setFormat((format: any) => ({ ...format, [props.name!]: checked }))
    props.onChange?.(checked)
  }

  return <IZCheckBox {...props} onChange={onHandleCheckboxPress} />
}

export default FormatCheckBox
