import React, { useContext, useEffect, useState } from 'react'
import { Icon, View } from '../../../libraries/react-native'
//@ts-ignore
import IZInput from '../../../components/atomic-components/IZInput'
import { SearchContext } from '../../../context/search.context'
import { ColorKeys, getThemeColor, isDark } from '../../../utils/colors'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../../constants/screens'
import medicalCasesI18nKeys from '../i18n/keys'
import { Dimensions, Platform } from 'react-native'

const Search = () => {
  const { t } = useTranslation(SCREEN.MedicalCases)
  const { searchText, setSearchText } = useContext(SearchContext)
  const scheme = isDark(ColorKeys.primary) ? 'light' : 'dark'
  const [width, setWidth] = useState(Dimensions.get('window').width)
  const handleDimensionsChange = ({ window }: any) => {
    const { width } = window
    setWidth(width)
  }

  useEffect(() => {
    Dimensions.addEventListener('change', handleDimensionsChange)
  }, [])
  return (
    <IZInput
      value={searchText}
      scheme={scheme}
      style={{ width: width >= 690 ? 360 : width-50 ,marginTop: width >= 690?undefined: 10}}
      theme={{ roundness: 100 }}
      onChangeText={setSearchText}
      placeholder={t(medicalCasesI18nKeys.SEARCH_PLACEHOLDER)}
      left={
        <Icon
          icon={'view-headline'}
          color={getThemeColor(ColorKeys.text, scheme)}
        />
      }
      right={
        <Icon icon={'magnify'} color={getThemeColor(ColorKeys.text, scheme)} />
      }
    />
  )
}

export default Search
