import IZFormat from '../../../components/templates/IZFormat'
import useMessage from '../../../hooks/useMessage'
import { useServices } from '../../../hooks/useServices/useServices'
import newCaseI18nKeys from '../i18n/keys'
import { useForm } from '../../../components/templates/IZFormat/hooks/useForm'
import prescriptionI18nKeys from '../../AddPrescription/i18n/keys'

interface Props {
  navigation: any
  t: any
}
const SaveButton = ({ navigation, t }: Props) => {
  const { showMessage } = useMessage()
  const { CaseService } = useServices()
  const { showAxiosErrors } = useForm()
  const onHandlePressButtonSave = async (format: any) => {
    try {
      if (!format.animal_id?.length) {
        showMessage({
          content: t(newCaseI18nKeys.SAVE_BUTTON_ERROR_ANIMAL),
          type: 'error',
        })
        return
      }
      if (!format.diagnosis) {
        showMessage({
          content: t(newCaseI18nKeys.SAVE_BUTTON_ERROR_DIAGNOSIS),
          type: 'error',
        })
        return
      }

      await CaseService.createCase(format)
      showMessage({
        content: t(newCaseI18nKeys.ADD_CASE_SUCCESS),
        type: 'success',
      })
      navigation.goBack()
    } catch (error: any) {
      showAxiosErrors(error)
      showMessage({
        content: t(newCaseI18nKeys.ADD_CASE_ERROR),
        type: 'error',
      })
    }
  }
  return (
    <IZFormat.Button onPress={onHandlePressButtonSave}>
      {t(newCaseI18nKeys.BUTTON_SAVE)}
    </IZFormat.Button>
  )
}

export default SaveButton
