const localeES = {
  TITLE: 'Nuevo Caso',
  TEXT_GENERAL_INFORMATION: 'Información general',
  TEXT_DIAGNOSIS: 'Diagnóstico',
  INPUT_DIAGNOSIS: 'Diagnóstico',
  INPUT_DATE_START: 'Fecha inicio',
  INPUT_PRONOSTIC: 'Evolución',
  BUTTON_ADD_NEW_ANIMAL: 'Añadir nuevo',
  ADD_CASE_SUCCESS: 'Se ha añadido el caso correctamente',
  ADD_CASE_ERROR: 'Ha habido un error a la hora de crear el caso',
  GET_ANIMALS_ERROR:
    'Ha habido un error a la hora de obtener la lista de animales',
  SAFE_CASE_FORMAT_ANIMALID_REQUIRED_ERROR: 'Falta el identificador de animal',
  BUTTON_SAVE: 'Guardar',
  OPTION_TEXT_REGA: 'Ganaderia: {{rega_id}}',
  COLLAPSE_MORE_INFO: 'Más info. diagnóstica',
  INPUT_DIAGNOSIS_REQUIRED: '* Diagnóstico',
  SAVE_BUTTON_ERROR_ANIMAL: 'Identificador de animal requerido',
  SAVE_BUTTON_ERROR_DIAGNOSIS: 'Diagnostico requerido',
  INPUT_SYSTEM_OPTION_LABEL: 'Sistema/s afectados',
  INPUT_TYPE_DIAGNOSIS_OPTION_LABEL: 'Tipo de diagnóstico',
}

export default localeES
