import { ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

export const styles = {
  container: { flexDirection: 'row', alignItems: 'center' } as ViewStyle,

  inputContainer: ({ scheme, theme, left, right, editable }: any) =>
    ({
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: left ? 20 : 0,
      paddingHorizontal: right ? 30 : 0,
      backgroundColor: getThemeColor(
        editable === false ? ColorKeys.disabled : ColorKeys.background,
        scheme
      ),
      borderWidth: 1,
      borderColor: getThemeColor(ColorKeys.placeholder, scheme),
      borderRadius: theme?.roundness ?? 7,
      justifyContent: 'space-between',
    } as ViewStyle),
}
