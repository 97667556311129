import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SCREEN } from '../../../constants/screens'
import { navigationScreensConfigType } from '../../types'
import NewCaseConfig from '../../../screens/NewCase/config'
import MedicalCasesConfig from '../../../screens/MedicalCases/config'
import WrapperHeader from '../WrapperHeader'
import { mergeObjects } from '../../../utils/objects'
import { ColorKeys, getTextColorByBackground, getThemeColor } from '../../../utils/colors'
import { Platform } from 'react-native'
import AddAnimalConfig from '../../../screens/AddAnimal/config'
import { DeepLinkingWrapper } from '../../../components/wrappers/DeepLinkingWrapper'
import PrescriptionConfig from '../../../screens/AddPrescription/config'
import AddDiagnosisConfig from '../../../screens/AddDiagnosis/config'
import { ReRendsAvoiderWrapper } from '../../../utils/context'
import { concatWrapper } from '../../../utils/wrapper'

const Stack = createNativeStackNavigator()
const principalScreensOptions = {
  headerTitleStyle: {
    color: getTextColorByBackground(ColorKeys.primary),
    fontSize: 22,
  },
  headerStyle: {
    backgroundColor: getThemeColor(ColorKeys.primary),
  },
  headerTintColor: getThemeColor(ColorKeys.background),
}

const Wrappers = (props: any) =>
  concatWrapper(
    [ReRendsAvoiderWrapper, DeepLinkingWrapper],
    WrapperHeader({
      ...props,
      options: mergeObjects(principalScreensOptions, props.options),
    })
  )
const PrincipalNavigation = () => {
  const principalScreensConfig: navigationScreensConfigType[] = [
    NewCaseConfig,
    MedicalCasesConfig,
    AddAnimalConfig,
    PrescriptionConfig,
    AddDiagnosisConfig,
  ]
  return (
    <Stack.Navigator initialRouteName={SCREEN.MedicalCases}>
      {principalScreensConfig.map((props: any, index) => (
        <Stack.Screen
          {...props}
          component={Wrappers(props)}
          key={props.name ?? `principalScreensConfig-${index}`}
          options={
            Platform.OS !== 'web' ? mergeObjects(principalScreensOptions, props.options) : { headerShown: false }
          }
        />
      ))}
    </Stack.Navigator>
  )
}

export default PrincipalNavigation
