import IZFormat from '../IZFormat'
import { ViewStyle } from 'react-native'
import caseDropboxAffectedSystemsI18nKeys from './i18n/keys'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import localeES from './i18n/es'
import { bundleName } from './constants'
import { IZDropdownProps } from '../../atomic-components/IZDropdown'
import { useEffect, useState } from 'react'
import { useServices } from '../../../hooks/useServices/useServices'
import { mergeArrayObjects } from '../../../utils/objects'

interface Props extends Omit<IZDropdownProps, 'options'> {
  initialValue?: string
}
/**
 * InputSearchAnimalIdentifier is a React component that provides an input field for searching animal identifiers and update FormatContext animal_id with value selected .
 *
 * @param {Object} props - The component's properties.
 * @param {ViewStyle} [props.style] - Optional styling for the component.
 * @param {string} [props.value] - The initial value for the input field.
 */
const CaseDropboxAffectedSystems = (props: Props) => {
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  const { CaseService } = useServices()
  const [options, setOptions] = useState<{ value: any; label: string }[]>([])
  useEffect(() => {
    ;(async () => {
      try {
        const unformatedOptions = await CaseService.getAffectedSystem()
        const mergedUnformatedOptions = mergeArrayObjects(unformatedOptions)
        const formatedOptions = Object.entries(mergedUnformatedOptions).map(([key, value]: any) => ({
          value: key,
          label: (caseDropboxAffectedSystemsI18nKeys as any)[`INPUT_SYSTEM_OPTION_${key.toUpperCase()}`]
            ? t((caseDropboxAffectedSystemsI18nKeys as any)[`INPUT_SYSTEM_OPTION_${key.toUpperCase()}`])
            : value,
        }))
        setOptions([
          { label: t(caseDropboxAffectedSystemsI18nKeys.INPUT_SYSTEM_OPTION_DEFAULT), value: '' },
          ...formatedOptions,
        ])
      } catch (error) {}
    })()
  }, [])
  return (
    <IZFormat.DropDown
      {...props}
      label={props.label ?? t(caseDropboxAffectedSystemsI18nKeys.INPUT_SYSTEM_OPTION_LABEL)}
      name='affected_system'
      options={options}
      multiselect
    />
  )
}

export default CaseDropboxAffectedSystems
