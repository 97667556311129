import React from 'react'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const LinkWeb = (props: any) => {
  return <a {...props} style={style.link} />
}

const style = {
  link: { color: getThemeColor(ColorKeys.primary), textDecoration: 'none' },
}
export default LinkWeb
