import { Dimensions, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const style = {
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  } as ViewStyle,
  borderLine: {
    width: Dimensions.get('window').width * 3,
    height: 1,
    left: -Dimensions.get('window').width,
    bottom: 0,
    position: 'absolute',
    borderBottomWidth: 1,
    borderColor: getThemeColor(ColorKeys.disabled),
  } as ViewStyle,
}
export default style
