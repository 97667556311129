const localeES = {
  TITLE: 'Resumen',
  TEXT_GENERAL_INFORMATION: 'Información general',
  INPUT_ANIMAL_ID: 'Identificador de animal o lote',
  INPUT_ISSUER: 'Rega o Ganadero',
  TEXT_EVOLUTION_DIAGNOSIS: 'Evolución diagnóstica',
  BUTTON_DIAGNOSIS: 'Añadir evolución diagnostica',
  BUTTON_SAVE: 'Guardar',
  GET_CASE_DETAIL_FILTERS_EMPTY_STATE: 'No hay diagnosticos con los filtros actuales',
  GET_CASE_DETAIL_EMPTY_STATE: 'No hay diagnosticos cargados actualmente',
  GET_CASE_DETAIL_ERROR: 'Error al cargar los detalles del caso',
  EVOLUTION_DETAILS_MUSCULOSKELETAL: 'Musculoesquelético',
  EVOLUTION_DETAILS_RESPIRATORY: 'Respiratorio',
  EVOLUTION_DETAILS_DIGESTIVE: 'Digestivo',
  EVOLUTION_DETAILS_GENITOURINARY: 'Génitourinario',
  EVOLUTION_DETAILS_NERVOUS: 'Nervioso',
  EVOLUTION_DETAILS_CIRCULATORY: 'Circulatorio',
  EVOLUTION_DETAILS_SKIN: 'Piel y mucosas',
  EVOLUTION_DETAILS_SENSEORGANS: 'Organos de los sentidos',
  EVOLUTION_DETAILS_SYMPTOMATIC: 'Sintomático',
  EVOLUTION_DETAILS_DIRECT: 'Directo',
  EVOLUTION_DETAILS_INDIRECT: 'Indirecto',
  EVOLUTION_DETAILS_DEFERRED: 'Diferido (Necropsia)',
  EVOLUTION_DETAILS_ETIOPATHOGENIC: 'Etiopatogénico',
  EVOLUTION_DETAILS_DEFINITIVE: 'Definitivo',
  EVOLUTION_DETAILS_CURED: 'Curado',
  EVOLUTION_DETAILS_NOTCURED: 'No curado',
  EVOLUTION_DETAILS_IMPROVED: 'Mejora pero no cura',
  EVOLUTION_DETAILS_NOTIMPROVED: 'Empeora y no cura',
  EVOLUTION_DETAILS_DEATH: 'Muerte',
  EVOLUTION_DETAILS_INCONCLUSIVE: 'No concluyente',
  UPDATE_CASE_SUCCESS: 'El caso se ha actualizado correctamente',
  UPDATE_CASE_ERROR: 'Ha habido un error a la hora de actualizar el caso',
  INPUT_LABEL_REGA: 'REGA',
  INPUT_LABEL_ANIMAL_ID: 'Animal o Lote',
  SAFE_FILTERS_SUCCESS: 'Se ha modificado correctamente el caso',
  SAFE_FILTERS_ERROR: 'Ha habido un error al modificar el caso',
  COLLAPSE_MORE_INFO: 'Más info. diagnóstica',
  SYSTEM: 'Sistema: ',
  DIAGNOSTIC: 'Diagnostico: ',
}

export default localeES
