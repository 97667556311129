import { getPrioritaryItem } from '../../../../utils/objects'
import { getValueExtracted } from './valueExtractor'

type PrioritaryItemType = {
  value: any
  selectedItem: any
  initialValue: any
}

export const getPrioritaryValueMultiple = ({ value, selectedItem, initialValue }: PrioritaryItemType) => {
  const prioritaryValueMultiple = getPrioritaryItem({
    value: value,
    stateValue: selectedItem,
    initialValue: initialValue,
    defaultValue: [],
  })
  return prioritaryValueMultiple
}

export const getPrioritaryValueSimple = ({ value, selectedItem, initialValue }: PrioritaryItemType) => {
  const prioritaryValueSimple = getPrioritaryItem({
    value: value,
    stateValue: selectedItem,
    initialValue: initialValue,
    defaultValue: '',
  })
  return prioritaryValueSimple
}
