import React, { useContext } from 'react'
import { MessageContext } from '../../../context/UserMessage.context'
import Color from '../../../constants/color'
import { Snackbar } from '../../../libraries/react-native'

export default function SnackbarMessage(): JSX.Element {
  const { setMessageInfo, messageInfo } = useContext(MessageContext)

  const getBackgroundColor = (messageType: string) => {
    if (messageType === 'error') {
      return Color.error
    } else if (messageType === 'success') {
      return Color.success
    } else if (messageType === 'warning') {
      return Color.warning
    } else {
      return 'transparent'
    }
  }
  const snackbarStyle = {
    borderRadius: 10,
    backgroundColor: getBackgroundColor(messageInfo.type),
  }

  return (
    //@ts-ignore
    <Snackbar
      wrapperStyle={{ bottom: 100, zIndex: 10000 }}
      visible={messageInfo.isVisible && messageInfo.content}
      duration={messageInfo.duration ?? 3000}
      onDismiss={() => {
        setMessageInfo({ content: '', type: 'transparent', isVisible: false })
      }}
      style={snackbarStyle}
    >
      {messageInfo.content}
    </Snackbar>
  )
}
