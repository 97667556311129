import React from 'react'
import { ImageBackground, View } from '../../../libraries/react-native'
import styles from './styles'
//@ts-ignore
import LoginBackground from '../../../assets/images/backgroundImage.png'

interface Props {
  children: JSX.Element | (JSX.Element | boolean)[]
}
const AuthContainer = (props: Props) => {
  return (
    <ImageBackground source={LoginBackground}>
      <View style={styles.screen}>
        <View style={styles.loginContainer}>{props.children}</View>
      </View>
    </ImageBackground>
  )
}

export default AuthContainer
