import { useEffect, useState } from 'react'
import { Icon, TouchableOpacity, View } from '../../../../libraries/react-native'
import IZInput, { IZInputProps } from '../../IZInput'
import { Dimensions, FlatList, Platform, SafeAreaView } from 'react-native'
import { DescriptionText } from '../../Text/variants'
import styles from '../styles'
import izInputSearchI18n from '../i18n/keys'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import localeES from '../i18n/es'
import { InputSearchModalProps } from '../../IZInput/types'
import ModalHeader from './ModalHeader'
import { bundleName } from '../constants'
import IZCard from '../../IZCard'
import { ColorKeys, getThemeColor } from '../../../../utils/colors'
import { toArray } from '../../../../utils/objects'

export type ModalSearchInputProps = InputSearchModalProps & {
  textInputProps?: IZInputProps
  onHandleItemPress: (x: any) => void
  onDismiss?: () => void
  onChangeSearchText?: (x: any) => void
  selectedItems: any
  options: any
  searchText?: string
  emptyStateText?: string
}
const ModalSearchInput = (props: ModalSearchInputProps) => {
  const [selectedItems, setSelectedItems] = useState(toArray(props.selectedItems))
  useEffect(() => {
    setSelectedItems(toArray(props.selectedItems))
  }, [props.selectedItems?.length ?? props.selectedItems])

  i18next.addResourceBundle('es', bundleName, localeES)
  const [text, setText] = useState(props.searchText ?? '')
  const { t } = useTranslation(bundleName)
  const modalHeaderProps = {
    headerStyle: props.headerStyle,
    headerTitle: props.headerTitle,
    headerScheme: props.headerScheme,
    onDismiss: props.onDismiss,
  }

  const onHandleSearchTextChange = (text: string) => {
    setText(text)
    props.onChangeSearchText?.(text)
  }

  return (
    <View style={styles.container}>
      <IZInput {...props.textInputProps} value={text} onChangeText={onHandleSearchTextChange} />
      <View style={{ ...styles.optionsContainer, ...props.optionsContainerStyle }}>
        {!props.options?.length && (
          <DescriptionText style={{ marginTop: 10 }}>
            {props.emptyStateText || t(izInputSearchI18n.EMPTY_STATE_OPTIONS)}
          </DescriptionText>
        )}
        <View style={{ paddingVertical: 20 }}>{props.footer}</View>
        <FlatList
          style={{ height: Platform.OS !== 'web' ? '90%' : Dimensions.get('window').height - 450 }}
          data={props.options?.filter((obj: any) => {
            return props.searchFilter?.(text, obj) ?? obj.label?.includes(text) ?? true
          })}
          renderItem={({ item: obj, index }: any) => {
            return (
              <TouchableOpacity
                key={`${obj.value}-${index}`}
                onPress={() => {
                  props.onHandleItemPress(obj)
                  const newItems = selectedItems.find((item) => obj.id === item.id)
                    ? selectedItems.filter((item) => obj.id !== item.id)
                    : [...selectedItems, obj]
                  setSelectedItems(newItems)
                }}
              >
                <IZCard style={styles.cardContainer}>
                  {props.renderOptions(obj, index)}
                  {!!selectedItems.find((item) => obj.id === item.id) && (
                    <Icon icon={'checkbox-marked'} color={getThemeColor(ColorKeys.primary)} />
                  )}
                </IZCard>
              </TouchableOpacity>
            )
          }}
        />
      </View>
    </View>
  )
}

export default ModalSearchInput
