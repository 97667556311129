import { Platform } from 'react-native'
import { TouchableOpacity, View } from '../../../../libraries/react-native'
import IZPill from '../../Pill'


const MultipleTag = (props: any) => {
  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginLeft: Platform.OS !== 'web' ? 35 : 0 }}>
      {props.value
        ?.split(';')
        .filter((v: any) => !!v)
        .map((v: string, index: any) => {
          const { label, value } = JSON.parse(v)
          return (
            <TouchableOpacity key={`MultipleTag-${index}`} onPress={() => props.onPress(value)}>
              <IZPill remove style={{ margin: 5 }}>
                {label}
              </IZPill>
            </TouchableOpacity>
          )
        })}
    </View>
  )
}

export default MultipleTag
