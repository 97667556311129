import { useState } from 'react'
import {
  DescriptionText,
  Subtitle,
} from '../../components/atomic-components/Text/variants'
import AuthContainer from '../../components/containers/AuthContainer'
//@ts-ignore
import IZInput from '../../components/atomic-components/IZInput'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../constants/screens'
import recoverAccessI18nKeys from './i18n/keys'
import styles from './styles'
import Link from '../../components/atomic-components/Text/variants/Link'
import IZButton from '../../components/atomic-components/Button'
import PasswordResetService from '../../services/PasswordResetService'
import useMessage from '../../hooks/useMessage'

interface Props {
  navigation: any
}
export default function RecoverAccess(props: Props) {
  const { t } = useTranslation(SCREEN.RecoverAccess)
  const [email, setEmail] = useState('')
  const { showMessage } = useMessage()

  const formatMessage = (message: string) => {
    switch (message) {
      case 'This field may not be blank.':
      case 'Enter a valid email address.':
        return t(recoverAccessI18nKeys.SEND_EMAIL_ERROR_NO_VALID_EMAIL)
      default:
        return t(message)
    }
  }

  const sendErrorMessage = (message: string) => {
    showMessage({ content: message, type: 'error' })
  }

  const onHandleButtonSendPress = async () => {
    try {
      await PasswordResetService.passwordReset({ email })
      showMessage({
        content: t(recoverAccessI18nKeys.SEND_EMAIL_SUCCESS),
        type: 'success',
      })
      props.navigation.goBack()
    } catch (error: any) {
      const errorData: string[] = error?.response?.data?.email ?? [
        recoverAccessI18nKeys.SEND_EMAIL_ERROR_UNEXPECTED_ERROR,
      ]
      errorData.map(formatMessage).forEach(sendErrorMessage)
    }
  }
  const onHandleBackPress = async () => {
    props.navigation.goBack()
  }

  return (
    <AuthContainer>
      <Subtitle style={styles.title}>{t(recoverAccessI18nKeys.TITLE)}</Subtitle>
      <DescriptionText style={styles.description}>
        {t(recoverAccessI18nKeys.DESCRIPTION)}
      </DescriptionText>
      <IZInput
        style={styles.input}
        value={email}
        onChangeText={setEmail}
        placeholder={t(recoverAccessI18nKeys.EMAIL)}
        label={t(recoverAccessI18nKeys.EMAIL)}
      />
      <Link style={styles.link} onPress={onHandleBackPress}>
        {t(recoverAccessI18nKeys.LINK_GO_BACK)}
      </Link>
      <IZButton onPress={onHandleButtonSendPress}>
        {t(recoverAccessI18nKeys.BUTTON)}
      </IZButton>
    </AuthContainer>
  )
}
