import { memo, useContext, useMemo } from 'react'

type ContextWrapperConfigParams = {
  Context: any
  rerenderParamsListExtractor: (x: any, props: any) => any[]
}
/**
 * A higher-order component (HOC) that helps avoid unnecessary re-renders of a React component by memoizing its rendering conditions based on context data.
 *
 * @param {React.Component} Component - The React component to be wrapped.
 * @param {Object} config - Configuration parameters for the context wrapper.
 * @param {any} config.Context - The context that the component relies on.
 * @param {Function} config.rerenderParamsListExtractor - A function that extracts the parameters that should trigger re-renders from the context data.
 * @param {Object} props - The component's properties.
 * @returns {React.Component} A memoized version of the wrapped component.
 */
export const ContextReRendsAvoiderWrapper =
  (
    Component: any,
    { Context, rerenderParamsListExtractor }: ContextWrapperConfigParams
  ) =>
  (props: any) => {
    // Memoize the wrapped component.
    const MemoizedComponent = memo(Component)

    // Get the context data.
    const contextData: any = useContext(Context)

    // Extract the condition for memoization based on context data.
    const conditionMemo = rerenderParamsListExtractor(contextData, props)

    return useMemo(() => {
      // Return the memoized component with the context data passed as a prop.
      return <MemoizedComponent {...props} contextData={contextData} />
    }, [...conditionMemo])
  }

export const ReRendsAvoiderWrapper = (Component: any) => (props: any) => {
  // Memoize the wrapped component.
  const MemoizedComponent = memo(Component)

  return useMemo(() => {
    // Return the memoized component with the context data passed as a prop.
    return <MemoizedComponent {...props} />
  }, [])
}
