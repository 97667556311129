/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

import { DarkTheme, NavigationContainer } from '@react-navigation/native'
import { ColorSchemeName, Platform } from 'react-native'
import { RootStackParamList } from './types'
import BottomTabNavigator from './components/BottomTabNavigator'

import { STACKS } from '../constants/screens'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { navTheme } from '../constants/color'
import PrincipalNavigation from './components/PrincipalNavigation'
import AuthNavigation from './components/AuthNavigation'
import WrapperHeader from './components/WrapperHeader'
import { ColorKeys, getTextColorByBackground, getThemeColor } from '../utils/colors'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import localeES from './i18n/es'
import navigationI18nKeys from './i18n/keys'
import { getInitialNavigationRoute } from './utils/login'
import { useAsyncFunctionState } from '../hooks/utils'

export default function Navigation({ colorScheme }: { colorScheme?: ColorSchemeName }) {
  const initialRouteName = useAsyncFunctionState(getInitialNavigationRoute)

  return (
    <NavigationContainer theme={colorScheme === 'dark' ? DarkTheme : navTheme}>
      {initialRouteName && <RootNavigator initialRouteName={initialRouteName} />}
    </NavigationContainer>
  )
}

const Stack = createNativeStackNavigator<RootStackParamList>()

function RootNavigator({ initialRouteName }: any): any {
  const bundleName = 'Navigator'
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  const principalScreensOptions = {
    headerTintColor: getThemeColor(ColorKeys.background),
    headerTitleStyle: {
      color: getTextColorByBackground(ColorKeys.primary),
      fontSize: 22
    },
    title: t(navigationI18nKeys.TITLE),
    headerBackTitleVisible: true,
    headerStyle: {
      backgroundColor: getThemeColor(ColorKeys.primary)
    }
  }
  const CaseDetailConfig = {
    name: STACKS.CaseDetail,
    component: BottomTabNavigator,
    options: principalScreensOptions
  }

  return (
    <Stack.Navigator
      initialRouteName={initialRouteName as any}
      screenOptions={{
        headerBackVisible: true
      }}
    >
      <Stack.Screen name={STACKS.Auth} component={AuthNavigation} options={{ headerShown: false }} />
      <Stack.Screen name={STACKS.Main} component={PrincipalNavigation} options={{ headerShown: false }} />
      <Stack.Screen
        name={STACKS.CaseDetail as any}
        component={Platform.OS !== 'web' ? BottomTabNavigator : WrapperHeader(CaseDetailConfig as any)}
        options={Platform.OS !== 'web' ? principalScreensOptions : { headerShown: false }}
      />
    </Stack.Navigator>
  )
}
