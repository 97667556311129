import axios from 'axios'
import { Platform } from 'react-native'
import { blobToBase64, createObjectURLFromBase64, utf8ToBase64 } from '..'
import FS from '../../../libraries/react-native-fs'

export const downloadFromBlobHibrid = async (blob: any, fileName: string) => {
  // Ruta completa del archivo en el dispositivo
  const url = URL.createObjectURL(blob)
  FS.downloadFile({ fromUrl: url, toFile: fileName })
}

export const downloadFromUrlHibrid = async (url: string, filePath: any) => {
  await axios.get(url).then(async (response) => {
    // const blobData = await response.blob()
    const base64 = utf8ToBase64(response.data)
    const url = await createObjectURLFromBase64(base64)
    FS.downloadFile({ fromUrl: url, toFile: filePath })
  })
}
