import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import SummarizedDetail from '.'
import { getCurrentTranslation } from '../../utils/i18n'

const ScreenName = SCREEN.SummarizedDetail

i18next.addResourceBundle('es', ScreenName, es)

const currentLanguage = getCurrentTranslation<typeof es>({ es })
const SummarizedDetailConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: SummarizedDetail,
  options: { headerTitle: currentLanguage.TITLE, title: currentLanguage.TITLE },
}

export default SummarizedDetailConfig
