import { Dimensions, Platform, StyleSheet, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { colors } from 'react-select/dist/declarations/src/theme'

const styles = StyleSheet.create({
  container: {
    height: Platform.OS !== 'web' ? '90%' : Dimensions.get('window').height - 300,
    backgroundColor: getThemeColor(ColorKeys.background),
  },
  optionsContainer: {
    width: '100%',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  cardContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginRight: 10 },
  closeButtonText: {},
  filter: {
    minWidth: 300,
    marginTop: 20,
  } as ViewStyle,
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
  } as ViewStyle,
  containerModal: {
    backgroundColor: getThemeColor(ColorKeys.background),
    alignSelf: 'center',
    padding: 20,
  } as ViewStyle,
  buttonClean: {
    borderWidth: 0,
  },
  closeContainer: { alignSelf: 'flex-end' } as ViewStyle,
})

export default styles
