import React, { useEffect } from 'react'
import {
  DescriptionText,
  Title,
} from '../../components/atomic-components/Text/variants'
import { TouchableOpacity, View } from '../../libraries/react-native'
import styles from './styles'
import IZView from '../../components/atomic-components/IZView'

export default function NotFoundScreen({ navigation }: any) {

  
  return (
    <IZView style={styles.container}>
      <Title style={styles.title}>This screen doesn't exist.</Title>
      <TouchableOpacity
        onPress={() => navigation.replace('Root')}
        style={styles.link}
      >
       
        <DescriptionText style={styles.linkText}>
          Go to home screen!
        </DescriptionText>
      </TouchableOpacity>
    </IZView>
  )
}
