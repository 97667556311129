import { Platform, TextStyle, ViewStyle } from 'react-native'

const styles = {
  container: {
    padding: 20,
    paddingBottom: 110,
  } as ViewStyle,
  sectionTextContainer: {
    marginTop: 49,
  } as ViewStyle,
  title: {} as ViewStyle,
  link: {} as ViewStyle,
  cardDescriptionDate: {
    fontSize: 16,
    letterSpacing: 1,
  } as TextStyle,
  button: {
    maxWidth: Platform.OS === 'web' ? 335 : 1000,
    width: Platform.OS === 'web' ? '200%' : 'auto',
  } as ViewStyle,
  cardContainer: {
    marginVertical: 20,
  } as ViewStyle,
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as ViewStyle,
  buttonRemoveContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: Platform.OS !== 'web' ? -70 : -20
  } as ViewStyle
}
export default styles
