import React, { useContext } from 'react'
import { FormatContext } from '../context/format.context'
import IZButton, { IZButtonProps } from '../../../atomic-components/Button'

/**
 * FormatButton is a formatted button component that can be used to trigger
 * specific formatting actions when clicked.
 *
 * @component
 * @param {IZButtonProps} props - The props for configuring the FormatButton component.
 * @param {string} [props.name] - An optional name for the button.
 * @param {Function} [props.onPress] - An optional callback function that is called when the button is pressed.
 * It receives the current format as a parameter.
 * @returns {JSX.Element} A formatted button element.
 */
const FormatButton = (
  props: Omit<IZButtonProps, 'onPress'> & {
    name?: string
    onPress?: (format: any) => void
  }
) => {
  const { format } = useContext(FormatContext)

  const extractValue = (value: any) => {
    if (Array.isArray(value)) {
      return (value as any[]).map((obj) =>
        typeof obj === 'object' ? obj.value : obj
      )
    } else {
      return typeof value === 'object' ? value.value : value
    }
  }
  const onHandleButtonPress = () => {
    const formatedFormatEntries = Object.entries(format).map(
      ([key, value]: any) => [key, extractValue(value)]
    )
    const formatedFormat = Object.fromEntries(formatedFormatEntries)
    props.onPress?.(formatedFormat)
  }

  return <IZButton {...props} onPress={onHandleButtonPress} />
}

export default FormatButton
