import { ViewStyle } from 'react-native'

const styles = {
  container: { height: '100%' } as ViewStyle,
  listLabelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  } as ViewStyle,
}
export default styles
