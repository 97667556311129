import React from 'react'
import { DescriptionText } from '../../Text/variants'

const ButtonText = (props:any) => {
  return (
    <>
      {typeof props.children == typeof '' ? (
        <DescriptionText {...props}/>
      ) : (
        props.children
      )}
    </>
  )
}

export default ButtonText
