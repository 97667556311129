import { DescriptionText } from '../../components/atomic-components/Text/variants'
import styles from './styles'
//@ts-ignore
import IZButton from '../../components/atomic-components/Button'
import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import prescriptionDetailI18nKeys from './i18n/keys'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import PrescriptionCard from './components/PrescriptionCard'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import IZView from '../../components/atomic-components/IZView'
import StateWrapper from '../../components/wrappers/StateWrapper'
import { useServices } from '../../hooks/useServices/useServices'
import { Case } from '../../interfaces/Case'
import { Animal } from '../../interfaces/Animal'
import { Platform } from 'react-native'
import { Prescription } from '../../interfaces/Prescription'
import PrescriptionService from '../../services/PrescriptionService'

export default function PrescriptionDetail(props: any) {
  const case_id = props.case_id

  const { t } = useTranslation(SCREEN.PrescriptionDetail)
  const { CaseService, AnimalService } = useServices()

  const getPrescriptions = async () => {
    const prescriptions = await PrescriptionService.getPrescriptionList(case_id)
    return { prescriptions }
  }
  const onHandleButtonAddDiagnosis = async () => {
    const cases: Case = await CaseService.getCase(case_id!)
    const animal: Animal = await AnimalService.getAnimal(
      cases?.animal?.[0]?.id!
    )
    const animalIdentifierList = cases.animal?.map(
      (animal) => animal.identifier
    )
    props.navigation.navigate(STACKS.Main, {
      screen: SCREEN.AddPrescription,
      params: {
        case_id,
        animal_identifier: animalIdentifierList,
        specie: animal?.specie?.id,
        breeder: animal.breeder_id ?? animal.breeder?.id,
      },
    })
  }

  const onHandleButtonPrescriptionCardPress = async (
    prescription: Prescription
  ) => {
    const cases: Case = await CaseService.getCase(case_id!)
    const animal: Animal = await AnimalService.getAnimal(cases?.animal?.[0].id!)
    const animalIdentifierList = cases.animal?.map(
      (animal) => animal.identifier
    )
    props.navigation.navigate(STACKS.Main, {
      screen: SCREEN.AddPrescription,
      params: {
        case_id,
        animal_identifier: animalIdentifierList,
        specie: animal?.specie?.id,
        breeder: animal.breeder_id ?? animal.breeder?.id,
        prescription: prescription.id,
      },
    })
  }

  return (
    <IZScreenContainer
      withScroll
      headerHeight={160}
      footer={
        <IZButton
          buttonColor={getThemeColor(ColorKeys.background, 'light')}
          onPress={onHandleButtonAddDiagnosis}
          style={{
            width: Platform.OS === 'web' ? '200%' : 'auto',
            marginTop: 30,
          }}
        >
          {t(prescriptionDetailI18nKeys.BUTTON_ADD_PRESCRIPTION)}
        </IZButton>
      }
    >
      <DescriptionText style={styles.title}>
        {t(prescriptionDetailI18nKeys.TEXT_PRESCRIPTION)}
      </DescriptionText>
      <StateWrapper
        initialFunction={getPrescriptions}
        emptyStateCondition={({ prescriptions }) => !prescriptions?.length}
      >
        {({ prescriptions }) => (
          <>
            <IZView>
              {prescriptions.map(
                (prescription: Prescription, index: number) => (
                  <PrescriptionCard
                    key={index}
                    prescription={prescription}
                    onPress={onHandleButtonPrescriptionCardPress}
                    t={t}
                  />
                )
              )}
            </IZView>
          </>
        )}
      </StateWrapper>
    </IZScreenContainer>
  )
}
