import { FormatProvider } from './context/format.context'
import FormatCheckBox from './components/FormatCheckBox'
import FormatButton from './components/FormatButton'
import FormatInput from './components/FormatInput'
import FormatRadioButtonGroup from './components/FormatRadioButtonGroup'
import FormatInputSearch from './components/FormatInputSearch'
import FormatDropDown from './components/FormatDropDown'
import FormatDatePicker from './components/FormatDatePicker'

/**
 * IZFormat is an object that provides access to various formatted components
 * and a `FormatProvider` for managing formatting settings.
 *
 * @namespace
 * @property {React.ComponentType} Provider - The `FormatProvider` component for managing formatting settings.
 * @property {React.ComponentType} CheckBox - A formatted checkbox component.
 * @property {React.ComponentType} Button - A formatted button component.
 * @property {React.ComponentType} Input - A formatted input component.
 * @property {React.ComponentType} InputSearch - A formatted input search component.
 * @property {React.ComponentType} RadioButtonGroup - A formatted radio button group component.
 */
const IZFormat = {
  Provider: FormatProvider,
  CheckBox: FormatCheckBox,
  Button: FormatButton,
  Input: FormatInput,
  InputSearch: FormatInputSearch,
  RadioButtonGroup: FormatRadioButtonGroup,
  DropDown: FormatDropDown,
  DatePicker: FormatDatePicker,
}

export default IZFormat
