import React from 'react'
import { CheckBox as CheckboxWeb } from 'react-native-web'
import { Icon, TouchableOpacity, View } from '..'
import { CheckboxProps } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const CheckBoxWebComponent = (
  props: CheckboxProps & { onPress: () => void; editable?: boolean }
) => {
  const editable = props.editable ?? true
  const isChecked = props.status === 'checked'
  return (
    <TouchableOpacity
      style={{
        padding: isChecked ? 3 : 7,
        borderWidth: 1.5,
        borderColor: isChecked ? props.color : getThemeColor(ColorKeys.text),
      }}
      onPress={() => editable && props.onPress()}
    >
      <>
        {isChecked && (
          <Icon icon={'checkbox-marked'} size={8} color={props.color} />
        )}
      </>
    </TouchableOpacity>
  )
}

export default CheckBoxWebComponent
