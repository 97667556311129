import StateWrapper from '../../../components/wrappers/StateWrapper'
import { EvolutionCase } from '../../../interfaces/Case'
import useMessage from '../../../hooks/useMessage'
import summarizedDetail from '../i18n/keys'
import DiagnosisDetails from './DiagnosisDetails'
import { useServices } from '../../../hooks/useServices/useServices'

interface Props {
  case_id: number
  t: (s: string) => string
}
const DiagnosisDetailsList = (props: Props) => {
  const { showMessage } = useMessage()
  const { CaseService } = useServices()
  const getCaseDetails = async (case_id?: number) => {
    if (case_id) {
      try {
        const caseDetail: EvolutionCase[] = await CaseService.getCaseDetail(
          case_id
        )
        return caseDetail
      } catch (error) {
        showMessage({
          content: props.t(summarizedDetail.GET_CASE_DETAIL_ERROR),
          type: 'error',
        })
      }
    }
  }

  return (
    <StateWrapper
      initialFunction={() => getCaseDetails(props.case_id)}
      emptyStateCondition={(caseSelected) => !caseSelected.length}
      renderEmptyState={props.t(summarizedDetail.GET_CASE_DETAIL_EMPTY_STATE)}
    >
      {(caseDetail) => {
        return caseDetail.map((caseEvolution: EvolutionCase) => (
          <DiagnosisDetails
            key={`case-detail-${caseEvolution.id}`}
            case={caseEvolution}
            t={props.t}
          />
        ))
      }}
    </StateWrapper>
  )
}

export default DiagnosisDetailsList
