import styled from 'styled-components'
import TextStyled from '..'
//@ts-ignore
import { TextStyle } from 'react-native'

const PageTitle = (props: any) => {
  return (
    <TextStyled
      {...props}
      style={{ fontSize: 24, fontWeight: 'bold', ...props.style } as TextStyle}
    />
  )
}

export default PageTitle
