import { DescriptionText } from '../../components/atomic-components/Text/variants'
import styles from './styles'
import IZView from '../../components/atomic-components/IZView'
//@ts-ignore
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../constants/screens'
import newCaseI18nKeys from './i18n/keys'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import IZFormat from '../../components/templates/IZFormat'
import IZAdaptiveContainer from '../../components/containers/IZAdaptiveContainer'
//@ts-ignore
import AnimalIdentifierInputSearch from '../../components/templates/AnimalIdentifierInputSearch'
import AnimalInputRega from '../../components/templates/AnimalInputRega'
import SaveButton from './components/SaveButton'
import IZCollapse from '../../components/containers/IZCollapse'
import CaseDropboxAffectedSystems from '../../components/templates/CaseDropboxAffectedSystems'
import CaseDropboxDiagnosisType from '../../components/templates/CaseDropboxDiagnosisType'
import {
  useOptionsAffectedSystems,
  useOptionsAnimalID,
  useOptionsDiagnosisType,
  useOptionsRega,
} from '../../hooks/useOptions'
import AnimalIDFooter from './components/AnimalIDFooter'

function NewCase({ navigation, route }: any) {
  const { t } = useTranslation(SCREEN.NewCase)
  const selectedAnimal = route?.params?.animalSelected
  const { optionsRega } = useOptionsRega()
  const { optionsAnimalID } = useOptionsAnimalID()
  const { optionsAffectedSystems } = useOptionsAffectedSystems()
  const { optionsDiagnosisType } = useOptionsDiagnosisType()

  return (
    <IZScreenContainer
      withScroll
      withFormat
      footer={<SaveButton navigation={navigation} t={t} />}
    >
      <IZView>
        <DescriptionText style={styles.text}>
          {t(newCaseI18nKeys.TEXT_GENERAL_INFORMATION)}
        </DescriptionText>
        <IZAdaptiveContainer itemsPerRow={3} minWidthPerItem={400}>
          <AnimalInputRega
            style={styles.input}
            initialAnimalId={selectedAnimal?.animal_id}
            options={optionsRega}
          />
        </IZAdaptiveContainer>
        <IZAdaptiveContainer itemsPerRow={3} minWidthPerItem={400}>
          <AnimalIdentifierInputSearch
            style={styles.input}
            initialValue={selectedAnimal?.animal_id}
            options={optionsAnimalID}
            footer={<AnimalIDFooter navigation={navigation} route={route} />}
          />
        </IZAdaptiveContainer>

        <DescriptionText style={styles.text}>
          {t(newCaseI18nKeys.TEXT_DIAGNOSIS)}
        </DescriptionText>
        <IZAdaptiveContainer itemsPerRow={1} itemStyle={styles.input}>
          <IZFormat.DatePicker
            name="timestamp"
            value={selectedAnimal?.timestamp}
            label={t(newCaseI18nKeys.INPUT_DATE_START)}
            format="YYYY-MM-DDThh:mm:ssZ"
          />
          <IZFormat.Input
            name={'diagnosis'}
            placeholder={t(newCaseI18nKeys.INPUT_DIAGNOSIS_REQUIRED)}
            value={selectedAnimal?.diagnosis}
            label={t(newCaseI18nKeys.INPUT_DIAGNOSIS)}
            multiline
          />
        </IZAdaptiveContainer>
        <IZCollapse
          headerTitle={t(newCaseI18nKeys.COLLAPSE_MORE_INFO)}
          styleContainer={{ padding: 0 }}
          style={{ marginTop: 20 }}
        >
          <IZAdaptiveContainer itemStyle={{ marginTop: 20 }}>
            <IZFormat.DropDown
              label={t(newCaseI18nKeys.INPUT_SYSTEM_OPTION_LABEL)}
              name="affected_system"
              options={optionsAffectedSystems}
              multiselect
            />
            {/* <CaseDropboxAffectedSystems /> */}
            <IZFormat.DropDown
              label={t(
                newCaseI18nKeys.INPUT_TYPE_DIAGNOSIS_OPTION_LABEL
              )}
              name="diagnosis_type"
              options={optionsDiagnosisType}
            />
          </IZAdaptiveContainer>
        </IZCollapse>
      </IZView>
    </IZScreenContainer>
  )
}
export default NewCase
