import { useTranslation } from 'react-i18next'
import { bundleName } from '../constants'
import stateWrapperI18nKeys from '../i18n/keys'
import { DescriptionText } from '../../../atomic-components/Text/variants'

interface Props {
  text?: string
}
const DefaultEmptyState = (props: Props) => {
  const { t } = useTranslation(bundleName)
  return (
    <DescriptionText>
      {props.text ?? t(stateWrapperI18nKeys.EMPTY_STATE_MESSAGE)}
    </DescriptionText>
  )
}

export default DefaultEmptyState
