import i18next from '../../i18n.config'
import { SCREEN } from "../../constants/screens"
import { navigationScreensConfigType } from "../../navigation/types"
import es from "./i18n/es"
import PrivacyPolicy from '.'

const ScreenName = SCREEN.PrivacyPolicy

i18next.addResourceBundle("es", ScreenName, es)

const PrivacyPolicyConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: PrivacyPolicy,
  options: { headerShown: false },
}

export default PrivacyPolicyConfig
