import React from 'react'
//@ts-ignore
import Header from './Header'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { View } from '../../../libraries/react-native'

interface Props {
  component: () => JSX.Element
  options: NativeStackNavigationOptions
  name: string
}
const WrapperHeader =
  ({ component: Component, options, name }: Props) =>
  (componentProps: any) => {
    return (
      <View>
        <Header {...componentProps} options={options} name={name} />
        <Component {...componentProps} />
      </View>
    )
  }

export default WrapperHeader
