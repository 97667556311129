import IZView from '../IZView'
import styles from './style'
import { DescriptionText } from '../Text/variants'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { ViewStyle } from 'react-native'
import { useState } from 'react'
import { Checkbox } from '../../../libraries/react-native'
import { CheckboxProps } from 'react-native-paper'

export type IZCheckBoxProps = Omit<CheckboxProps, 'status'> & {
  onChange?: (b: boolean) => void
  initialValue?: boolean
  label?: string
  editable?: boolean
  containerStyle?: ViewStyle
  name?: string
}

const IZCheckBox = (props: IZCheckBoxProps) => {
  const [checked, setChecked] = useState(props.initialValue ?? false)

  const editable = props.editable ?? true
  const onHandleCheckboxPress = () => {
    props.onChange?.(!checked)
    setChecked(!checked)
  }
  return (
    <IZView style={{ ...styles.container, ...props.containerStyle }}>
      <Checkbox
        {...props}
        editable={editable}
        status={checked ? 'checked' : 'unchecked'}
        onPress={onHandleCheckboxPress}
        color={getThemeColor(
          props.editable === false ? ColorKeys.disabled : ColorKeys.primary
        )}
      />
      {props.label && (
        <DescriptionText style={{ marginLeft: 10 }}>
          {props.label}
        </DescriptionText>
      )}
    </IZView>
  )
}

export default IZCheckBox
