import React, { useContext, useEffect } from 'react'
import IZCheckBox, {
  IZCheckBoxProps,
} from '../../../atomic-components/IZCheckBox'
import { FormatContext } from '../context/format.context'
import { IZInputProps } from '../../../atomic-components/IZInput'
import IZInput from '../../../atomic-components/IZInput'
import { useAutoFill } from '../hooks/useAutoFill'
import { getPrioritaryItem } from '../../../../utils/objects'

/**
 * FormatInput is a formatted input component that can be used to update formatting settings.
 *
 * @component
 * @param {IZInputProps} props - The props for configuring the FormatInput component.
 * @param {string} [props.name] - An optional name for the input used to identify it in formatting settings.
 * @param {Function} [props.onChangeText] - An optional callback function that is called when the input text changes.
 * It receives the new text value as a parameter.
 * @returns {JSX.Element} A formatted input element.
 */
const FormatInput = (
  props: IZInputProps & { name?: string; initialValue?: string }
) => {
  const { format, setFormat } = useContext(FormatContext)

  const onHandleCheckboxPress = (text: string) => {
    props.name &&
      setFormat((format: any) => ({ ...format, [props.name!]: text }))
    props.onChangeText?.(text)
  }

  const priorizedValue = getPrioritaryItem({
    stateValue: format[props.name!],
    initialValue: props.initialValue,
    defaultValue: '',
  })

  useAutoFill(props.name!, priorizedValue)

  return (
    <IZInput
      {...props}
      value={priorizedValue}
      onChangeText={onHandleCheckboxPress}
    />
  )
}

export default FormatInput
