const localeES = {
  TITLE: 'Actualizar contraseña',
  INPUT_NEW_PASSWORD: 'Nueva contraseña',
  INPUT_REPEAD_PASSWORD: 'Repetir contraseña',
  BUTTON_UPDATE: 'Actualizar',
  UPDATE_SUCCESS: 'La contraseña ha sido actualizada correctamente',
  PASSWORD_ERROR: 'ERROR: las contraseñas no coinciden',
  UPDATE_ERROR: 'Error inesperado, pruebe mas tarde',
  TOKEN_ERROR:
    'Token incorrecto, vuelva a solicitar otro enlace de recuperación',
  UPDATE_PASSWORD_ERROR_UNEXPECTED_ERROR: 'Ha habido un error a la hora de actualizar la contraseña',
  UPDATE_PASSWORD_ERROR_TOO_SHORT: 'La contraseña es demasiado corta, debe tener almenos 8 caracteres',
  UPDATE_PASSWORD_ERROR_TOO_COMMON: 'La contraseña es demasiado comun',
  UPDATE_PASSWORD_ERROR_ONLY_NUMERIC: 'La contraseña debe tener numeros y letras',
}

export default localeES
