import i18next from '../../i18n.config'
import { SCREEN } from "../../constants/screens"
import { navigationScreensConfigType } from "../../navigation/types"
import es from "./i18n/es"
import UpdatePassword from '.'

const ScreenName = SCREEN.UpdatePassword

i18next.addResourceBundle("es", ScreenName, es)

const UpdatePasswordConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: UpdatePassword,
  options: { headerShown: false },
}

export default UpdatePasswordConfig
