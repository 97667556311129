import React, { useMemo, useState } from 'react'
import { ValueLabel } from '../types'
import useModal from '../../../../hooks/useModal'

export const useSelectItems = (props: any) => {
  const { hideModal } = useModal()
  const [selectedItem, setSelectedItem] = useState<ValueLabel[] | ValueLabel | undefined>(undefined)

  const onHandleItemPressMultiple = (item: ValueLabel, prioritaryValueMultiple: any[]) => {
    const itemList = prioritaryValueMultiple.find((obj) => obj.id === item.id)
      ? prioritaryValueMultiple.filter((obj) => obj.id !== item.id)
      : [...prioritaryValueMultiple, item]

    setSelectedItem(itemList)
    props.onChangeItem?.(itemList)
  }

  const onHandleItemPressSimple = (item: ValueLabel) => {
    setSelectedItem(item)
    props.onChangeItem?.(item)
    hideModal()
  }

  return {
    selectedItem,
    onHandleItemPressMultiple,
    setSelectedItem,
    onHandleItemPressSimple,
  }
}
