import React from 'react'
import IZFormat from '../../../components/templates/IZFormat'
import styles from '../styles'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { useServices } from '../../../hooks/useServices/useServices'
import useMessage from '../../../hooks/useMessage'
import summarizedDetailI18n from '../i18n/keys'
import { Animal } from '../../../interfaces/Animal'

interface Props {
  case_id: number
  t: any
}
const SaveButton = ({ case_id, t }: Props) => {
  const { CaseService } = useServices()
  const { showMessage } = useMessage()
  const onHandleButtonSavePress = async (format: any) => {
    try {
      await CaseService.updateCase(case_id, format)
      showMessage({
        content: t(summarizedDetailI18n.SAFE_FILTERS_SUCCESS),
        type: 'success',
      })
    } catch (error) {
      showMessage({
        content: t(summarizedDetailI18n.SAFE_FILTERS_ERROR),
        type: 'error',
      })
    }
  }
  return (
    <IZFormat.Button
      style={styles.buttonContainer}
      buttonColor={getThemeColor(ColorKeys.background)}
      onPress={onHandleButtonSavePress}
    >
      {t(summarizedDetailI18n.BUTTON_SAVE)}
    </IZFormat.Button>
  )
}

export default SaveButton
