import React from 'react'
import IZFormat from '../IZFormat'
import i18next from 'i18next'
import { bundleName } from './constants'
import localeES from './i18n/es'
import { useTranslation } from 'react-i18next'
import caseInputDiagnosisI18nKeys from './i18n/keys'

interface Props {
  initialValue?: string
}
const CaseInputDiagnosis = (props: Props) => {
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)
  return (
    <IZFormat.Input
      {...props}
      name={'diagnosis'}
      label={t(caseInputDiagnosisI18nKeys.INPUT_DIAGNOSIS)}
      multiline
    />
  )
}

export default CaseInputDiagnosis
