import { Platform, ViewStyle } from 'react-native'

const styles = {
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  iconContainer: {
    marginRight: Platform.OS === 'web' ? 0 : 20,
    marginBottom: Platform.OS === 'web' ? 0 : 20,
  } as ViewStyle,
}

export default styles
