import React, { useEffect, useState } from 'react'
import { DescriptionText } from '../../components/atomic-components/Text/variants'
import AuthContainer from '../../components/containers/AuthContainer'
//@ts-ignore
import IZInput from '../../components/atomic-components/IZInput'
import IZButton from '../../components/atomic-components/Button'
import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import updatePasswordI18nKeys from './i18n/keys'
import styles from './styles'
import useMessage from '../../hooks/useMessage'
import PasswordResetService from '../../services/PasswordResetService'

interface Props {
  token?: string
  route?: any
  navigation: any
}
export default function UpdatePassword(props: Props) {
  const token = props.route?.params?.token ?? props.token
  const [password, setPassword] = useState({
    password: '',
    passwordRepeated: '',
  })

  const { t } = useTranslation(SCREEN.UpdatePassword)
  const { showMessage } = useMessage()

  const validateToken = async (token: string) => {
    try {
      const validateTokenResponse = await PasswordResetService.validateToken({
        token: token as string,
      })
      if (validateTokenResponse.status !== 'OK') {
        showMessage({
          content: t(updatePasswordI18nKeys.TOKEN_ERROR),
          type: 'error',
        })
        return false
      } else return true
    } catch (error) {
      showMessage({
        content: t(updatePasswordI18nKeys.TOKEN_ERROR),
        type: 'error',
      })
    }
  }

  const formatMessage = (message: string) => {
    switch (message) {
      case 'This password is too short. It must contain at least 8 characters.':
        return t(updatePasswordI18nKeys.UPDATE_PASSWORD_ERROR_TOO_SHORT)
      case 'This password is too common':
        return t(updatePasswordI18nKeys.UPDATE_PASSWORD_ERROR_TOO_COMMON)
      case 'This password is entirely numeric':
        return t(updatePasswordI18nKeys.UPDATE_PASSWORD_ERROR_ONLY_NUMERIC)
      default:
        return t(message)
    }
  }

  const sendErrorMessage = (message: string) => {
    showMessage({ content: message, type: 'error' })
  }

  const updatePassword = async (password: string, token: string) => {
    try {
      const response = await PasswordResetService.changePassword({
        password: password,
        token: token ?? '',
      })

      if (response.status === 'OK') {
        showMessage({
          content: t(updatePasswordI18nKeys.UPDATE_SUCCESS),
          type: 'success',
        })
        setTimeout(() => {
          props.navigation.navigate(STACKS.Auth)
        }, 2000)
      } else {
        showMessage({
          content: t(updatePasswordI18nKeys.UPDATE_ERROR),
          type: 'error',
        })
      }

      props.navigation.navigate(SCREEN.Login)
    } catch (error: any) {
      const errorData = error?.response?.data?.password ?? [
        updatePasswordI18nKeys.UPDATE_PASSWORD_ERROR_UNEXPECTED_ERROR,
      ]
      errorData.map(formatMessage).forEach(sendErrorMessage)
    }
  }

  const onHandleButtonUpdatePress = async () => {
    if (password.password !== password.passwordRepeated) {
      showMessage({
        content: t(updatePasswordI18nKeys.PASSWORD_ERROR),
        type: 'error',
      })
    } else if (await validateToken(token)) {
      await updatePassword(password.password, token)
    }
  }

  return (
    <AuthContainer>
      <DescriptionText style={styles.title}>{t(updatePasswordI18nKeys.TITLE)}</DescriptionText>
      <IZInput
        style={styles.input}
        secureTextEntry
        value={password.password}
        placeholder={t(updatePasswordI18nKeys.INPUT_NEW_PASSWORD)}
        onChangeText={(text: string) => {
          setPassword((format) => ({ ...format, password: text }))
        }}
        label={t(updatePasswordI18nKeys.INPUT_NEW_PASSWORD)}
      />
      <IZInput
        style={styles.input}
        secureTextEntry
        value={password.passwordRepeated}
        placeholder={t(updatePasswordI18nKeys.INPUT_REPEAD_PASSWORD)}
        onChangeText={(text: string) => {
          setPassword((format) => ({ ...format, passwordRepeated: text }))
        }}
        label={t(updatePasswordI18nKeys.INPUT_REPEAD_PASSWORD)}
      />
      <IZButton style={styles.button} onPress={onHandleButtonUpdatePress}>
        {t(updatePasswordI18nKeys.BUTTON_UPDATE)}
      </IZButton>
    </AuthContainer>
  )
}
