import { ViewStyle } from "react-native"
import { getThemeColor, ColorKeys } from "../../utils/colors"

export const stylesWeb = {
  container: {
    position: 'absolute',
    top: 16,
    left: 16,
    width: 40,
    zIndex: 10000,
  } as ViewStyle
}
export const stylesNative = {
  container: {
    position: 'absolute',
    backgroundColor: getThemeColor(ColorKeys.tabBackground),
    top: 0,
    left: '35%',
    marginLeft: -20,
    width: 150,
    zIndex: 10000,

  } as ViewStyle
}