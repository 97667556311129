import React from 'react'
import { createContext, useState } from 'react'
import AnimalService from '../services/AnimalService'
import CaseService from '../services/CaseService'
import moment from 'moment'
import BreederService from '../services/BreederService'
import PrescriptionService from '../services/PrescriptionService'
import MedicamentService from '../services/MedicamentService'

const DataContext = createContext({} as any)

export type ModalContextProps = {
  animals: any
  cases: any
  fetchData: any
}

/**
 * Provides modal management functionality to components.
 *
 * @param {Object} props - The component properties.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The JSX element representing the modal provider.
 */
function DataProvider(props: any) {
  const RUTE_NAMES = {
    ANIMALS: 'animals',
    CASES: 'cases',
    BREEDERS: 'breeders',
    SPECIE: 'specie',
    DIAGNOSIS_TYPES: 'cases/diagnosis_types',
    AFFECTED_SYSTEMS: 'cases/affected_system',
    EVOLUTION: 'cases/evolution',
  } as const

  type ValDataNames = (typeof RUTE_NAMES)[keyof typeof RUTE_NAMES]
  const [data, setData] = useState<Record<ValDataNames, any>>({
    [RUTE_NAMES.ANIMALS]: undefined,
    [RUTE_NAMES.CASES]: undefined,
    [RUTE_NAMES.BREEDERS]: undefined,
    [RUTE_NAMES.SPECIE]: undefined,
    [RUTE_NAMES.DIAGNOSIS_TYPES]: undefined,
    [RUTE_NAMES.AFFECTED_SYSTEMS]: undefined,
    [RUTE_NAMES.EVOLUTION]: undefined,
  })
  const [expirationTimestamp, setExpirationTimestamp] = useState<any>({})

  // Define functions to fetch data for each route.
  const currentFunctions: Record<ValDataNames, () => any> = {
    [RUTE_NAMES.ANIMALS]: () => AnimalService.getAnimals(),
    [RUTE_NAMES.CASES]: () => CaseService.getCaseList(),
    [RUTE_NAMES.BREEDERS]: () => BreederService.getBreedersList(),
    [RUTE_NAMES.SPECIE]: () => AnimalService.getSpecies(),
    [RUTE_NAMES.DIAGNOSIS_TYPES]: () => CaseService.getTypeDiagnosis(),
    [RUTE_NAMES.AFFECTED_SYSTEMS]: () => CaseService.getAffectedSystem(),
    [RUTE_NAMES.EVOLUTION]: () => CaseService.getEvolution(),
  }

  // Function to update the expiration timestamp for a specific data route.
  const updateExpirationTime = (name: string) => {
    setExpirationTimestamp((currentState: any) => ({
      ...currentState,
      [name]: moment().add(1, 'hours').format(),
    }))
  }

  // Function to remove the expiration timestamp for a specific data route.
  const removeExpirationTime = (name: string) => {
    setExpirationTimestamp((currentState: any) => {
      const nameParts = name.split('/')
      nameParts.forEach((part, index) => {
        const keyToRemove = nameParts.slice(0, index + 1).join('/')

        if (!![keyToRemove]) {
          delete currentState[keyToRemove]
        }
      })
      return currentState
    })
  }

  // Function to update the data for a specific route.
  const updateData = (name: ValDataNames, value: any) => {
    setData((currentState) => ({ ...currentState, [name]: value }))
  }

  // Function to get data with caching, based on the expiration timestamp.
  const getData = async (name: ValDataNames) => {
    const expirationTimeData = expirationTimestamp[name]

    if (
      !expirationTimeData ||
      moment(expirationTimeData).isBefore(moment()) ||
      !data[name] ||
      (Array.isArray(data[name]) && !data[name]?.length)
    ) {
      return await fetchData(name)
    } else {
      return data[name]
    }
  }

  // Function to remove the cache for a specific data route.
  const removeCache = (name: ValDataNames) => {
    const nameWithoutSlash = name.endsWith('/') ? name.slice(0, -1) : name
    removeExpirationTime(nameWithoutSlash)
  }

  // Function to fetch data for a specific route, with caching and expiration timestamp updates.
  const fetchData = async (name: ValDataNames) => {
    const currentFunction = (currentFunctions as any)[name]
    if (currentFunction) {
      const data = await currentFunction()
      updateData(name, data)
      updateExpirationTime(name)
      return data
    }
  }

  const fetchAllData = async () => {
    await Promise.all(Object.values(RUTE_NAMES).map((name) => fetchData(name)))
  }

  return (
    <DataContext.Provider
      value={{ data, fetchAllData, fetchData, getData, removeCache }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export { DataContext, DataProvider }
