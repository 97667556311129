import { Modal } from 'react-native'
import { Icon, View } from '../../../libraries/react-native'
import ModalSearchInput from './components/ModalSearchInput'
import { IZInputSearchProps } from './types'
import MultipleInputSearch from './components/MultipleInputSearch'
import SimpleInputSearch from './components/SimpleInputSearch'
import { getPrioritaryValueMultiple, getPrioritaryValueSimple } from './utils/prioritaryValue'
import { getModalProps, getPrincipalProps } from './utils/props'
import { useSelectItems } from './hooks/useSelectItem'
import { useEffect, useState } from 'react'
import useModal from '../../../hooks/useModal'

const IZInputSearch = (props: IZInputSearchProps) => {
  const { selectedItem, setSelectedItem, onHandleItemPressMultiple, onHandleItemPressSimple } = useSelectItems(props)

  const { modal, showModal, secondaryModal } = useModal()

  const editable = props.editable===undefined || props.editable
  useEffect(() => {
    props.name == modal.id && modal.isVisible && showModalSearchInput()
  }, [props.options?.length, modal.isVisible, secondaryModal.isVisible])

  const showModalSearchInput = () => {
   editable && showModal({
      id: props.name,
      Component: ({ onCancel }) => (
        <ModalSearchInput
          {...modalProps}
          emptyStateText={props.emptyStateText}
          searchText={props.searchText}
          onChangeSearchText={props.onChangeSearchText}
          options={props.options}
          onDismiss={onCancel}
          textInputProps={{ ...inputProps, left }}
          selectedItems={props.multiline ? prioritaryValueMultiple : prioritaryValueSimple}
          onHandleItemPress={
            props.multiline
              ? (item) => onHandleItemPressMultiple(item, prioritaryValueMultiple)
              : onHandleItemPressSimple
          }
        />
      ),
    })
  }
  const onHandlePress = () => {
    if (editable !== false) {
      showModalSearchInput()
    }
  }

  const onHandleSelectedItem = (item: any) => {
    setSelectedItem?.(item)
    props.onChangeItem?.(item)
  }
  const {
    principalProps: { value, initialValue, placeholder },
    restProps,
  } = getPrincipalProps(props)
  const { modalProps, restProps: inputProps } = getModalProps(restProps)

  const prioritaryValueMultiple = getPrioritaryValueMultiple({ value, initialValue, selectedItem: selectedItem })

  const prioritaryValueSimple = getPrioritaryValueSimple({ value, initialValue, selectedItem: selectedItem })
  const left = props.left ?? <Icon icon={'magnify'} />

  return (
    <View style={props.style}>
      {props.multiline ? (
        <MultipleInputSearch
          {...inputProps}
          editable={editable}
          placeholder={placeholder}
          left={left}
          options={props.options}
          value={prioritaryValueMultiple}
          onPress={onHandlePress}
          onChangeText={onHandleSelectedItem}
        />
      ) : (
        <SimpleInputSearch
          {...inputProps}
          editable={editable}
          options={props.options}
          placeholder={placeholder}
          left={left}
          value={prioritaryValueSimple}
          onPress={onHandlePress}
        />
      )}
    </View>
  )
}

export default IZInputSearch
