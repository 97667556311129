import { useContext } from 'react'
import { FormatContext } from '../context/format.context'
import { useTranslation } from 'react-i18next'
import { bundleNameIZFormat } from '../constants'
import izFormatI18nKeys from '../i18n/keys'

const getLocalizatedValue = (value: string[], t: any) => {
  const locatedValuesList = value.map((str: string) => {
    switch (str) {
      case '""" is not a valid choice."':
        return t(izFormatI18nKeys.DROPBOX_NO_SELECTION)
      case 'This field is required.':
        return t(izFormatI18nKeys.FIELD_REQUIRED)
      default:
        return str
    }
  })
  return locatedValuesList.join('\n')
}

export const useForm = () => {
  const { setError } = useContext(FormatContext)
  const { t } = useTranslation(bundleNameIZFormat)

  const showAxiosErrors = (axiosError: any) => {
    try {
      const dataEntries: any = Object.entries(axiosError.response.data)
      const data = Object.fromEntries(
        dataEntries.map(([key, value]: [string, string[]]) => [
          key,
          getLocalizatedValue(value, t),
        ])
      )
      setError(data)
    } catch (error) {}
  }

  return { showAxiosErrors }
}
