import { useEffect, useState } from 'react'

export const useAsyncFunctionState = (func: any, defaultValue?: any) => {
  const [data, setData] = useState<any>(defaultValue ?? undefined)

  useEffect(() => {
    ;(async () => {
      try {
        const data = (await func()) ?? defaultValue
        setData(data)
      } catch (error) {}
    })()
  }, [])
  return data
}
