//@ts-ignore
import '../fixSearch.css'
import HibridInput from './HibridInput'
import IZView from '../../IZView'
import { IZInputProps } from '..'
import { View } from '../../../../libraries/react-native'
import { styles } from '../styles'
import { DescriptionText, ErrorText } from '../../Text/variants'

const IZInputWeb = (props: IZInputProps) => {
  const { left, right, label, style: componentStyles, ...inputProps } = props

  return (
    <View style={componentStyles}>
      {props.label && (
        <DescriptionText style={{ fontSize: 12 }}>
          {props.label}
        </DescriptionText>
      )}
      <IZView
        //@ts-ignore
        style={{
          ...styles.inputContainer(props),
        }}
      >
        <View style={styles.container} testID="izinputContainer">
          <View>{left}</View>
          <HibridInput {...inputProps} />
        </View>
        {right}
      </IZView>
      {props.description && (
        <DescriptionText style={{ fontSize: 12, marginLeft: 16, marginTop: 4 }}>
          {props.description}
        </DescriptionText>
      )}
      {props.error && (
        <ErrorText style={{ fontSize: 16, marginLeft: 16, marginTop: 4 }}>
          {props.error}
        </ErrorText>
      )}
    </View>
  )
}

export default IZInputWeb
