const localeES = {
  INPUT_PRONOSTIC_OPTION_DEFAULT: '---',
  INPUT_PRONOSTIC_OPTION_LABEL: 'Evolución',
  INPUT_PRONOSTIC_OPTION_CURED: 'Curado',
  INPUT_PRONOSTIC_OPTION_NOTCURED: 'No curado',
  INPUT_PRONOSTIC_OPTION_IMPROVED: 'Mejora pero no cura',
  INPUT_PRONOSTIC_OPTION_NOTIMPROVED: 'Empeora y no cura',
  INPUT_PRONOSTIC_OPTION_INCONCLUSIVE: 'No concluyente',
  INPUT_PRONOSTIC_OPTION_DEATH: 'Muerte',
  INPUT_PRONOSTIC_OPTION_UNKNOWN: 'No sabe',
}

export default localeES
