import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Animal } from '../interfaces/Animal'
import { Case } from '../interfaces/Case'
import { Prescription } from '../interfaces/Prescription'
import { CallBackParams } from '../utils/service'
import AnimalService from './AnimalService'
import ApiClient from './ApiClient'
import CaseService from './CaseService'

class PrescriptionService {
  public static getPrescriptionList = async (case_id: number, callback?: Function): Promise<Prescription[]> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, `prescription/?case_id=${case_id}`, undefined, callback)
  }
  public static getShareLink = async (presciption_id: number, callback?: Function): Promise<any> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, `shareurl/breeder/${presciption_id}`, undefined, callback)
  }

  public static createPrescription = async (
    prescription: Prescription,
    callback?: Function
  ): Promise<Prescription> => {
    
    const headers ={
      'Content-Type': 'multipart/form-data',
    }
    return await ApiClient.post(BACKEND_BASE_URL_V1, 'prescription/', prescription, callback,headers)
  }

  public static updatePrescription = async (
    id: number,
    prescription: Prescription,
    callback?: CallBackParams
  ): Promise<Prescription> => {
    const headers ={
      'Content-Type': 'multipart/form-data',
    }
    return await ApiClient.patch(BACKEND_BASE_URL_V1, `prescription/${id}/`, prescription, callback,headers)
  }

  public static getPrescription = async (id: number, callback?: Function): Promise<Prescription> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, `prescription/${id}`, undefined, callback)
  }

  public static getLastPrescription = async (
    veterinarian_id: number,
    case_id: number,
    callback?: Function
  ): Promise<any> => {
    const lastPrescription = await ApiClient.get(
      BACKEND_BASE_URL_V1,
      `prescription?last=1&veterinarian_id=${veterinarian_id}&case_id=${case_id}`,
      undefined,
      callback
    )
    return lastPrescription?.[0]
  }

  public static deletePrescription = async (id: number, callback?: CallBackParams): Promise<Prescription> => {
    const data = await ApiClient.delete(BACKEND_BASE_URL_V1, `prescription/${id}/`, callback)
    return data
  }
}
export default PrescriptionService
