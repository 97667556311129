import { useContext, useEffect } from 'react'
import { TouchableOpacity, View } from '../../../libraries/react-native'
import StateWrapper from '../../../components/wrappers/StateWrapper'
import {
  DescriptionText,
  Link,
} from '../../../components/atomic-components/Text/variants'
import MedicalCaseDetail from './MedicalCaseDetail'
//@ts-ignore
import { FlatList, Platform } from 'react-native'
import { SearchContext } from '../../../context/search.context'
import { useStateWrapperReload } from '../../../components/wrappers/StateWrapper/hooks/useStateWrapperReload'
import { useNavigation } from '@react-navigation/native'
import { SCREEN, STACKS } from '../../../constants/screens'
import { useTranslation } from 'react-i18next'
import medicalCasesI18nKeys from '../i18n/keys'
import styles from '../styles'
import { Case } from '../../../interfaces/Case'
import useMessage from '../../../hooks/useMessage'
import { debounce } from '../../../utils/api'
import { useServices } from '../../../hooks/useServices/useServices'
import i18next from 'i18next'

const MedicalCasesList = () => {
  const { searchText } = useContext(SearchContext)
  const navigation = useNavigation()
  const { CaseService } = useServices()
  const { showMessage } = useMessage()
  const { ref: stateWrapperRef } = useStateWrapperReload({
    reloadWhenFocus: true,
  })

  const debouncedSearch = debounce(() => {
    stateWrapperRef.current?.reload?.()
  }, 500)

  useEffect(() => {
    debouncedSearch()
  }, [searchText])

  const { t } = useTranslation(SCREEN.MedicalCases)
  const onHandleInitialFunction = async () => {
    try {
      const caseList = await CaseService.getCaseList(searchText)
      return { data: caseList }
    } catch (error) {
      showMessage({
        content: t(medicalCasesI18nKeys.GET_CASE_ERROR),
        type: 'error',
      })
      return { data: [] }
    }
  }
  const isEmptyStateCondition = ({ data }: { data: Case[] }) => {
    return !data || !data.length
  }

  return (
    <View>
      <View style={styles.listLabelContainer}>
        <DescriptionText>
          {t(medicalCasesI18nKeys.TEXT_CASES_LIST)}
        </DescriptionText>
        <Link
          onPress={() => {
            //@ts-ignore
            navigation.navigate(SCREEN.AddAnimal)
          }}
        >
          {t(medicalCasesI18nKeys.LINK_NEW_ANIMAL)}
        </Link>
      </View>
      <StateWrapper
        ref={stateWrapperRef}
        initialFunction={onHandleInitialFunction}
        emptyStateCondition={isEmptyStateCondition}
      >
        {({ data }: { data: Case[] }) => (
          <FlatList
            data={data}
            style={{ marginBottom: Platform.OS !== 'web' ? 50 : 0 }}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  //@ts-ignore
                  navigation.navigate(STACKS.CaseDetail, {
                    screen: SCREEN.SummarizedDetail,
                    case_id: item.id,
                  })
                }}
              >
                <MedicalCaseDetail item={item} t={t} />
              </TouchableOpacity>
            )}
          />
        )}
      </StateWrapper>
    </View>
  )
}

export default MedicalCasesList
