import { useState } from 'react'
import { TouchableOpacity, View } from '../../../../libraries/react-native'
import IZInput, { IZInputProps } from '../../IZInput'

type ValueLabel = {
  value: any
  label: string
}
const SimpleInputSearch = (
  props: Omit<IZInputProps, 'onChangeText' | 'value'> & {
    onPress: () => void
    value?: any
    options: ValueLabel[]
  }
) => {
  const contentValue: any = props.options?.find((opt) => props.value.value === opt.value)?.label ?? ''

  var textLines = contentValue?.toString()?.split('\n')?.length - 1

  return (
    <View style={props.style}>
      <TouchableOpacity onPress={!props.loading ? props.onPress : undefined}>
        <IZInput {...props} disabled={true} value={contentValue} multiline={!!textLines} />
      </TouchableOpacity>
    </View>
  )
}

export default SimpleInputSearch
