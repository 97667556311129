import { useState } from 'react'
import MultipleTag from './MultipleTag'
import { TouchableOpacity } from '../../../../libraries/react-native'
import { getPrioritaryItem } from '../../../../utils/objects'
import IZInput, { IZInputProps } from '../../IZInput'

type ValueLabel = {
  value: any
  label: string
}
const MultipleInputSearch = (
  props: Omit<IZInputProps, 'onChangeText' | 'value'> & {
    onChangeText: (x: any) => void
    onPress: () => void
    value: any[]
    options: ValueLabel[]
  }
) => {
  const { value, ...inputProps } = props
  const values = value.map((val) => val.value)
  const contentValue = props.options
    ?.filter((opt) => values.includes(opt?.value))
    ?.map((obj) => JSON.stringify(obj))
    ?.join(';')

  const onHandlePressTag = (tag: string) => {
    const currentItemsSelected = (value as ValueLabel[])?.filter?.((obj: ValueLabel) => obj?.value !== tag)
    props.onChangeText?.(currentItemsSelected)
  }

  const Tag = (renderProps: any) => {
    return <MultipleTag {...renderProps} onPress={onHandlePressTag} />
  }

  return (
    <>
      <TouchableOpacity onPress={!props.loading ? props.onPress : undefined}>
        <IZInput {...inputProps} disabled={true} render={contentValue ? Tag : undefined} value={contentValue} />
      </TouchableOpacity>
    </>
  )
}

export default MultipleInputSearch
