import addAnimalI18nKeys from './i18n/keys'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../constants/screens'
import styles from './styles'
import IZFormat from '../../components/templates/IZFormat'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import IZAdaptiveContainer from '../../components/containers/IZAdaptiveContainer'
import { DescriptionText } from '../../components/atomic-components/Text/variants'
import useMessage from '../../hooks/useMessage'
import { optionsGender } from './constants'
import { useRoute } from '@react-navigation/native'
import AnimalInputSpecie from '../../components/templates/AnimalInputSpecie'
import AnimalInputRega from '../../components/templates/AnimalInputRega'
import { useServices } from '../../hooks/useServices/useServices'
import { toArray } from '../../utils/objects'
import { useOptionsRega } from '../../hooks/useOptions'
import SaveButton from './components/SaveButton'

export default function AddAnimal({ navigation }: any) {
  const { t } = useTranslation(SCREEN.AddAnimal)
  const route = useRoute()
  //@ts-ignore
  const animalSelected = route?.params?.animal
  const { optionsRega } = useOptionsRega()

  return (
    <IZScreenContainer
      withFormat
      withScroll
      footer={
        <SaveButton
          t={t}
          navigation={navigation}
          route={route}
          animalSelected={animalSelected}
        />
      }
    >
      <DescriptionText>{t(addAnimalI18nKeys.SCREEN_TITLE)}</DescriptionText>
      <IZAdaptiveContainer>
        <IZFormat.Input
          name="identifier"
          label={t(addAnimalI18nKeys.INPUT_LABEL_ID)}
          description={t(addAnimalI18nKeys.INPUT_DESCRIPTION_ID)}
          style={styles.inputStyle}
        />
      </IZAdaptiveContainer>
      <IZAdaptiveContainer itemSeparation={20} minWidthPerItem={250}>
        <IZFormat.Input
          name="batch_number"
          style={styles.nAnimalsInputStyle}
          description={t(addAnimalI18nKeys.INPUT_DESCRIPTION_N_ANIMALS)}
          label={t(addAnimalI18nKeys.INPUT_LABEL_N_ANIMALS)}
        />
        <AnimalInputSpecie />
        <IZFormat.DropDown
          style={styles.nAnimalsInputStyle}
          label={t(addAnimalI18nKeys.DROPDOWN_LABEL_GENDER)}
          description={t(addAnimalI18nKeys.DROPDOWN_DESCRIPTION_GENDER)}
          name="gender"
          options={optionsGender(t)}
        />
        <AnimalInputRega options={optionsRega} />
      </IZAdaptiveContainer>
    </IZScreenContainer>
  )
}
