import { useState } from 'react'
import { View } from '../../../libraries/react-native'
import prescriptionI18nKeys from '../i18n/keys'
import { Dimensions } from 'react-native'
import MedicationData from './MedicationData'
import { formatString } from '../../../utils/strings'

interface Props {
  medication: any
  t: (s: string) => string
}
const Medication = (props: Props) => {
  const { t, medication } = props
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width)

  const handleScreenResize = () => {
    setScreenWidth(Dimensions.get('window').width)
  }

  Dimensions.addEventListener('change', handleScreenResize)
  const isAllInRow = screenWidth > 800
  return (
    <>
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_NAME_MEDICATION)}
        value={medication.presentation_name}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_NREGISTER_MEDICATION)}
        value={medication.nregister}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_NATIONAL_CODE)}
        value={medication.national_code}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_ACTIVE_PRINCIPLE_MEDICATION)}
        value={medication.active_principle}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_INDICATIONS_MEDICATION)}
        value={formatString(medication.indications)}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_AFFECTION_MEDICATION)}
        value={medication.affection}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_COMPOSITION)}
        value={formatString(medication.composition)}
      />
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_ATC_CODE)}
        value={formatString(medication.atc_code)}
      />

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {isAllInRow && (
          <MedicationData
            label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_ADMINISTRATION)}
            value={formatString(medication.administration_route)}
          />
        )}
      </View>
      {!isAllInRow && (
        <MedicationData
          label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_ADMINISTRATION)}
          value={medication.pharmaceutical_form}
        />
      )}
      <MedicationData
        label={t(prescriptionI18nKeys.TREAMENT_PRESCRIBED_LABEL_TIME_WAIT)}
        value={formatString(medication.waiting_time)}
      />
    </>
  )
}

export default Medication
