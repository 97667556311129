import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import DefaultEmptyState from './components/DefaultEmptyState'
import i18next from 'i18next'
import localeES from './i18n/es'
import { bundleName } from './constants'
import DefaultError from './components/DefaultError'
import { ViewStyle } from 'react-native'
import { View } from '../../../libraries/react-native'
import IZLoading from '../../atomic-components/Loading'
import { StateWrapperProps } from './type'

/**
 * StateWrapper is a higher-order component that manages the state of a component's data-fetching process,
 * displaying loading, error, and empty state conditions as needed.
 *
 * @component
 * @param {StateWrapperProps} props - Props for configuring the behavior of the StateWrapper component.
 * @param {Function} ref - A reference to the component instance that can trigger a reload of data.
 * @property {Function} props.initialFunction - A function that will be called to fetch initial data when the component is mounted. This function should return the fetched data.
 * @property {Function} props.children - A function that takes the fetched data and returns a JSX element representing the main content of the component when data is successfully fetched.
 * @property {Function} [props.emptyStateCondition] - An optional condition function that determines whether the empty state should be shown based on the fetched data. If this function returns `true`, the empty state will be displayed.
 * @property {JSX.Element} [props.renderEmptyState] - An optional JSX element that represents the content to be displayed when the component's data is empty and the empty state condition is met.
 * @property {Function} [props.renderError] - An optional function that takes an error object and returns a JSX element representing the content to be displayed when an error occurs during data fetching.
 * @property {ViewStyle} [props.containerStyle] - An optional style object that can be used to apply custom styles to the container of the component.

 * @returns {JSX.Element} A React element that encapsulates the data-fetching and state management logic.
 */
const StateWrapper = forwardRef((props: StateWrapperProps, ref: any) => {
  i18next.addResourceBundle('es', bundleName, localeES)

  type Status = 'loading' | 'empty-state' | 'completed' | 'error'

  const [state, setState] = useState<{ status: Status; data?: any }>({
    status: 'loading',
    data: '',
  })

  useImperativeHandle(ref, () => ({
    reload() {
      setState({ status: 'loading' })
    },
  }))

  useEffect(() => {
    ;(async () => {
      if (state.status === 'loading') {
        try {
          const data = await props.initialFunction()
          if (props.emptyStateCondition?.(data)) {
            setState({
              status: 'empty-state',
            })
          } else {
            setState({
              status: 'completed',
              data,
            })
          }
        } catch (error) {
          const errorMessage = (error as any).message
          setState({
            status: 'error',
            data: errorMessage,
          })
        }
      }
    })()
  }, [state.status])

  return (
    <View>
      {state.status === 'loading' && <IZLoading />}
      {state.status === 'completed' && props.children(state.data)}
      {state.status === 'error' &&
        (props.renderError ? props.renderError(state.data) : <DefaultError />)}
      {state.status === 'empty-state' &&
        (typeof props.renderEmptyState === 'object' ? (
          props.renderEmptyState
        ) : (
          <DefaultEmptyState
            text={props.renderEmptyState as string | undefined}
          />
        ))}
    </View>
  )
})

export default StateWrapper
