import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import PrescriptionDetail from '.'
import { getCurrentTranslation } from '../../utils/i18n'

const ScreenName = SCREEN.PrescriptionDetail

i18next.addResourceBundle('es', ScreenName, es)

const PrescriptionDetailConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: PrescriptionDetail,
  options: { title: getCurrentTranslation({ es }).TITLE },
}

export default PrescriptionDetailConfig
