import React, { useEffect, useState } from 'react'
import IZButton from '../Button'
import { DescriptionText } from '../Text/variants'
import { Icon, View } from '../../../libraries/react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import DocumentPicker from '../../../libraries/react-native-document-picker'
import { DocumentPickerResponse } from 'react-native-document-picker'
import ApiClient from '../../../services/ApiClient'
import { downloadFromBlob, downloadFromUrl } from '../../../utils/attachedFile'

interface Props {
  value: any
  label: string
  onChange?: (x: any) => void
  editable?: boolean
  onDownload?: () => void
}
const IZFilePicker = (props: Props) => {
  const [value, setValue] = useState(props.value)
  const [filePath, setFilePath] = useState('')

  useEffect(() => {
    ;(async () => {
      if (typeof props.value === typeof '') {
        try {
          const filePathArray = (props.value as string).split('/')
          const filePathStr = filePathArray[filePathArray.length - 1]
          setFilePath(filePathStr)
        } catch (error) {
          setValue(undefined)
          props.onChange?.(undefined)
        }
      } else {
        setValue(props.value)
      }
    })()
  }, [props.value])

  const color = getThemeColor(
    props.editable === false ? ColorKeys.disabled : ColorKeys.primary
  )
  const onHandleClose = () => {
    props.onChange?.(undefined)
    setValue(undefined)
    setFilePath('')
  }
  const onHandlePressButtonDocumentPicker = async () => {
    const document: DocumentPickerResponse[] = await DocumentPicker.pick()
    const documentFileName = document[0]?.name
    props.onChange?.(document[0])
    setValue(document[0])
    setFilePath(documentFileName!)
  }

  const onHandleDownload = async() => {
    if (typeof value !== typeof '') {

      const blob = new Blob([value], { type: 'application/octet-stream' })
      await downloadFromBlob(blob, filePath)
    } else {
      downloadFromUrl(value, filePath)
    }
    props.onDownload?.()
  }
  return (
    <>
      {!value && (
        <IZButton
          mode="outlined"
          disabled={props.editable === false}
          onPress={onHandlePressButtonDocumentPicker}
        >
          {props.label}
        </IZButton>
      )}
      {value && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <DescriptionText style={{ color }}>{filePath}</DescriptionText>
          <View style={{ flexDirection: 'row', marginRight: 25 }}>
            {value && (
              <Icon
                icon={'download'}
                onPress={onHandleDownload}
                color={color}
              />
            )}
           {(props.editable !== false) && <View style={{ marginLeft: 20 }}>
              <Icon icon={'close'} color={color} onPress={onHandleClose} />
            </View>}
          </View>
        </View>
      )}
    </>
  )
}

export default IZFilePicker
