import TextStyled from '..'
import { ColorKeys, getThemeColor } from '../../../../utils/colors'

const ErrorText = (props: any) => {
  return (
    <TextStyled
      {...props}
      style={{
        fontSize: 16,
        lineHeight: 20,
        color: getThemeColor(ColorKeys.error),
        ...props.style,
      }}
    />
  )
}

export default ErrorText
