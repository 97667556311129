
export function formatString(input: string): string {

  const removeUnmatchedParentheses = (str: string): string => {
    let result = ''
    let count = 0

    for (const char of str) {
      if (char === '(') {
        count++
      } else if (char === ')') {
        if (count > 0) {
          count--
        } else {
          continue
        }
      }

      result += char
    }

    return result
  }

  // Remove parenthesis without it's match
  input = removeUnmatchedParentheses(input)

  // Add space after comma
  input = input.replace(/,([^ ])/g, ', $1')

  // Remove space before comma if there is one
  input = input.replace(/\s+,/g, ',')

  // Add space before initial parenthesis
  input = input.replace(/([^\s])\(/g, '$1 (')

  return input
}