import { Dimensions } from 'react-native'
import { RootTabParamList, navigationScreensConfigType } from '../../types'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
//@ts-ignore
import './styles'
import { SCREEN } from '../../../constants/screens'
import localeES from '../../i18n/es'
import i18next from 'i18next'
import SummarizedDetailConfig from '../../../screens/SummarizedDetail/config'
import EvidenceDetailConfig from '../../../screens/EvidenceDetail/config'
import PrescriptionDetailConfig from '../../../screens/PrescriptionDetail/config'

function BottomTabNavigator({ navigation, route }: any) {
  const case_id = route.params?.case_id
  const bottonTabNavigationScreensConfig: navigationScreensConfigType[] = [
    SummarizedDetailConfig,
    EvidenceDetailConfig,
    PrescriptionDetailConfig,
  ]

  const Tab = createMaterialTopTabNavigator<RootTabParamList>()

  const wrappedComponent = (Component: any) => (props: any) => <Component {...props} case_id={case_id} />
  return (
    <>
      <Tab.Navigator
        initialRouteName={SCREEN.SummarizedDetail}
        initialLayout={{
          width: Dimensions.get('window').width,
          height: Dimensions.get('window').height,
        }}
        screenOptions={{
          tabBarActiveTintColor: getThemeColor(ColorKeys.primary),
          tabBarInactiveTintColor: getThemeColor(ColorKeys.tabIconDefault),
          tabBarStyle: {
            backgroundColor: getThemeColor(ColorKeys.tabBackground),
          },
        }}
      >
        {bottonTabNavigationScreensConfig.map((props: any, index) => (
          <Tab.Screen
            {...props}
            key={`${props.name}-${index}`}
            options={() => ({
              tabBarLabel: props.options.title,
              tabBarLabelStyle: { width: 400 },
              tabBarIndicatorStyle: {
                backgroundColor: getThemeColor(ColorKeys.primary),
                height: 3,
              },
              ...props.options,
            })}
            component={wrappedComponent(props.component)}
          />
        ))}
      </Tab.Navigator>
    </>
  )
}

export default BottomTabNavigator
