import React, { createContext, useState, ReactNode } from 'react'

/**
 * Context for managing messages.
 */
const MessageContext = createContext<{
  messageInfo: {
    content: string
    type: 'no' | 'error' | 'success' | 'warning' | 'transparent'
    isVisible: boolean
    duration?: number
  }
  showMessage: (info: {
    content: string
    type: 'error' | 'success' | 'warning'
    duration?: number
  }) => void
  setMessageInfo: React.Dispatch<
    React.SetStateAction<{
      content: string
      type: 'error' | 'success' | 'warning' | 'transparent'
      isVisible: boolean
      duration?: number
    }>
  >
}>({} as any)

/**
 * Provides message management functionality to components.
 *
 * @param {Object} props - The component properties.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The JSX element representing the message provider.
 */
function MessageProviderWrapper(props: { children: ReactNode }) {
  const [messageInfo, setMessageInfo] = useState<{
    content: string
    type: 'error' | 'success' | 'warning' | 'transparent'
    isVisible: boolean
    duration?: number
  }>({
    content: '',
    type: 'success',
    isVisible: false,
    duration: undefined,
  })

  const showMessage = ({
    content,
    type,
    duration,
  }: {
    content: string
    type: 'error' | 'success' | 'warning'
    duration?: number
  }) => {
    setMessageInfo({
      content,
      type,
      isVisible: true,
      duration,
    })
  }

  return (
    <MessageContext.Provider
      value={{ messageInfo, showMessage, setMessageInfo }}
    >
      {props.children}
    </MessageContext.Provider>
  )
}

export { MessageContext, MessageProviderWrapper }
