import React from 'react'
import { RadioButton } from 'react-native-paper'
import IZView from '../IZView'
import { DescriptionText } from '../Text/variants'
import styles from './style'
import { ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { View } from '../../../libraries/react-native'

export type IZRadioButtonGroupProps = {
  buttons: { label?: string; value: string }[]
  onValueChange?: (value: string) => void
  buttonContainerStyle?: ViewStyle
  buttonGroupContainerStyle?: ViewStyle
  label?: string
}
const IZRadioButtonGroup = (props: IZRadioButtonGroupProps) => {
  const [value, setValue] = React.useState(props.buttons[0]?.value)

  const onHandleRadioButtonValueChange = (newValue: string) => {
    setValue(newValue)
    props.onValueChange?.(newValue)
  }

  return (
    <View>
      {props.label && <DescriptionText>{props.label}</DescriptionText>}
      <RadioButton.Group
        onValueChange={onHandleRadioButtonValueChange}
        value={value}
      >
        <IZView
          style={{ ...styles.container, ...props.buttonGroupContainerStyle }}
        >
          {props.buttons.map((button, index) => (
            <IZView
              style={{ ...styles.container, ...props.buttonContainerStyle }}
              key={`buttons-${index}`}
            >
              <RadioButton
                value={button.value}
                color={getThemeColor(ColorKeys.primary)}
              />
              {button.label && (
                <DescriptionText>{button.label}</DescriptionText>
              )}
            </IZView>
          ))}
        </IZView>
      </RadioButton.Group>
    </View>
  )
}

export default IZRadioButtonGroup
