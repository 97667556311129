import { useState } from 'react'
import { Icon, View } from '../../../libraries/react-native'
import IZInput from '../../../components/atomic-components/IZInput'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import IZDropdown from '../../../components/atomic-components/IZDropdown'
import styles from '../../../components/atomic-components/IZInputSearch/styles'
import IZButton from '../../../components/atomic-components/Button'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { FilterProp } from '../../../components/atomic-components/IZInputSearch/types'
import prescriptionI18nKeys from '../i18n/keys'

type Props = {
  filters: FilterProp[]
  filtersValues: any
  onCancel?: () => void
  onSubmitFilters: (filters: any[]) => void
  t: (x: string) => string
}
const ModalFilters = (props: Props) => {
  const [form, setForm] = useState<any>(props.filtersValues ?? {})
  const { t } = props

  const onHandleChangeText = (name: string) => (value: any) => {
    setForm((form: any) => ({ ...form, [name]: value }))
  }
  return (
    <View style={styles.containerModal}>
      <DescriptionText>{t(prescriptionI18nKeys.FILTERS_MODAL_TITLE)}</DescriptionText>
      {props.filters?.map((filter: FilterProp, index) => {
        let InputWrapper
        if (filter.type === 'text-input') {
          InputWrapper = (
            <IZInput
              {...filter}
              style={styles.filter}
              value={form[filter.name]}
              onChangeText={onHandleChangeText(filter.name)}
            />
          )
        } else if (filter.type === 'dropdown') {
          InputWrapper = (
            // @ts-ignore
            <IZDropdown
              {...filter}
              style={styles.filter}
              value={form[filter.name]?.toString()}
              options={filter.options!}
              onChange={onHandleChangeText(filter.name)}
            />
          )
        }
        return <View key={`filter-${index}`}>{InputWrapper}</View>
      })}
      <View style={styles.buttonContainer}>
        <IZButton
          buttonColor={getThemeColor(ColorKeys.background)}
          style={styles.buttonClean}
          onPress={() => {
            setForm({})
          }}
        >
          {t(prescriptionI18nKeys.FILTERS_MODAL_CLEAN)}
        </IZButton>
        <IZButton
          onPress={() => {
            props.onSubmitFilters(form)
            setTimeout(() => {
              props.onCancel?.()
            }, 200)
          }}
        >
          {t(prescriptionI18nKeys.FILTERS_MODAL_SAFE)}
        </IZButton>
      </View>
    </View>
  )
}

export default ModalFilters
