const localeES = {
  TITLE: 'Añadir diagnóstico',
  TEXT_GENERAL_INFORMATION: 'Información general',
  NEW_DIAGNOSIS_EVOLUTION: 'Nueva Evo. Diagnóstica',
  TEXT_DIAGNOSIS: 'Diagnóstico',
  CASE_DETAILS_CREATE_SUCCESS: 'El diagnostico se ha creado correctamente',
  CASE_DETAILS_CREATE_ERROR: 'Ha habido un error en la creación del diagnostico',
  INPUT_PLACEHOLDER_SYSTEM_AFFECTED: '*Sistema/s afectados',
  INPUT_DESCRIPTION_SYSTEM_AFFECTED: 'Selecciona las opciones necesarias',
  INPUT_PLACEHOLDER_DIAGNOSIS: '*Diagnóstico',
  INPUT_DESCRIPTION_DIAGNOSIS: 'Describe el diagnóstico',
  INPUT_PLACEHOLDER_DATE: 'Fecha',
  INPUT_DESCRIPTION_DATE: 'Selecciona una fecha',
  INPUT_PLACEHOLDER_DIAGNOSIS_TYPE: '*Tipo de diagnóstico',
  INPUT_DESCRIPTION_DIAGNOSIS_TYPE: 'Selecciona una opción',
  INPUT_PLACEHOLDER_EVOLUTION: 'Evolución',
  INPUT_DESCRIPTION_EVOLUTION: 'Selecciona una opción',
  INPUT_MORE_INFO: 'Más info. diagnóstica',
  BUTTON_SAVE: 'Guardar',
  ADD_DIAGNOSIS: 'Añadir diagnostico',
  BUTTON_GO_BACK: 'Volver al detalle',
}

export default localeES
