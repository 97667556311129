import axios from "axios"


export function objectToQuery(params: any) {
  const queryParts = []
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParts.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
    }
  }
  return queryParts.join('&')
}

export function debounce(func:any, delay:any) {
  let timeout:any;
  return function (...args:any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const unPrintCurl = () => {
	axios.interceptors.request.use((_:any) => {
    return _
	})
}

export const printCurl = () => {
	axios.interceptors.request.use((config: any) => {
		let curlCmd = `curl -X ${config.method.toUpperCase()} '${config.url}'`

		if (config.headers) {
			Object.entries(config.headers).forEach(([key, value]) => {
				curlCmd += ` -H '${key}: ${value}'`
			})
		}

		if (config.data instanceof FormData) {
			for (const [key, value] of config.data._parts) {
				if (typeof value === 'string') {
					curlCmd += ` -F "${key}=${value}"`
				} else {
					curlCmd += ` -F "${key}=@${(value as any).uri}"`
				}
			}
		} else if (
			typeof config.data === 'object' ||
			typeof config.data === 'string'
		) {
			curlCmd += ` -d '${JSON.stringify(config.data)}'`
		}

		console.info(curlCmd)
		return config
	})
}