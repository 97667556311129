import IZCard from '../../../components/atomic-components/IZCard'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import IZView from '../../../components/atomic-components/IZView'
import styles from '../styles'
import IZPill from '../../../components/atomic-components/Pill'
import moment from 'moment'
import { EvolutionCase } from '../../../interfaces/Case'
import summarizedDetailI18n from '../i18n/keys'

interface Props {
  case: EvolutionCase
  t: (s: string) => string
}
const DiagnosisDetails = (props: Props, index: number) => {
  const translate = (str: string | string[]) => {
    if (!str) return ''
    const strList = [str].flat()

    return strList.map((str) => props.t(`EVOLUTION_DETAILS_${str?.toLocaleUpperCase?.()}`)).join(', ')
  }
  return (
    <IZCard key={`map-${index}`} style={styles.pillContent}>
      <DescriptionText style={styles.description_date}>
        {moment(props.case.timestamp).format('YYYY-MM-DD HH:mm')}
      </DescriptionText>
      <DescriptionText style={styles.description}>
        {props.t(summarizedDetailI18n.SYSTEM)} {translate(props.case.affected_system)}
      </DescriptionText>
      <DescriptionText style={styles.description}>
        {props.t(summarizedDetailI18n.DIAGNOSTIC)} {props.case.diagnosis}
      </DescriptionText>
      <IZView style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {!!props.case.diagnosis_type && <IZPill style={styles.pill}>{translate(props.case.diagnosis_type)}</IZPill>}
        {!!props.case.evolution.length && <IZPill style={styles.pill}>{translate(props.case.evolution)}</IZPill>}
      </IZView>
    </IZCard>
  )
}

export default DiagnosisDetails
