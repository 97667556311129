import { Platform, ViewStyle } from 'react-native'

const style = {
  buttonContainer: {
    position: Platform.OS !== 'web' ? 'absolute' : 'fixed',
    bottom: 20,
    right: 15,
    borderRadius: 16,
    padding: 4,
    elevation: 6,
  } as ViewStyle,
  button: {
    borderRadius: 16,
    paddingVertical: 12,
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
}

export default style
