import { StyleSheet, ViewStyle } from 'react-native'
import { useContext } from 'react'
import { ModalContext, ModalContextProps } from '../../../context/Modal.context'
import { Modal } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Icon, View } from '../../../libraries/react-native'
const modalStyleDefault = {
  backgroundColor: getThemeColor(ColorKeys.background),
  margin: 10,
  padding: 10,
  borderRadius: 10,
  justifyContent: 'flex-start',
}
export default function ModalWrapperSecondary() {
  const { secondaryModal, hideSecondaryModal }: ModalContextProps = useContext(ModalContext)
  const { onCancel, Component, isVisible, modalContainerStyle, modalContainerProps, modalStyle } = secondaryModal
  const { Component: AuxComponent, ...modalProps } = secondaryModal
  const handleOnCancel = () => {
    onCancel?.()
    hideSecondaryModal()
  }

  const handleOnAccept = (propsResponse?: any) => {
    secondaryModal.onAccept?.(propsResponse)
    hideSecondaryModal()
  }
  return (
    <Modal
      visible={isVisible}
      onDismiss={handleOnCancel}
      contentContainerStyle={{ ...modalStyleDefault, ...modalContainerStyle } as ViewStyle}
      style={modalStyle}
      //@ts-ignore
      modalContainerStyle={modalStyle as ViewStyle}
      {...modalContainerProps}
    >
      <View style={styles.closeContainer}>
        <Icon icon={'close'} onPress={handleOnCancel} />
      </View>
      {!!Component ? <Component {...modalProps} onCancel={handleOnCancel} onAccept={handleOnAccept} /> : <></>}
    </Modal>
  )
}

const styles = { closeContainer: { alignSelf: 'flex-end', marginRight: 20, zIndex: 1 } as ViewStyle }
