//@ts-ignore
import { TextInputProps } from 'react-native-paper'
//@ts-ignore
import IZInput from './components/Input'
import HibridInput from './components/HibridInput'
import { ViewStyle } from 'react-native'

export type IZInputProps = TextInputProps & {
  scheme?: 'light' | 'dark'
  style?: ViewStyle
  description?: string
  loading?: boolean
  name?: string
}
export default IZInput as typeof HibridInput
