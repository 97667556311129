import { TextStyle, ViewStyle } from "react-native";
import { ColorKeys, getThemeColor } from "../../utils/colors";

const styles = {
  image: {
    alignSelf: "center",
    width: "100%",
    height: 50,
    marginBottom: 20,
    resizeMode: "contain",
  } as ViewStyle,
  errorText:{
    marginTop: 5
  },
  screen: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  } as ViewStyle,
  loginContainer: {
    justifyContent: "center",
    width: 350,
    height: 550,
    paddingHorizontal: 30,
    paddingVertical: 60,
    backgroundColor: getThemeColor(ColorKeys.background),
  } as ViewStyle,
  input: {
    width: "100%",
    marginTop: 20,
  } as ViewStyle,
  forgetPasswordContainer: {
    marginTop: 10,
  } as ViewStyle,
  forgetPasswordText: {} as TextStyle,
  button: {
    marginTop: 30,
  } as ViewStyle,
};
export default styles;
