import IZView from '../../atomic-components/IZView'
import styles from './styles'
import { ViewStyle } from 'react-native'
import ViewContainer from './components/ViewContainer'
import { useAdaptativeItemsPerRow } from './hooks/useAdaptativeItemsPerRow'

interface Props {
  children: boolean | JSX.Element | (JSX.Element | boolean)[]
  style?: ViewStyle
  itemSeparation?: number
  itemStyle?: ViewStyle
  itemsPerRow?: number
  minWidthPerItem?: number
}

/**
 * IZAdaptiveContainer is a component that adapts its layout based on the platform (web or mobile).
 *
 * @component
 * @param {Props} props - The props for configuring the IZAdaptiveContainer component.
 * @param {JSX.Element | (JSX.Element | boolean)[]} props.children - The child components to be rendered.
 * @param {ViewStyle} [props.style] - An optional style object to apply custom styles to the container.
 * @param {number} [props.itemSeparation] - An optional separation value between child items. Defaults to 15.
 * @param {ViewStyle} [props.itemStyle] - An optional style object to apply custom styles to individual child items.
 * @returns {JSX.Element} A React element that adapts its layout based on the platform.
 */
const IZAdaptiveContainer = (props: Props) => {
  const itemSeparation = props.itemSeparation ?? 15
  const itemsPerRow = useAdaptativeItemsPerRow({
    //@ts-ignore
    itemsPerRow: props.itemsPerRow ?? props.children.length,
    minWidthPerItem: props.minWidthPerItem!,
  })

  return (
    <IZView style={{ ...styles.container, ...props.style }}>
      {Array.isArray(props.children) &&
        props.children
          .filter((obj) => !!obj)
          .map((child, index, arr) => (
            <ViewContainer
              key={`IZAdaptiveContainer-${index}`}
              itemStyle={props.itemStyle!}
              itemsPerRow={itemsPerRow}
              arr={arr}
              index={index}
              itemSeparation={itemSeparation}
            >
              {child}
            </ViewContainer>
          ))}
      {!Array.isArray(props.children) && (
        <ViewContainer
          itemStyle={props.itemStyle!}
          itemsPerRow={itemsPerRow}
          itemSeparation={itemSeparation}
        >
          {props.children}
        </ViewContainer>
      )}
    </IZView>
  )
}

export default IZAdaptiveContainer
