import TextStyled from "..";
import { FONT_FAMILY } from "../../../../constants/fontFamily"
//@ts-ignore
import { TextProps, ViewStyle } from "react-native"

const Title = (props:any) => {
  return (
    <TextStyled {...props} bold fontFamily= {FONT_FAMILY.SECUNDARY} style={{fontSize: 24,...props.style as ViewStyle}}/>
  );
};

export default Title;

