import WebFont from 'webfontloader'

export const loadAsync = async (loadConfig: any) => {
  return new Promise(async (resolve) => {
    await Promise.all(
      Object.keys(loadConfig).map(async (key) => {
        return new Promise((resolve) => {
          const resolveFunction = (typefunctions:string) => {
            resolve(true)
          }
          WebFont.load({
            custom: {
              families: [key],
              urls: [`../../assets/fonts/${key}.css`],
            },
            active: () => resolveFunction("active"),
            inactive: () => resolveFunction("inactive"),
            fontactive: () => resolveFunction("fontactive"),
            fontinactive: () => resolveFunction("fontinactive"),
          })
        })
      })
    )
    resolve(true)
  })
}
const Font = {
  loadAsync
}
export default Font
