import React from 'react'
import { View } from '../../../libraries/react-native'
import { DescriptionText } from '../Text/variants'
import style from './styles'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { ViewStyle, Text } from 'react-native'

interface Props {
  children: string | JSX.Element
  style?: ViewStyle
  remove?: boolean
}
const IZPill = (props: Props) => {
  return (
    <View style={{ ...style.pillContainer, ...props.style, flexDirection: 'row' }}>
      <DescriptionText color={getThemeColor(ColorKeys.pillText)} bold>
        {props.children}
      </DescriptionText>
      {props.remove && <Text style={{ ...style.removeIcon }}>X</Text>}
    </View>
  )
}

export default IZPill
