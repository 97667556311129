import React, { useContext } from 'react'
import { FormatContext } from '../context/format.context'
import IZDatePicker, {
  IZDatePickerProps,
} from '../../../atomic-components/IZDatePicker'
import { useAutoFill } from '../hooks/useAutoFill'
import moment from 'moment'
import { getPrioritaryItem } from '../../../../utils/objects'

const FormatDatePicker = (
  props: IZDatePickerProps & { name: string; initialValue?: string }
) => {
  const { format: form, setFormat } = useContext(FormatContext);
  const format = props.format ?? "DD-MM-YYYY";
  const priorizedValue = getPrioritaryItem({
    stateValue: form[props.name],
    initialValue: props.initialValue,
    defaultValue: moment().format(),
  })

  useAutoFill(props.name!, priorizedValue)

  const onHandleCheckboxPress = (text: string) => {
    props.name &&
      setFormat((format: any) => ({ ...format, [props.name!]: text }))
    props.onChangeDate?.(text)
  }
  const value = moment(priorizedValue).format(format)

  return (
    <IZDatePicker
      {...props}
      value={value}
      format={format}
      onChangeDate={onHandleCheckboxPress}
    />
  )
}

export default FormatDatePicker
