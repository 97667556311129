import { Appearance, ColorSchemeName } from 'react-native'
import Color from '../constants/color'

/**
 * Record containing keys corresponding to color names from the Color.light object.
 * Each key maps to itself.
 */
export const ColorKeys: Record<
  keyof typeof Color.light,
  keyof typeof Color.light
> = Object.keys(Color.light).reduce(
  (accum: any, key) => ({ ...accum, [key]: key }),
  {}
)

/**
 * Get the theme color based on the provided name and specific theme.
 *
 * @param {keyof typeof Color.light} name - The name of the color.
 * @param {'light' | 'dark'} [specificTheme] - Specific theme (light/dark).
 * @returns {string} The theme color value.
 */
export const getThemeColor = (
  name: keyof typeof Color.light,
  specificTheme?: 'light' | 'dark'
): string => {
  const theme: ColorSchemeName =
    specificTheme === 'dark' || specificTheme === 'light'
      ? specificTheme
      : Appearance.getColorScheme()
  const themeColors = Color[theme || 'light'] as typeof Color.light
  return themeColors[name]
}

export const inverseTheme = () => {
  return Appearance.getColorScheme() === 'dark' ? 'light' : 'dark'
}

export const getTextColorByBackground = (
  background: string | keyof typeof Color.light
): string => {
  let theme: 'light' | 'dark'
  if (background in Color.light) {
    theme = getBackgroundTheme(
      ColorKeys[background as keyof typeof Color.light]
    )
  } else {
    theme = getBackgroundTheme(background)
  }
  const themeColors = Color[theme || 'light'] as typeof Color.light
  return themeColors[ColorKeys.text]
}

/**
 * Get the color based on the provided name and background color.
 *
 * @param {keyof typeof Color.light} name - The name of the color.
 * @param {string} background - The background color.
 * @returns {string} The theme color value based on background.
 */
export const getColorByBackground = (
  name: keyof typeof Color.light,
  background: string
): string => {
  const theme: 'light' | 'dark' = getBackgroundTheme(background)
  const themeColors = Color[theme || 'light'] as typeof Color.light
  return themeColors[name]
}

/**
 * Check if a color corresponds to a dark theme.
 *
 * @param {string} color - The color value.
 * @returns {boolean} True if the color is associated with a dark theme, false otherwise.
 */
export function isDark(color: string | keyof typeof Color.light): boolean {
  return getBackgroundTheme(color) === 'dark'
}

/**
 * Determine the background theme (light/dark) based on the provided background color.
 *
 * @param {string | undefined | keyof typeof Color.light } bgColor - The background color.
 * @returns {'light' | 'dark'} The determined background theme.
 */
export function getBackgroundTheme(
  bgColor: string | keyof typeof Color.light | undefined
): 'light' | 'dark' {
  if (bgColor == undefined) return 'light'
  if (Object.keys(Color.light).includes(bgColor)) {
    bgColor = getThemeColor(bgColor as keyof typeof Color.light)
  }
  try {
    const isSimpleBackground = bgColor.length === 4
    const numberLettersPerColor = isSimpleBackground ? 1 : 2
    const rStart = 1
    const gStart = rStart + numberLettersPerColor
    const bStart = gStart + numberLettersPerColor

    const r = parseInt(
      bgColor.substring(rStart, rStart + numberLettersPerColor),
      16
    )
    const g = parseInt(
      bgColor.substring(gStart, gStart + numberLettersPerColor),
      16
    )
    const b = parseInt(
      bgColor.substring(bStart, bStart + numberLettersPerColor),
      16
    )

    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 128 ? 'light' : 'dark'
  } catch (error) {
    return 'light'
  }
}
