type WrapperType = (Component: ComponentType) => (props: any) => JSX.Element

type ComponentType = (props: any) => JSX.Element

export const concatWrapper = (
  wrappers: WrapperType[],
  Component: ComponentType
): ComponentType => {
  const wrappedComponent = wrappers.reduce((component, wrapper) => {
    return wrapper(component)
  }, Component)
  return wrappedComponent
}

