import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Case, EvolutionCase } from '../interfaces/Case'
import { CallBackParams } from '../utils/service'
import ApiClient from './ApiClient'
import AuthService from './authService'

export type CreateNewEvolutionCaseParams = Omit<EvolutionCase, 'case' | 'created_by'> & {
  animal_id: number
}

type updateCaseParams = {
  animal_id?: number[]
  case_id: number
  timestamp?: string
}
class CaseService {
  public static getCaseList = async (search?: string, callback?: Function): Promise<Case[]> => {
    return await ApiClient.get(
      BACKEND_BASE_URL_V1,
      'cases',
      search
        ? {
            search: `${search}`,
          }
        : undefined,
      callback
    )
  }
  public static getEvolution = async (callback?: Function): Promise<Case[]> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, 'cases/evolution', undefined, callback)
  }
  public static getAffectedSystem = async (callback?: Function): Promise<Case[]> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, 'cases/affected_system', undefined, callback)
  }
  public static getTypeDiagnosis = async (callback?: Function): Promise<Case[]> => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, 'cases/diagnosis_types', undefined, callback)
  }

  public static getCase = async (case_id: number, callback?: Function) => {
    return await ApiClient.get(BACKEND_BASE_URL_V1, `cases/${case_id}`, undefined, callback)
  }

  public static createCase = async (caseObject: Case, callback?: Function) => {
    return await ApiClient.post(BACKEND_BASE_URL_V1, 'cases/', caseObject, callback)
  }
  public static createCaseDetails = async (
    { case_id, timestamp, diagnosis, affected_system, diagnosis_type, evolution }: CreateNewEvolutionCaseParams,
    callback?: Function
  ): Promise<EvolutionCase> => {
    const { user_id: created_by_id } = await AuthService.getAuthInfo()
    const evolutionCase: Partial<EvolutionCase> = {
      case_id,
      created_by_id,
      timestamp,
      diagnosis,
      affected_system,
      diagnosis_type,
      evolution,
    }
    const evolutionCaseResponse: EvolutionCase = await ApiClient.post(
      BACKEND_BASE_URL_V1,
      'case-evolutions/',
      evolutionCase,
      callback
    )

    return evolutionCaseResponse
  }

  public static updateCase = async (case_id: number, caseParams: Case, callback?: CallBackParams) => {
    try {
      const caseDetailsList = await ApiClient.put(BACKEND_BASE_URL_V1, `cases/${case_id}/`, caseParams, callback)
      return caseDetailsList
    } catch (error) {
      throw error
    }
  }

  public static getCaseDetail = async (case_id: number, callback?: Function): Promise<EvolutionCase[]> => {
    try {
      const { user_id } = await AuthService.getAuthInfo()
      const caseDetailsList = await ApiClient.get(
        BACKEND_BASE_URL_V1,
        `case-evolutions`,
        {
          case_id,
          created_by: user_id,
        },
        callback
      )
      return caseDetailsList
    } catch (error) {
      throw error
    }
  }
}

export default CaseService
