import { useRef, useState } from 'react'
import {  View } from '../../libraries/react-native'
import styles from './styles'
import Popup from 'reactjs-popup'
import { DescriptionText } from '../../components/atomic-components/Text/variants'
import SignaturePad from 'react-signature-canvas'
import IZButton from '../../components/atomic-components/Button'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import { useTranslation } from 'react-i18next'
import localeES from './i18n/es'
import signatureI18nKeys from './i18n/keys'
import i18next from 'i18next'
import { IconButton } from 'react-native-paper'

const SignatureCanvas = (props: any) => {
  const [acceptLoading, setAcceptLoading] = useState(false)
  const sigCanvas = useRef<SignaturePad>(null)
  //@ts-ignore
  const clear = () => sigCanvas.current.clear()
  //@ts-ignore

  const save = async(closeModal: (x?: any) => void) => {
    //@ts-ignore
    setAcceptLoading(true)
    const imageURL:any = sigCanvas?.current?.getTrimmedCanvas().toDataURL('image/png') 

    await  closeModal(imageURL)
    props.setImageURL?.(imageURL)
    setAcceptLoading(false)
  }

  const bundleName = 'Signature'
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  return (
    <View>
      <Popup modal open={props.isVisible} closeOnDocumentClick={false}>
        {
          //@ts-ignore
          (close) => (
            <>
              <View style={styles.overlayPad}>
                <View style={styles.signatureCanvas}>
                  <View style={styles.signHeader}>
                    <DescriptionText>{t(signatureI18nKeys.SIGN)}</DescriptionText>
                    <IconButton style={{ marginRight: -8 }} icon='close' size={20} onPress={props.onCancel} />
                  </View>
                  <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{
                      style: { border: '1px solid lightgray', backgroundColor: 'white' },
                      className: 'signatureCanvas'
                    }}
                  />
                  <View style={styles.signatureButtonOptionsContainer}>
                    <IZButton
                      buttonColor={getThemeColor(ColorKeys.background)}
                      style={styles.signatureButtonOption}
                      onPress={clear}
                    >
                      {t(signatureI18nKeys.CLEAN)}
                    </IZButton>
                    <IZButton style={styles.signatureButtonOption} loading={acceptLoading} onPress={() => save(props.onAccept)}>
                      {t(signatureI18nKeys.SEND_SIGN)}
                    </IZButton>
                  </View>
                </View>
              </View>
            </>
          )
        }
      </Popup>
    </View>
  ) as any
}
export default SignatureCanvas
