import { ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Dimensions } from 'react-native'
const authContainerWidth = Dimensions.get('window').width * 0.85
const styles = {
  screen: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  loginContainer: {
    justifyContent: 'center',
    width: Math.min(authContainerWidth, 350),
    height: 470,
    paddingHorizontal: 30,
    paddingVertical: 30,
    backgroundColor: getThemeColor(ColorKeys.background),
  } as ViewStyle,
}
export default styles
