import React from 'react'
import { View } from '../../../libraries/react-native'
import style from './styles'
import { ViewStyle } from 'react-native'

interface Props {
  children: JSX.Element | (JSX.Element | false)[]
  style?: ViewStyle
  borderColor?: string
}
const IZCard = (props: Props) => {
  return (
    <View style={{ position: 'relative', overflow: 'visible' }}>
      <View style={{ ...style.container, ...props.style }}>
        {[props.children].flat().map((child,index) => (
          <View key={`IZCard-${index}`}>{child}</View>
        ))}
      </View>
      <View
        style={{
          ...style.borderLine,
          ...(props.borderColor ? { borderColor: props.borderColor } : {}),
        }}
      />
    </View>
  )
}

export default IZCard
