import { STACKS } from '../../constants/screens'
import PasswordResetService from '../../services/PasswordResetService'
import AuthService from '../../services/authService'

export const getInitialNavigationRoute = async () => {
  try {
    const authInfo = await AuthService.getAuthInfo()
    if (authInfo.access) {
      return STACKS.Main
    } else {
      return STACKS.Auth
    }
  } catch (error) {
    return STACKS.Auth
  }
}
