import { Dimensions, TextStyle, ViewStyle } from 'react-native'

const styles = {
  container: {
    padding: 20,
    paddingBottom: 40,
    height: '100%',
  } as ViewStyle,
  title: {
    fontSize: 16,
    marginBottom: 10,
  } as TextStyle,
  cardDateText: {
    fontSize: 16,
    letterSpacing: 1,
  } as TextStyle,
  cardContainer: { marginTop: 30, height: Dimensions.get('window').height - 170 } as ViewStyle,
}
export default styles
