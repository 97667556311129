import React from 'react'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import { Icon, View } from '../../../libraries/react-native'
import prescriptionI18nKeys from '../i18n/keys'
import IZButton from '../../../components/atomic-components/Button'

const DeletePrescriptionModal = (props: any) => {
  const { t } = props
  return (
    <>
      <DescriptionText>
        {t(prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_DESCRIPTION)}
      </DescriptionText>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <IZButton mode="outlined" onPress={props.onCancel}>
          {t(prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_BUTTON_CANCEL)}
        </IZButton>
        <IZButton onPress={props.onAccept}>
          {t(prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE)}
        </IZButton>
      </View>
    </>
  )
}

export default DeletePrescriptionModal
