import React from 'react'
import { View } from '../../../libraries/react-native'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import prescriptionI18nKeys from '../i18n/keys'
import MedicationData from './MedicationData'
import IZPill from '../../../components/atomic-components/Pill'
import styles from '../styles'

interface Props {
  t: (s: string) => string
  animalIdentifierList: string[]
  specie?: string
}
const AnimalInfo = (props: Props) => {
  return (
    <View>
      <DescriptionText>{props.t(prescriptionI18nKeys.ANIMAL_INFO_DESCRIPTION)}</DescriptionText>
      <View style={styles.pillContainer}>
        {props.animalIdentifierList.map((pill) => (
          <IZPill key={pill} style={styles.pill}>
            {pill}
          </IZPill>
        ))}
      </View>
      {props.specie && <MedicationData label={props.t(prescriptionI18nKeys.ANIMAL_INFO_SPECIE)} value={props.specie} />}
    </View>
  )
}

export default AnimalInfo
