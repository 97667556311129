import { createNativeStackNavigator } from '@react-navigation/native-stack' // Importa tus componentes y configuraciones de navegación aquí
import { SCREEN } from '../../../constants/screens'
import {
  AuthParamList,
  navigationScreensConfigType,
} from '../../types'
import NotFoundConfig from '../../../screens/NotFound/config'
import LoginConfig from '../../../screens/Login/config'
import RecoverAccessConfig from '../../../screens/RecoverAccess/config'
import UpdatePasswordConfig from '../../../screens/UpdatePassword/config'
import { DeepLinkingWrapper } from '../../../components/wrappers/DeepLinkingWrapper'
import PrivacyPolicyConfig from '../../../screens/PrivacyPolicy/config'
import TermsAndConditionsConfig from '../../../screens/TermsAndConditions/config'

const Stack = createNativeStackNavigator<AuthParamList>()
const AuthNavigation = () => {
  const authenticationScreensConfig: navigationScreensConfigType[] = [
    NotFoundConfig,
    LoginConfig,
    RecoverAccessConfig,
    UpdatePasswordConfig,
    PrivacyPolicyConfig,
    TermsAndConditionsConfig
  ]

  return (
    <Stack.Navigator initialRouteName={SCREEN.Login}>
      {authenticationScreensConfig.map((props: any, index) => (
        <Stack.Screen
          {...props}
          component={DeepLinkingWrapper(props.component)}
          key={props.name ?? `authenticationScreensConfig-${index}`}
        />
      ))}
    </Stack.Navigator>
  )
}

export default AuthNavigation
