import { Image, View } from '../../../libraries/react-native'
import IZAdaptiveContainer from '../../../components/containers/IZAdaptiveContainer'
import { DescriptionText } from '../../../components/atomic-components/Text/variants'
//@ts-ignore
import moment from 'moment'
import styles from '../styles'
import prescriptionI18nKeys from '../i18n/keys'
import Link from '../../../libraries/react-native/Link'
import { ViewStyle } from 'react-native'

interface Props {
  t: (x: string) => string
  signature: any
}

const VeterianarianData = ({
  value,
  label,
  style
}: {
  value: string
  label: string
  style?:ViewStyle
}) => {
  return (
    <View style={style}>
      <DescriptionText>{label}</DescriptionText>
      <DescriptionText>{value}</DescriptionText>
    </View>
  )
}

const VeterianarianDataURL = ({
  value,
  label,
}: {
  value: string
  label: string
}) => {
  return (
    <View>
      <DescriptionText>{label}</DescriptionText>
      <Link href={value}>{value}</Link>
    </View>
  )
}

const SignatureInfo = (props: Props) => {
  const { signature } = props

  return (
    <>
      <IZAdaptiveContainer itemsPerRow={5} minWidthPerItem={200}>
        {!!signature.name && (
          <VeterianarianData
            value={signature.name}
            label={props.t(
              prescriptionI18nKeys.SIGNATURE_DATA__NAME_AND_LASTNAME
            )}
          />
        )}
        {!!signature?.veterinarian_identification && (
          <VeterianarianData
            value={signature?.veterinarian_identification}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__DNI)}
          />
        )}
        {!!signature?.veterinarian_registration_number && (
          <VeterianarianData
            value={signature?.veterinarian_registration_number}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__LICENSE_NUMBER)}
          />
        )}
        {!!signature?.cif && (
          <VeterianarianData
            value={signature?.cif}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__CIF)}
          />
        )}

        {!!signature?.breeder_rega && (
          <VeterianarianData
            value={signature?.breeder_rega}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__REGA)}
          />
        )}
        {!!signature?.signature_date && (
          <View style={styles.veterinarianSignatureContainer}>
            <VeterianarianData
              value={moment(signature?.signature_date).format('DD/MM/YYYY')}
              label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__SIGNATURE)}
            />
            {!!signature?.signature && (
              <View style={styles.veterinarianSignatureImageContainer}>
                <Image
                  source={signature?.signature!}
                  alt="signature"
                  style={styles.veterinarianSignatureImage}
                />
              </View>
            )}
            
          </View>
        )}
      </IZAdaptiveContainer>
      <IZAdaptiveContainer itemsPerRow={2} minWidthPerItem={400}>
        {!!signature?.address && (
          <VeterianarianData
            value={signature?.address}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__ADDRESS)}
          />
        )}
        {!!signature?.name && (
          <VeterianarianData
            value={'Alastria'}
            label={props.t(
              prescriptionI18nKeys.SIGNATURE_DATA__BLOCKCHAIN_NETWORK
            )}
          />
        )}
      </IZAdaptiveContainer>
      <IZAdaptiveContainer itemsPerRow={2} minWidthPerItem={400}>
        {!!signature?.url_blockchain && (
          <VeterianarianDataURL
            value={signature?.url_blockchain}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__BLOCKCHAIN_URL)}
          />
        )}
        {!!signature?.ipfs_hash && (
          <VeterianarianDataURL
            value={signature?.ipfs_hash}
            label={props.t(prescriptionI18nKeys.SIGNATURE_DATA__IPFS)}
          />
        )}
      </IZAdaptiveContainer>
    </>
  )
}

export default SignatureInfo
