import i18next from 'i18next'
import React, {
  createContext,
  useState,
  ReactNode,
} from 'react'
import { bundleNameIZFormat } from '../constants'
import localeES from '../i18n/es'

const FormatContext = createContext<{
  format: any
  setFormat: (data: any) => void
  error: any
  setError: any
}>({} as any)

function FormatProvider<T>(props: {
  children: ReactNode
  onFormatChange?: (format: T) => void
}) {
  i18next.addResourceBundle('es', bundleNameIZFormat, localeES)
  const [format, setFormat] = useState<T>({} as any)
  const [error, setError] = useState<T>({} as any)

  return (
    <FormatContext.Provider value={{ format, setFormat, error, setError }}>
      {props.children}
    </FormatContext.Provider>
  )
}

export { FormatContext, FormatProvider }
