import { DescriptionText } from '../../components/atomic-components/Text/variants'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import summarizedDetail from './i18n/keys'
import IZButton from '../../components/atomic-components/Button'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import DiagnosisDetailsList from './components/DiagnosisDetailsList'
import { NavigationInjectedProps } from 'react-navigation'
import CaseDetails from './components/CaseDetails'

interface Props extends NavigationInjectedProps {
  case_id: number
  route: any
}
export default function SummarizedDetail(props: Props) {
  const case_id = props.case_id
  const { t } = useTranslation(SCREEN.SummarizedDetail)

  const onHandleButtonAddDiagnosis = () => {
    props.navigation.navigate(STACKS.Main, {
      screen: SCREEN.AddDiagnosis,
      params: { case_id },
    })
  }

  return (
    <IZScreenContainer withScroll withFormat headerHeight={150}>
      <CaseDetails navigation={props.navigation} route={props.route} case_id={props.case_id} t={t} />

      <DescriptionText style={styles.diagnosis}>{t(summarizedDetail.TEXT_EVOLUTION_DIAGNOSIS)}</DescriptionText>
      <DiagnosisDetailsList case_id={case_id} t={t} />

      <IZButton
        style={styles.button_add_diagnosis}
        buttonColor={getThemeColor(ColorKeys.background)}
        onPress={onHandleButtonAddDiagnosis}
      >
        {t(summarizedDetail.BUTTON_DIAGNOSIS)}
      </IZButton>
    </IZScreenContainer>
  )
}
