import { Image, Platform, TextStyle, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import { FONT_FAMILY } from '../../constants/fontFamily'

const styles = {
  signHeader: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } as ViewStyle,
  canvasButtons: { borderRadius: 25, marginRight: 10 } as ViewStyle,
  canvasButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    height: 100,
    backgroundColor: 'white'
  } as ViewStyle,
  signTitle: {
    backgroundColor: '#ffffff'
  } as ViewStyle,
  closeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 16
  } as ViewStyle,
  overlayPad: { width: '100%', backgroundColor: 'black', padding: 1 } as ViewStyle,
  signatureCanvas: { backgroundColor: 'white', padding: 20 } as ViewStyle,
  signatureButton: {
    backgroundColor: getThemeColor(ColorKeys.primary),
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? 335 : 1000
  } as ViewStyle,
  signatureButtonText: {
    letterSpacing: 0.57,
    fontFamily: FONT_FAMILY.PRINCIPAL,
    fontWeight: 'bold',
    color: 'white'
  } as TextStyle,
  signatureButtonOptionsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 40,
    width: '100%',
    marginTop: 10
  } as ViewStyle,
  signatureButtonOption: { borderRadius: 25, marginRight: 10 } as ViewStyle,
  cardDateText: {
    fontSize: 16,
    letterSpacing: 1
  } as TextStyle,
  itemContainer: {
    marginTop: 20
  } as ViewStyle,
  treatmentMedicationLabel: {
    marginTop: 20,
    color: getThemeColor(ColorKeys.placeholder)
  } as ViewStyle,
  treatmentMedicationContent: {
    marginTop: 5
  } as ViewStyle,
  closeButton: {
    alignSelf: 'flex-end'
  } as ViewStyle,
  modalContainer: {
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  } as ViewStyle,
  modalContent: {
    borderRadius: 10,
    padding: 20
  } as ViewStyle,
  headerModalContent: {
    backgroundColor: getThemeColor(ColorKeys.primary),
    padding: 15,
    paddingVertical: 25
  } as ViewStyle,
  pillContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  } as ViewStyle,
  pill: {
    margin: 10
  } as ViewStyle,
  card: {
    paddingBottom: 50,
    flexDirection: 'row',
    alignItems: 'center'
  } as ViewStyle,
  iconButton: { marginLeft: -20 },
  optionContainer: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as ViewStyle,
  optionHeaderLeftContainer: { flexDirection: 'row', alignItems: 'center' } as ViewStyle
}
export default styles
