const localeES = {
  USER: 'Usuario',
  PASSWORD: 'Contraseña',
  ERROR: 'El usuario o la contraseña no coinciden',
  TEXT_FORGET_PASSWORD: '¿Olvidaste tu contraseña?',
  BUTTON_LOGIN: 'Iniciar sesión',
  LINK_TERMS_AND_CONDITIONS: 'Términos y condiciones',
  LINK_PRIVACY_POLICY: 'Política de privacidad',
}

export default localeES
