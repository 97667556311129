import { parseCookies, setCookie, destroyCookie } from 'nookies'
import { NextPageContext } from 'next'
type valueLabels =
  (typeof CookieService.LABELS)[keyof typeof CookieService.LABELS]
class CookieService {
  public static LABELS = {
    TOKEN: 'token',
    USERNAME: 'username',
    CREDENTIAL: 'credential',
    USER_ID: 'user_id',
    REFRESH_TOKEN: 'refresh',
    EMAIL: 'email',
  } as const
  private static MAX_TIME = 60 * 60 * 24 * 30 * 12

  public static async storeData(
    key: valueLabels,
    value: string,
    remember?: boolean
  ) {
    setCookie(null, key, value, {
      path: '/',
      maxAge: remember ? this.MAX_TIME : null,
    })
  }
  public static async getData(key: valueLabels, ctx = undefined) {
    try {
      return parseCookies(ctx)[key]
    } catch (error) {
      return undefined
    }
  }
  public static async storeNumericData(
    key: valueLabels,
    value: number,
    remember?: boolean
  ) {
    setCookie(null, key, value.toString(), {
      path: '/',
      maxAge: remember ? this.MAX_TIME : null,
    })
  }
  public static async getNumericData(key: valueLabels, ctx = undefined) {
    try {
      const data = parseCookies(ctx)[key]
      return parseInt(data)
    } catch (error) {
      return undefined
    }
  }

  public static removeAllCookies(ctx?: NextPageContext) {
    Object.values(this.LABELS).forEach((key: string) => {
      destroyCookie(ctx, key)
    })
  }
}

export default CookieService
