//@ts-ignore
import { Dimensions, ViewStyle } from 'react-native'
import { ScrollView } from 'react-native-virtualized-view'
import IZView from '../../atomic-components/IZView'
import styles from './styles'
import IZFormat from '../../templates/IZFormat'

interface Props {
  children: JSX.Element | (JSX.Element | boolean)[]
  withScroll?: boolean
  withFormat?: boolean
  footer?: JSX.Element
  contentContainerStyle?: ViewStyle
  headerHeight?: number
}

/**
 * IZScreenContainer is a versatile React component for creating screen containers with optional features such as scrolling and formatting.
 *
 * @param {Object} props - The component's properties.
 * @param {JSX.Element | (JSX.Element | boolean)[] | ((f: any) => JSX.Element)} props.children - The content to be rendered within the container. This can be a JSX element, an array of elements, or a function that accepts a format object and returns JSX.
 * @param {boolean} [props.withScroll] - Determines whether the content should be scrollable. Defaults to false.
 * @param {boolean} [props.withFormat] - Determines whether the content should be wrapped in a formatting context. Defaults to false.
 * @param {JSX.Element} [props.footer] - An optional footer element to be displayed at the bottom of the container.
 * @param {ViewStyle} [props.contentContainerStyle] - Additional styles for the content container.
 * @param {number} [props.headerHeight] - The height of the header. Defaults to 100.
 */
const IZScreenContainer = (props: Props) => {
  // Calculate the height of the header
  const headerHeight = props.headerHeight ?? 100

  // Calculate the height of the content screen
  const contentScreenHeight = Math.round(
    Dimensions.get('window').height -
    headerHeight -
    (props.footer ? 120 : 90)
  )

  // Define the style for the screen container
  const screenStyle = {
    height: contentScreenHeight,
    ...props.contentContainerStyle,
  } as ViewStyle

  // ScrollViewComponent renders content with or without scrolling
  const ScrollViewComponent = ({ children }: any) =>
    props.withScroll ? (
      <IZView style={screenStyle}>
        <ScrollView showsVerticalScrollIndicator={false}>
          {children}
        </ScrollView>
      </IZView>
    ) : (
      <IZView style={screenStyle}>{children}</IZView>
    )

  // FormatComponent wraps content in a formatting context
  const FormatComponent = ({ children }: any) =>
    props.withFormat ? (
      <IZFormat.Provider>{children}</IZFormat.Provider>
    ) : (
      <IZView>{children}</IZView>
    )

  // Children function retrieves the format from the context and renders the children
  const Children = () => {
    return props.children
  }

  return (
    <IZView style={styles.container}>
      <FormatComponent>
        <ScrollViewComponent>
          <IZView style={styles.contentContainer}>
            <Children />
          </IZView>
        </ScrollViewComponent>
        <IZView style={styles.buttonContainer}>{props.footer}</IZView>
      </FormatComponent>
    </IZView>
  )
}

export default IZScreenContainer
