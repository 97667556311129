import React from 'react'
import { Platform, ViewStyle } from 'react-native'
import IZView from '../../../atomic-components/IZView'
import { calculateWidth } from '../utils/calculateWidth'
import { getMisMatchedItems } from '../utils/misMatchedItems'

const ViewContainer = (props: {
  itemStyle: ViewStyle
  itemsPerRow: number
  arr?: any[]
  index?: number
  itemSeparation?: number
  children?: any
}) => {
  const { numMismatchedItems, indexMismatchedItems } = getMisMatchedItems(
    props.arr!,
    props.itemsPerRow
  )
  const itemsPerRow =
    (props.index ?? 0) < indexMismatchedItems
      ? props.itemsPerRow
      : numMismatchedItems
  const width = calculateWidth({
    itemsPerRow: itemsPerRow,
    arrLength: props.arr?.length!,
  })
  return (
    <IZView
      style={{
        ...props.itemStyle,
        width: `${width}%` as any,
      }}
    >
      <IZView
        key={`IZAdaptiveContainer-${props.index}`}
        style={{
          marginLeft:
            props.arr && props.index !== 0 && props.index! % itemsPerRow !== 0
              ? props.itemSeparation
              : 0,
          marginTop: props.index! / itemsPerRow >= 1 ? props.itemSeparation : 0,
        }}
      >
        {props.children}
      </IZView>
    </IZView>
  )
}

export default ViewContainer
