import IZFormat from '../IZFormat'
import { bundleName } from './constants'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import localeES from './i18n/es'
import animalInputRegaI18nKeys from './i18n/keys'
import RegaOption from './components/RegaOption'
import { ViewStyle } from 'react-native'
import { ContextReRendsAvoiderWrapper } from '../../../utils/context'
import { FormatContext } from '../IZFormat/context/format.context'
import { useGetOptions } from './hooks/useOptions'
import { useUpdateRega } from './hooks/useUpdateRega'
import { useUpdateAnimals } from './hooks/useUpdateAnimals'
import { Breeder } from '../../../interfaces/Animal'

interface Props {
  style?: ViewStyle
  initialValue?: any
  label?: string
  options: any[]
  description?: string
  onChangeText?: (s: any) => void
}
/**
 * AnimalInputRega is a React component that provides an input field for selecting Rega identifiers.
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.label] - The label for the input field.
 * @param {string} [props.description] - The description for the input field.
 */
const AnimalInputRega = (props: Props & { contextData?: any }) => {
  const valueExtraction = (breeder: Breeder) => ({
    ...breeder,
    value: breeder?.id!,
    label: breeder?.rega_id,
  })
  const rega = useUpdateRega({ ...props, valueExtraction })
  const formatedRega: any = valueExtraction(rega)
  useUpdateAnimals(props, rega)
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  return (
    <IZFormat.InputSearch
      {...props}
      value={formatedRega}
      name='rega_id'
      description={props.description ?? t(animalInputRegaI18nKeys.INPUT_DESCRIPTION_REGA)}
      renderOptions={RegaOption}
      label={props.label ?? t(animalInputRegaI18nKeys.INPUT_LABEL_REGA)}
    />
  )
}
const rerenderParamsListExtractor = (contextData: any) => {
  return [contextData.format.animal_id, contextData.format.rega_id]
}

export default ContextReRendsAvoiderWrapper(AnimalInputRega, {
  Context: FormatContext,
  rerenderParamsListExtractor,
})
