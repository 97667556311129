import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Prescription } from '../interfaces/Prescription'
import { Veterinarian } from '../interfaces/Veterinarian'
import ApiClient from './ApiClient'

class VeterinarianService {
  public static getVeterinarianByUserId = async (id: number, callback?: Function): Promise<Veterinarian> => {
    const data = await ApiClient.get(BACKEND_BASE_URL_V1, `veterinarian`, { user_id: id }, callback)
    return data?.[0]
  }
  public static getVeterinarian = async (id: number, callback?: Function): Promise<Veterinarian> => {
    const data = await ApiClient.get(BACKEND_BASE_URL_V1, `veterinarian/${id}`, undefined, callback)
    return data
  }
}

export default VeterinarianService
