import React, { FC } from 'react'
import { ActivityIndicator } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { StyleProp } from 'react-native'
import { ViewStyle } from 'react-native'

interface IZLoadingProps {
    style?: StyleProp<ViewStyle>
}

const IZLoading: FC<IZLoadingProps> = ({ style }) => {
    return (
        <ActivityIndicator
            style={style}
            color={getThemeColor(ColorKeys.primary)}
        />
    )
}

export default IZLoading