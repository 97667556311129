import {
  FaSearch,
  FaQuestion,
  FaAlignJustify,
  FaPlus,
  FaEye,
  FaEyeSlash,
  FaArrowLeft,
  FaPencilAlt,
  FaChevronLeft,
  FaChevronRight,
  FaChevronDown,
  FaTimes,
  FaChevronUp,
  FaCalendarDay,
  FaInfoCircle,
  FaBars,
  FaCheck,
  FaFileDownload,
  FaDownload,
} from 'react-icons/fa'

import { LuDownload } from 'react-icons/lu'

import { RxReload } from 'react-icons/rx'

import { IconButton, TextInputIconProps } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import TouchableOpacity from '../TouchableOpacity'

const IconWeb = (props: TextInputIconProps & { onPress: () => void }) => {
  let IconWrapper = FaQuestion
  if (props.icon === 'magnify') {
    IconWrapper = FaSearch
  } else if (props.icon === 'view-headline') {
    IconWrapper = FaAlignJustify
  } else if (props.icon === 'view-headline') {
    IconWrapper = FaAlignJustify
  } else if (props.icon === 'plus') {
    IconWrapper = FaPlus
  } else if (props.icon === 'eye-off') {
    IconWrapper = FaEyeSlash
  } else if (props.icon === 'eye') {
    IconWrapper = FaEye
  } else if (props.icon === 'arrow-left') {
    IconWrapper = FaArrowLeft
  } else if (props.icon === 'pencil-outline') {
    IconWrapper = FaPencilAlt
  } else if (props.icon === 'chevron-left') {
    IconWrapper = FaChevronLeft
  } else if (props.icon === 'chevron-right') {
    IconWrapper = FaChevronRight
  } else if (props.icon === 'menu-down') {
    IconWrapper = FaChevronDown
  } else if (props.icon === 'menu-up') {
    IconWrapper = FaChevronUp
  } else if (props.icon === 'close') {
    IconWrapper = FaTimes
  } else if (props.icon === 'calendar-blank') {
    IconWrapper = FaCalendarDay
  } else if (props.icon === 'information-outline') {
    IconWrapper = FaInfoCircle
  } else if (props.icon === 'menu') {
    IconWrapper = FaBars
  } else if (props.icon === 'checkbox-marked') {
    IconWrapper = FaCheck
  } else if (props.icon === 'download') {
    IconWrapper = LuDownload
  } else if (props.icon === 'reload') {
    IconWrapper = RxReload
  }

  const IconContainer = ({ children }: any) =>
    props.onPress ? (
      <TouchableOpacity onPress={props.onPress}>{children}</TouchableOpacity>
    ) : (
      <>{children}</>
    )

  return (
    <IconContainer>
      <IconWrapper
        {...props}
        //@ts-ignore
        color={props.color ?? getThemeColor(ColorKeys.text)}
      />
    </IconContainer>
  )
}

export default IconWeb
