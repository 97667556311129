import { ButtonProps } from 'react-native-paper'
import HibridStickyButton from './components/HibridStickyButton'

import React from 'react'
import { Icon } from '../../../libraries/react-native'
import { ColorKeys, getTextColorByBackground } from '../../../utils/colors'

const IZStickyButton = (props: ButtonProps) => {
  
  const { icon, ...buttonProps } = props
  const customIcon = <Icon icon={icon} color={getTextColorByBackground(ColorKeys.primary)}/>
  return <HibridStickyButton {...buttonProps} icon={()=>customIcon}/>
}

export default IZStickyButton
