import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import IZCollapse from '../../components/containers/IZCollapse'
import addPrescriptionI18nKeys from './i18n/keys'
import TreatmentPrescribed from './components/TreatmentPrescribed'
import { useEffect, useState } from 'react'
import MedicationMoreInfo from './components/MedicationMoreInfo'
import AnimalInfo from './components/AnimalInfo'
import { Prescription } from '../../interfaces/Prescription'
//@ts-ignore
import moment from 'moment'
import AuthService from '../../services/authService'
import useMessage from '../../hooks/useMessage'
import VeterinarianService from '../../services/VeterinarianService'
import { Veterinarian } from '../../interfaces/Veterinarian'
import AnimalService from '../../services/AnimalService'
import { useAsyncFunctionState } from '../../hooks/utils'
import { Linking, Platform, ViewStyle } from 'react-native'
import IZLoading from '../../components/atomic-components/Loading'
import { useNavigation } from '@react-navigation/native'
import CustomHeader from '../../components/CustomHeader'
import MedicamentService from '../../services/MedicamentService'
import PrescriptionService from '../../services/PrescriptionService'
//@ts-ignore
import SignatureCanvas from '../../libraries/Signature'
import ButtonsControl from './components/ButtonsControl'
import {
  optionsDropdownUsePrescription,
  optionsRadioButtonFrecuenceTreatment,
  optionsTreatmentClass,
  requiredFieldsPrescription,
  optionsDropdownDispensableType,
} from './constants'
import IZInput from '../../components/atomic-components/IZInput'
import IZCheckBox from '../../components/atomic-components/IZCheckBox'
import IZDropdown from '../../components/atomic-components/IZDropdown'
import IZAdaptiveContainer from '../../components/containers/IZAdaptiveContainer'
import styles from './styles'
import prescriptionI18nKeys from './i18n/keys'
import IZButton from '../../components/atomic-components/Button'
import SignatureInfo from './components/SignatureInfo'
import StateWrapper from '../../components/wrappers/StateWrapper'
import { Signature } from '../../interfaces/Signature'
import BreederService from '../../services/BreederService'

//@ts-ignore
import Copy from '../../libraries/Clipboard'
import IZDatePicker from '../../components/atomic-components/IZDatePicker'
import ButtonDeletePrescription from './components/ButtonDeletePrescription'
import IZFilePicker from '../../components/atomic-components/IZFilePicker'
import {
  Icon,
  Image,
  TouchableOpacity,
  View,
} from '../../libraries/react-native'
import MedicationData from './components/MedicationData'
import IZPill from '../../components/atomic-components/Pill'
import {
  DescriptionText,
  ErrorText,
} from '../../components/atomic-components/Text/variants'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import SignatureService from '../../services/SignatureService'
import { downloadFromUrl } from '../../utils/attachedFile'

export default function AddPrescription(props: any) {
  const {
    case_id,
    animal_identifier,
    specie,
    breeder,
    prescription: prescriptionId,
  } = props.route.params ?? {}
  const { t } = useTranslation(SCREEN.AddPrescription)
  const [medication, setMedication] = useState<string | undefined>(undefined)
  const [isLoadingPrescriptionValues, setIsLoadingPrescriptionValues] =
    useState<boolean>(true)

  const [prescription, setPrescription] = useState<Partial<Prescription>>({
    case: case_id,
    breeder,
    specie,
  })
  const isEditable =
    prescription.prescription_status === 'Draft' ||
    prescription.prescription_status === undefined ||
    prescription.prescription_status === 'PresvetRejected'

  const [isSignatureVisible, setSignatureVisible] = useState<boolean>(false)
  const navigation = useNavigation()
  const { showMessage } = useMessage()

  const onHandleChangeMedication = (medication: any) => {
    setMedication(medication)
    setPrescription((currentState) => ({
      ...currentState,
      medicament: medication.id,
    }))
  }


  
  const { specieName } = useAsyncFunctionState(
    async () => specie && (await AnimalService.getSpecieName(specie)),
    {}
  )

  const initPrescription = async () => {
    try {
      setIsLoadingPrescriptionValues(true)
      if (prescriptionId) {
        const prescriptionDetails: Prescription =
          await PrescriptionService.getPrescription(prescriptionId)
        const medicamentDetails = await MedicamentService.getMedicament(
          prescriptionDetails.medicament
        )
        setMedication(medicamentDetails)
        const {
          dose_unit,
          anulation_date,
          dose,
          feed_quantity,
          presvet_id_work,
          presvet_status,
          signature_date,
          status_change_date,
          token_id,
          antibiogram_document,
          cleanliness_plan,
          ...auxPrescription
        } = prescriptionDetails as Partial<Prescription>
        setPrescription((currentState) => ({
          ...currentState,
          ...auxPrescription,
          medicament: medicamentDetails.id,
        }))
      }
    } finally {
      setIsLoadingPrescriptionValues(false)
    }
  }

  useEffect(() => {
    initPrescription()
  }, [])

  const onhandleAcceptDeletePrescription = async () => {
    try {
      await PrescriptionService.deletePrescription(prescriptionId)
      showMessage({
        content: t(
          prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE_SUCCESS
        ),
        type: 'success',
      })
      //@ts-ignore
      navigation.navigate(STACKS.CaseDetail, {
        screen: SCREEN.PrescriptionDetail,
        case_id: case_id,
      })
    } catch (error) {
      showMessage({
        content: t(
          prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE_ERROR
        ),
        type: 'error',
      })
    }
  }

  const onHandleDownload = () => {
    if (Platform.OS !== 'web') {
      showMessage({
        content: t(prescriptionI18nKeys.DOWNLOAD_SUCCESS),
        type: 'success',
      })
    }
  }

  const onHandleClickButtonSavePrescription = async () => {
    try {
      if (!parseFloat(prescription.treatment_duration!)) {
        showMessage({
          content: t(
            addPrescriptionI18nKeys.CREATE_PRESCRIPTION_MESSAGE_ERROR_TREADMENT_DURATION_FORMAT
          ),
          type: 'error',
        })
        return
      }
      if (parseFloat(prescription.total_package_perc!) > 100) {
        showMessage({
          content: t(
            addPrescriptionI18nKeys.CREATE_PRESCRIPTION_MESSAGE_ERROR_PERCENTAGE
          ),
          type: 'error',
        })
        return
      }
      if (parseFloat(prescription.med_quantity!) > 999) {
        showMessage({
          content: t(
            addPrescriptionI18nKeys.CREATE_PRESCRIPTION_MESSAGE_ERROR_MED_CUANTITY_EXCEDED
          ),
          type: 'error',
        })
        return
      }
      let veterinarian = prescription.veterinarian
      if (!veterinarian) {
        const { user_id } = await AuthService.getAuthInfo()
        const SignatureInfo: Veterinarian =
          await VeterinarianService.getVeterinarianByUserId(user_id!)
        veterinarian = SignatureInfo.id
      }

      const requiredFields = requiredFieldsPrescription(t)

      const missingFields = Object.keys(requiredFields).filter(
        (field) => !(prescription as any)?.[field]
      )
      if (missingFields.length > 0) {
        const errorMessage = `${t(
          addPrescriptionI18nKeys.ERROR_REQUIRED_FIELDS
        )} ${missingFields
          ?.map((field) =>
            //@ts-ignore
            requiredFields[field].replace('*', '')
          )
          .join(', ')}`
        showMessage({ content: errorMessage, type: 'error' })
        return
      }
      const { dispensation_type, ...auxPrescription } = prescription as any

      if (!prescriptionId) {
        await PrescriptionService.createPrescription({
          ...auxPrescription,
          prescription_status: 'Draft',
          prescription_date: moment().format('YYYY-MM-DD'),
          dispensation_type: dispensation_type,
          total_package_perc: '100.0',
          veterinarian,
        } as Prescription)
        showMessage({
          content: t(
            addPrescriptionI18nKeys.CREATE_PRESCRIPTION_MESSAGE_SUCCESSFUL
          ),
          type: 'success',
        })
      } else {
        await PrescriptionService.updatePrescription(prescriptionId, {
          ...auxPrescription,
          prescription_status: 'Draft',
          veterinarian,
          total_package_perc: '100.0',
        } as Prescription)
        showMessage({
          content: t(
            addPrescriptionI18nKeys.EDIT_PRESCRIPTION_MESSAGE_SUCCESSFUL
          ),
          type: 'success',
        })
      }

      props.navigation.navigate(STACKS.CaseDetail, {
        screen: SCREEN.PrescriptionDetail,
        case_id,
      })
    } catch (error) {
      showMessage({
        content: t(addPrescriptionI18nKeys.CREATE_PRESCRIPTION_MESSAGE_ERROR),
        type: 'error',
      })
    }
  }

  const onHandleChangePrescription = (name: string) => (value: any) => {
    setPrescription((currentState: any) => {
      currentState[name] = value
      return currentState
    })
  }
  const getSignatureUserData = async (
    signatureType: Signature['signature_type']
  ) => {
    try {
      if (signatureType === undefined) return {}
      const signatureTypeLowerCase = signatureType?.toLocaleLowerCase()
      const signatureUserId = (prescription as any)[signatureTypeLowerCase]
      if (signatureType === 'Veterinarian') {
        const response: Veterinarian =
          await VeterinarianService.getVeterinarian(signatureUserId)
        return {
          veterinarian_registration_number: response.registration_number,
          veterinarian_identification: response.identification,
        }
      } else if (signatureType === 'Breeder') {
        const response = await BreederService.getBreeder(signatureUserId)
        return { breeder_rega: response.rega_id }
      } else {
        return {}
      }
    } catch (error) {
      return {}
    }
  }

  const signatures = ['Veterinarian', 'Breeder', 'Dispenser']
    .map((signature_type) => {
      return prescription.signatures?.find(
        (signature) => signature.signature_type === signature_type
      )
    })
    .filter((_) => !!_)

  return (
    <>
      <CustomHeader
        buttonText={t(addPrescriptionI18nKeys.BUTTON_GO_BACK)}
        onPress={() =>
          //@ts-ignore
          navigation.navigate(STACKS.CaseDetail, {
            screen: SCREEN.PrescriptionDetail,
            case_id,
          })
        }
      />

      <IZScreenContainer
        withScroll
        footer={
          !isLoadingPrescriptionValues ? (
            isEditable ? (
              <>
                <SignatureCanvas
                  prescription={prescriptionId}
                  isVisible={isSignatureVisible}
                  onCancel={() => {
                    setSignatureVisible(false)
                  }}
                  onAccept={async (signature?: any) => {
                    try {
                      let name=''
                      let address=''
                      try {
                        
                        const authInfo = await AuthService.getAuthInfo()
                        const userId = authInfo.user_id
                        const veterinarianInfo: Veterinarian =
                        await VeterinarianService.getVeterinarianByUserId(userId!)
                        address= veterinarianInfo.address
                        name = `${veterinarianInfo.name || ''} ${veterinarianInfo.surname || ''}  ${veterinarianInfo.second_surname || ''}`
                      } catch (error) { }
                      const signature_type = 'Veterinarian'
                      const signature_date = moment().format()
                      const signatureCall: Signature = {
                        prescription: prescriptionId,
                        signature_type: signature_type,
                        signature_date: signature_date,
                        name,
                        address, 
                        signature,
                      }
                      await SignatureService.setSignature(signatureCall)
                      signature &&
                        (await SignatureService.sendSignature(
                          prescriptionId,
                          signature
                        ))
                      showMessage({
                        content: t(prescriptionI18nKeys.SIGNATURE_CALL_SUCCESS),
                        type: 'success',
                      })
                      await initPrescription()
                    } catch (error) {
                      showMessage({
                        content:
                          (error as any)?.response?.data?.error ??
                          t(prescriptionI18nKeys.SIGNATURE_CALL_ERROR),
                        type: 'error',
                        duration: 7000,
                      })
                    }
                    setSignatureVisible(false)
                  }}
                />

                <ButtonsControl
                  buttons={[
                    {
                      onPress: onHandleClickButtonSavePrescription,
                      mode: 'outlined',
                      text: t(addPrescriptionI18nKeys.BUTTON_SAVE),
                    },
                    ...(!!prescriptionId ?[{
                      onPress: () => setSignatureVisible(true),
                      mode: 'contained',
                      text: t(addPrescriptionI18nKeys.BUTTON_SIGN),
                    }]:[]) as any,
                    {
                      onPress: () =>
                        //@ts-ignore
                        navigation.navigate(STACKS.CaseDetail, {
                          screen: SCREEN.PrescriptionDetail,
                          case_id,
                        }),
                      mode: 'text',
                      text: t(addPrescriptionI18nKeys.BUTTON_EXIT),
                    },
                  ]}
                />
              </>
            ) : (
              <ButtonsControl
                buttons={[
                  {
                    onPress: async () => {
                      const shareUrl = await PrescriptionService.getShareLink(
                        prescriptionId
                      )
                      Copy(shareUrl.url)
                      showMessage({
                        content: t(addPrescriptionI18nKeys.URL_COPY_DONE),
                        type: 'success',
                      })
                    },
                    mode: 'contained',
                    text: t(addPrescriptionI18nKeys.BUTTON_SHARE),
                  },
                  ...((prescription.ipfs_url
                    ? [
                        {
                          onPress: async () => {
                            if (Platform.OS === 'web') {
                              Linking.openURL(prescription.ipfs_url!)
                            } else {
                              try {
                                const time =
                                  moment().format('yyyy_mm_dd___hh_mm')
                                await downloadFromUrl(
                                  prescription.ipfs_url!,
                                  `prescription_${time}.pdf`
                                )
                                showMessage({
                                  content: t(
                                    prescriptionI18nKeys.DOWNLOAD_SUCCESS
                                  ),
                                  type: 'success',
                                })
                              } catch (error) {
                                
                                showMessage({
                                  content:
                                    typeof error == typeof 'string'
                                      ? error as string
                                      : t(prescriptionI18nKeys.DOWNLOAD_ERROR),
                                  type: 'error',
                                })
                               
                              }
                            }
                          },
                          mode: 'outlined',
                          text: t(addPrescriptionI18nKeys.BUTTON_DOWNLOAD),
                        },
                      ]
                    : []) as any),
                  {
                    onPress: () =>
                      //@ts-ignore
                      navigation.navigate(STACKS.CaseDetail, {
                        screen: SCREEN.PrescriptionDetail,
                        case_id,
                      }),
                    mode: 'text',
                    text: t(addPrescriptionI18nKeys.BUTTON_EXIT),
                  },
                ]}
              />
            )
          ) : undefined
        }
      >
        {isLoadingPrescriptionValues ? (
          <IZLoading style={{ marginTop: 20 }} />
        ) : (
          <>
            {!!prescription.prescription_status && (
              <IZAdaptiveContainer
                itemsPerRow={3}
                minWidthPerItem={350}
                style={{}}
              >
                <IZAdaptiveContainer
                  itemsPerRow={3}
                  minWidthPerItem={150}
                  style={{ overflow: 'visible' }}
                >
                  {!!prescription.presvet_code &&
                    prescription.presvet_code !== 'null' && (
                      <MedicationData
                        label={t(
                          prescriptionI18nKeys.PRESCRIPTION_DATA_N_PRESCRIPTION
                        )}
                        value={prescription.presvet_code!}
                      />
                    )}
                  <MedicationData
                    label={t(
                      prescriptionI18nKeys.PRESCRIPTION_DATA_LAST_UPDATE
                    )}
                    style={{ minWidth: 100 }}
                    value={prescription.prescription_date!}
                  />
                </IZAdaptiveContainer>
                <MedicationData
                  label={t(prescriptionI18nKeys.PRESCRIPTION_DATA_EXPIRATION)}
                  value={prescription.expiration_date!}
                />

                <IZAdaptiveContainer
                  itemsPerRow={3}
                  minWidthPerItem={150}
                  style={{ alignItems: 'center', height: 75, marginTop: 10 }}
                >
                  {
                    <IZPill style={{ maxWidth: 125 }}>
                      {t(`PRESVET_STATE_${prescription.prescription_status}`)}
                    </IZPill>
                  }
                  <TouchableOpacity
                    onPress={initPrescription}
                    style={{ flexDirection: 'row' }}
                  >
                    <>
                      <Icon
                        icon="reload"
                        color={getThemeColor(ColorKeys.primary)}
                      />
                      <DescriptionText
                        style={{
                          color: getThemeColor(ColorKeys.primary),
                          marginLeft: Platform.OS !== 'web' ? 25 : 5,
                        }}
                      >
                        {t(prescriptionI18nKeys.BUTTON_RELOAD)}
                      </DescriptionText>
                    </>
                  </TouchableOpacity>
                </IZAdaptiveContainer>
                {prescriptionId &&
                  !prescription.signatures?.find(
                    (signature) => signature.signature_type === 'Dispenser'
                  ) && (
                    <ButtonDeletePrescription
                      t={t}
                      onAccept={onhandleAcceptDeletePrescription}
                    />
                  )}

                {!!prescription.presvet_errors && (
                  <View>
                    <DescriptionText>
                      {t(prescriptionI18nKeys.PRESVET_DATA_ERRORS)}
                    </DescriptionText>
                    <ErrorText>{prescription.presvet_errors}</ErrorText>
                  </View>
                )}
                {!!prescription.presvet_incidents && (
                  <View>
                    <DescriptionText>
                      {t(prescriptionI18nKeys.PRESVET_DATA_INCIDENCES)}
                    </DescriptionText>
                    <ErrorText>{prescription.presvet_incidents}</ErrorText>
                  </View>
                )}
              </IZAdaptiveContainer>
            )}
            <IZCollapse
              style={{ marginTop: 10 }}
              headerTitle={t(
                addPrescriptionI18nKeys.COLLAPSE_TREATMENT_PRESCRIBE
              )}
              initialExpanded={true}
            >
              <TreatmentPrescribed
                editable={isEditable}
                breeder={breeder}
                case_id={case_id}
                t={t}
                onMedicationChange={onHandleChangeMedication}
                medication={medication}
                specie={specie}
              />
              {prescription.prescription_status === undefined && (
                <IZButton
                  style={{ marginTop: 16 }}
                  mode="outlined"
                  children={t(addPrescriptionI18nKeys.LOAD_LAST_PRESCRIPTION)}
                  onPress={async () => {
                    const authInfo = await AuthService.getAuthInfo()
                    const userId = authInfo.user_id
                    const veterinarianInfo: Veterinarian =
                      await VeterinarianService.getVeterinarianByUserId(userId!)
                    const veterinarian = veterinarianInfo.id
                    const {
                      anulation_date,
                      dose,
                      feed_quantity,
                      presvet_id_work,
                      presvet_status,
                      signature_date,
                      status_change_date,
                      token_id,
                      cleanliness_plan,
                      cleanliness_plan_link,
                      antibiogram_document,
                      antibiogram_document_link,
                      ...lastPrescription
                    } = await PrescriptionService.getLastPrescription(
                      veterinarian,
                      case_id
                    )
                    const lastMedicament = lastPrescription.medicament
                    const medicamentDetails =
                      await MedicamentService.getMedicament(lastMedicament)
                    setMedication(medicamentDetails)
                    setPrescription((currentState) => {
                      return {
                        ...currentState,
                        ...lastPrescription,
                        signatureDate: undefined,
                        signatures: undefined,
                      }
                    })
                  }}
                />
              )}
            </IZCollapse>
            <IZCollapse
              headerTitle={t(addPrescriptionI18nKeys.COLLAPSE_INFO_COMPLETE)}
              initialExpanded={true}
            >
              <IZAdaptiveContainer
                itemsPerRow={3}
                minWidthPerItem={300}
                itemStyle={styles.itemContainer}
              >
                <IZDatePicker
                  value={prescription.expiration_date}
                  label={t(prescriptionI18nKeys.INPUT_LABEL_EXPIRATION_DATE)}
                  placeholder={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_EXPIRATION_DATE
                  )}
                  editable={isEditable}
                  onChangeDate={onHandleChangePrescription('expiration_date')}
                  format="YYYY-MM-DD"
                  description={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION__EXPIRATION_DATE
                  )}
                />
                <IZDropdown
                  value={prescription.prescription_use}
                  label={t(prescriptionI18nKeys.INPUT_USE_LABEL_PRESCRIPTION)}
                  description={t(
                    prescriptionI18nKeys.INPUT_USE_DESCRIPTION_PRESCRIPTION
                  )}
                  options={optionsDropdownUsePrescription(t)}
                  initialValue={'Normal'}
                  onChange={onHandleChangePrescription('prescription_use')}
                  editable={isEditable}
                />
                <IZDropdown
                  value={prescription.prescription_type}
                  options={optionsRadioButtonFrecuenceTreatment(t)}
                  initialValue={'Ordinary'}
                  description={t(
                    prescriptionI18nKeys.INPUT_USE_DESCRIPTION_PRESCRIPTION
                  )}
                  label={t(
                    prescriptionI18nKeys.INPUT_LABEL_TREATMENT_TYPE_PRESCRIPTION
                  )}
                  onChange={onHandleChangePrescription('prescription_type')}
                  editable={isEditable}
                />
                <IZDropdown
                  value={prescription.dispensation_type}
                  initialValue={'Dispensable'}
                  description={t(
                    prescriptionI18nKeys.INPUT_USE_DESCRIPTION_PRESCRIPTION
                  )}
                  options={optionsDropdownDispensableType(t)}
                  label={t(prescriptionI18nKeys.DISPENSATION_TYPE)}
                  onChange={onHandleChangePrescription('dispensation_type')}
                  editable={isEditable}
                />
                <IZDropdown
                  value={prescription.treatment_class}
                  options={optionsTreatmentClass(t)}
                  description={t(
                    prescriptionI18nKeys.INPUT_USE_DESCRIPTION_PRESCRIPTION
                  )}
                  label={t(prescriptionI18nKeys.INPUT_LABEL_TREATMENT_CLASS)}
                  onChange={onHandleChangePrescription('treatment_class')}
                  editable={isEditable}
                />
                <IZCheckBox
                  initialValue={prescription.is_chronic}
                  label={t(
                    prescriptionI18nKeys.INPUT_LABEL_IS_VALID_DISPENSING
                  )}
                  onChange={onHandleChangePrescription('is_chronic')}
                  editable={isEditable}
                />
                <IZInput
                  editable={isEditable}
                  value={prescription.med_quantity}
                  label={t(prescriptionI18nKeys.INPUT_PLACEHOLDER_PACKAGING)}
                  placeholder={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_PACKAGING
                  ).replace('*', '')}
                  description={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION_PACKAGING
                  )}
                  onChangeText={onHandleChangePrescription('med_quantity')}
                />
                <IZInput
                  value={prescription.total_package_perc}
                  label={t(prescriptionI18nKeys.INPUT_LABEL_PACKAGE_PERCENTAGE)}
                  placeholder={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_PACKAGE_PERCENTAGE
                  ).replace('*', '')}
                  description={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION_PACKAGE_PERCENTAGE
                  )}
                  onChangeText={onHandleChangePrescription(
                    'total_package_perc'
                  )}
                  editable={isEditable}
                />
                <IZInput
                  value={prescription.treatment_duration}
                  label={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_DURATION_TREATMENT
                  )}
                  placeholder={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_DURATION_TREATMENT
                  ).replace('*', '')}
                  description={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION_DURATION_TREATMENT
                  )}
                  onChangeText={onHandleChangePrescription(
                    'treatment_duration'
                  )}
                  editable={isEditable}
                />
              </IZAdaptiveContainer>
              <IZAdaptiveContainer
                itemsPerRow={2}
                minWidthPerItem={500}
                itemStyle={styles.itemContainer}
                itemSeparation={20}
              >
                <IZInput
                  editable={isEditable}
                  value={prescription.caution_comment}
                  label={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION_LABEL_ADVICE_REGA
                  )}
                  placeholder={t(
                    prescriptionI18nKeys.INPUT_PLACEHOLDER_ADVICE_REGA
                  )}
                  description={t(
                    prescriptionI18nKeys.INPUT_DESCRIPTION_ADVICE_REGA
                  )}
                  onChangeText={onHandleChangePrescription('caution_comment')}
                />
                <IZAdaptiveContainer itemsPerRow={2} minWidthPerItem={300}>
                  <IZFilePicker
                    value={prescription.cleanliness_plan_link}
                    label={t(
                      prescriptionI18nKeys.INPUT_LABEL__ADD_CLEANING_PLAN
                    )}
                    onChange={onHandleChangePrescription('cleanliness_plan')}
                    editable={isEditable}
                    onDownload={onHandleDownload}
                  />
                  <IZFilePicker
                    value={prescription.antibiogram_document_link}
                    label={t(prescriptionI18nKeys.INPUT_LABEL__ADD_ANTIBIOGRAM)}
                    onChange={onHandleChangePrescription(
                      'antibiogram_document'
                    )}
                    editable={isEditable}
                    onDownload={onHandleDownload}
                  />
                </IZAdaptiveContainer>
              </IZAdaptiveContainer>
            </IZCollapse>
            <IZCollapse
              headerTitle={t(addPrescriptionI18nKeys.COLLAPSE_MORE_INFO)}
            >
              {!!medication && (
                <MedicationMoreInfo t={t} medication={medication} />
              )}
            </IZCollapse>
            <IZCollapse
              headerTitle={t(addPrescriptionI18nKeys.COLLAPSE_INFO_ANIMAL)}
            >
              {
                <AnimalInfo
                  t={t}
                  animalIdentifierList={animal_identifier}
                  specie={specieName}
                />
              }
            </IZCollapse>
            <>
              {prescriptionId &&
                signatures?.map((signature: any) => {
                  return (
                    <StateWrapper
                      initialFunction={() =>
                        getSignatureUserData(signature?.signature_type)
                      }
                    >
                      {(extraData) => (
                        <IZCollapse
                          key={signature.id}
                          headerTitle={t(
                            (prescriptionI18nKeys as any)[
                              `COLLAPSE_${signature.signature_type.toLocaleUpperCase()}`
                            ]
                          )}
                        >
                          {
                            <SignatureInfo
                              t={t}
                              signature={{ ...signature, ...extraData }}
                            />
                          }
                        </IZCollapse>
                      )}
                    </StateWrapper>
                  )
                })}
            </>
            <IZCheckBox
              label={t(
                prescriptionI18nKeys.CHECKBOX_TERMS_AND_CONDITIONS_LABEL
              )}
              onChange={onHandleChangePrescription('legal_check')}
              initialValue={(prescription as any).legal_check}
              containerStyle={{ marginTop: 20 }}
            />
          </>
        )}
      </IZScreenContainer>
    </>
  )
}
