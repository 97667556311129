import { Platform, ViewStyle } from 'react-native'
const styles = {
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 15,
  } as ViewStyle,
  contentContainer: {
    paddingBottom: 30,
    justifyContent: 'space-between',
  } as ViewStyle,
}
export default styles
