const localeES = {
  INPUT_SYSTEM_OPTION_DEFAULT: '---',
  INPUT_SYSTEM_OPTION_LABEL: 'Sistema/s afectados',
  INPUT_SYSTEM_OPTION_MUSCULOSKELETAL: 'Musculoesquelético',
  INPUT_SYSTEM_OPTION_RESPIRATORY: 'Respiratorio',
  INPUT_SYSTEM_OPTION_DIGESTIVE: 'Digestivo',
  INPUT_SYSTEM_OPTION_GENITOURINARY: 'Génitourinario',
  INPUT_SYSTEM_OPTION_NERVOUS: 'Nervioso',
  INPUT_SYSTEM_OPTION_CIRCULATORY: 'Circulatorio',
  INPUT_SYSTEM_OPTION_SKIN: 'Piel y mucosas',
  INPUT_SYSTEM_OPTION_SENSEORGANS: 'Organos de los sentidos',
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFAULT: '---',
  INPUT_TYPE_DIAGNOSIS_OPTION_LABEL: 'Tipo de diagnóstico',
  INPUT_TYPE_DIAGNOSIS_OPTION_SYMPTOMATIC: 'Sintomático',
  INPUT_TYPE_DIAGNOSIS_OPTION_INDIRECT: 'Indirecto',
  INPUT_TYPE_DIAGNOSIS_OPTION_ETIOPATHOGENIC: 'Etiopatogénico',
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFINITIVE: 'Definitivo',
  INPUT_TYPE_DIAGNOSIS_OPTION_DIRECT: 'Directo',
  INPUT_TYPE_DIAGNOSIS_OPTION_DEFERRED: 'Diferido (Necropsia)',
}

export default localeES
