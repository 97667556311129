const localeES = {
  TITLE: 'Recuperar acceso',
  DESCRIPTION:
    'Introduce tu correo. Si existe, enviaremos un enlace de recuperación.',
  LINK_GO_BACK: 'Volver a Inicio',
  EMAIL: 'Correo',
  BUTTON: 'Enviar',
  SEND_EMAIL_SUCCESS: 'Se ha enviado un enlace de recuperación al correo introducido',
  SEND_EMAIL_ERROR_NO_VALID_EMAIL: 'Error, el correo introducido no es valido',
  SEND_EMAIL_ERROR_UNEXPECTED_ERROR: 'Error, el correo introducido no es valido',
}

export default localeES
