import React from 'react'
import { View } from '../../../libraries/react-native'
import MedicationData from './MedicationData'
import prescriptionI18nKeys from '../i18n/keys'
import { formatString } from '../../../utils/strings'

interface Props {
  t: (s: string) => string
  medication: any
}
const MedicationMoreInfo = (props: Props) => {
  return (
    <View>
      <MedicationData
        label={props.t(prescriptionI18nKeys.MEDICATION_MORE_INFO_LABEL_LABORATORY)}
        value={props.medication.distributor}
      />
      <MedicationData
        label={props.t(prescriptionI18nKeys.MEDICATION_MORE_INFO_LABEL_CONTRAINDICATIONS)}
        value={formatString(props.medication.contraindications)}
      />
      <MedicationData
        label={props.t(prescriptionI18nKeys.MEDICATION_MORE_INFO_LABEL_FARMACEUTIC_FORM)}
        value={props.medication.pharmaceutical_form}
      />
      <MedicationData
        label={props.t(prescriptionI18nKeys.MEDICATION_MORE_INFO_LABEL_ADVERSE_REACTIONS)}
        value={formatString(props.medication.adverse_reactions)}
      />
    </View>
  )
}

export default MedicationMoreInfo
