const localeES = {
  TITLE: 'Nuevo animal o lote',
  SCREEN_TITLE: 'Añadir nuevo lote o animal',
  INPUT_LABEL_ID: '*Identificador',
  INPUT_LABEL_N_ANIMALS: 'Nº Animales',
  INPUT_LABEL_REGA: '*REGA',
  INPUT_DESCRIPTION_REGA: 'Busca el REGA al que pertenece',
  INPUT_DESCRIPTION_ID: 'Añade el identificador del crotal o lote',
  INPUT_DESCRIPTION_N_ANIMALS: 'Indica el nº de individuos si es un lote',
  DROPDOWN_DESCRIPTION_SPECIES: 'Seleciona la especie registrada',
  DROPDOWN_DESCRIPTION_GENDER: 'Selecciona una opción',
  BUTTON_ADD: 'Añadir',
  DROPDOWN_LABEL_GENDER: '*Sexo',
  DROPDOWN_GENDER_LABEL_DEFAULT: '---',
  DROPDOWN_GENDER_LABEL_MALE: 'Macho/s',
  DROPDOWN_GENDER_LABEL_FEMALE: 'Hembra/s',
  DROPDOWN_GENDER_LABEL_MIX: 'Mixto',
  ADD_ANIMAL_SUCCESS: 'Animal añadido correctamente',
  ADD_ANIMAL_ERROR: 'Ha habido un error a la hora de añadir el animal',
}

export default localeES
