import React, { useEffect, useState } from 'react'
import IZFormat from '../IZFormat'
import { bundleName } from './constants'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import localeES from './i18n/es'
import { ViewStyle } from 'react-native'
import animalInputSpecieI18nKeys from './i18n/keys'
import { AnimalSpecie } from '../../../interfaces/Animal'
import { useServices } from '../../../hooks/useServices/useServices'
import AnimalService from '../../../services/AnimalService'
import { useOptionsSpecie } from '../../../hooks/useOptions'

interface Props {
  style?: ViewStyle
  initialValue?: any
  label?: string
  description?: string
}
/**
 * AnimalInputRega is a React component that provides an input field for selecting Rega identifiers.
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.label] - The label for the input field.
 * @param {string} [props.description] - The description for the input field.
 */
const AnimalInputSpecie = (props: Props) => {
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  const { optionsSpecie } = useOptionsSpecie()

  return (
    <IZFormat.DropDown
      {...props}
      name='specie_id'
      description={props.description ?? t(animalInputSpecieI18nKeys.DESCRIPTION_SPECIES)}
      options={optionsSpecie}
      label={t(animalInputSpecieI18nKeys.LABEL)}
    />
  )
}

export default AnimalInputSpecie
