import IZFormat from '../IZFormat'
import { useTranslation } from 'react-i18next'
import { bundleName } from './constants'
import localeES from './i18n/es'
import i18next from 'i18next'
import caseInputTimestampI18nKeys from './i18n/keys'
import { IZDatePickerProps } from '../../atomic-components/IZDatePicker'

interface Props extends IZDatePickerProps {
  initialValue?: string
}
const CaseInputTimestamp = (props: Props) => {
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)

  return (
    <IZFormat.DatePicker
      {...props}
      name='timestamp'
      description={t(caseInputTimestampI18nKeys.INPUT_DESCRIPTION_DATE)}
      placeholder={t(caseInputTimestampI18nKeys.INPUT_PLACEHOLDER_DATE)}
      label={t(caseInputTimestampI18nKeys.INPUT_DATE_START)}
    />
  )
}

export default CaseInputTimestamp
