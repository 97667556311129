import { DescriptionText } from '../../../atomic-components/Text/variants'

const RegaOption = (props: any) => {
  return (
    <>
      <DescriptionText>ID: {props.id}</DescriptionText>
      <DescriptionText>REGA: {props.rega_id}</DescriptionText>
    </>
  )
}

export default RegaOption
