import { useContext } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { FormatContext } from '../../../components/templates/IZFormat/context/format.context'
import { SCREEN } from '../../../constants/screens'
import { Link } from '../../../components/atomic-components/Text/variants'
import inputSearchAnimalIdentifierI18nKeys from '../../../components/templates/AnimalIdentifierInputSearch/i18n/keys'
import { bundleName } from '../../../components/templates/AnimalIdentifierInputSearch/constants'
import useModal from '../../../hooks/useModal'

interface Props {
  navigation: any
  route: any
}

const AnimalIDFooter = (props: Props) => {
  const { hideModal } = useModal()
  const { format }: any = useContext(FormatContext)
  const { t } = useTranslation(bundleName)
  return (
    <Link
      onPress={() => {
        //@ts-ignore
        props.navigation.navigate(SCREEN.AddAnimal, {
          lastScreen: props.route.name,
          animal: format,
        })
        hideModal()
      }}
    >
      {t(inputSearchAnimalIdentifierI18nKeys.BUTTON_ADD_NEW_ANIMAL)}
    </Link>
  )
}

export default AnimalIDFooter
