import IZFormat from '../IZFormat'
import { useTranslation } from 'react-i18next'
import { bundleName } from './constants'
import localeES from './i18n/es'
import i18next from 'i18next'
import { IZDropdownProps } from '../../atomic-components/IZDropdown'
import caseDropboxEvolutionI18nKeys from './i18n/keys'
import { useServices } from '../../../hooks/useServices/useServices'
import { useEffect, useState } from 'react'
import { mergeArrayObjects } from '../../../utils/objects'
import { Evolution } from '../../../interfaces/Case'

interface Props extends Omit<IZDropdownProps, 'options'> {
  withLabel?: boolean
}
const CaseDropboxEvolution = (props: Props) => {
  const { withLabel, ...inputProps } = props
  i18next.addResourceBundle('es', bundleName, localeES)
  const { t } = useTranslation(bundleName)
  const { CaseService } = useServices()
  const [options, setOptions] = useState<{ value: Evolution | ''; label: string }[]>([])
  useEffect(() => {
    ;(async () => {
      try {
        const unformatedOptions = await CaseService.getEvolution()
        const mergedUnformatedOptions = mergeArrayObjects(unformatedOptions)
        const formatedOptions = Object.entries(mergedUnformatedOptions).map(([key, value]: any) => ({
          value: key,
          label: (caseDropboxEvolutionI18nKeys as any)[`INPUT_PRONOSTIC_OPTION_${key.toUpperCase()}`]
            ? t((caseDropboxEvolutionI18nKeys as any)[`INPUT_PRONOSTIC_OPTION_${key.toUpperCase()}`])
            : value,
        }))
        setOptions([
          { label: t(caseDropboxEvolutionI18nKeys.INPUT_PRONOSTIC_OPTION_DEFAULT), value: '' },
          ...formatedOptions,
        ])
      } catch (error) {}
    })()
  }, [])
  return (
    <IZFormat.DropDown
      {...inputProps}
      label={t(caseDropboxEvolutionI18nKeys.INPUT_PRONOSTIC_OPTION_LABEL)}
      name='evolution'
      options={options}
    />
  )
}

export default CaseDropboxEvolution
