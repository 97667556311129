import { Icon } from '../../../libraries/react-native'
import IZView from '../../../components/atomic-components/IZView'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { ViewStyle } from 'react-native'
import { Title } from '../../../components/atomic-components/Text/variants'
import {
  ColorKeys,
  getBackgroundTheme,
  getTextColorByBackground,
} from '../../../utils/colors'

interface Props {
  options: NativeStackNavigationOptions
}
const Header = (props: any) => {
  const { options, ...componentProps } = props

  const isheaderTitleString =
    typeof props.options?.headerTitle === typeof '' || props.options?.title
  return (
    <>
      {props.options.headerShown !== false ? (
        <IZView
          style={{
            ...styles.container,
            ...(props.options?.headerStyle as ViewStyle),
          }}
        >
          {options.headerBackTitleVisible && (
            <Icon
              onPress={() => {
                props.navigation.navigate('Main', { screen: 'MedicalCases' })
              }}
              icon="arrow-left"
              style={{
                color: getTextColorByBackground(ColorKeys.primary),
                marginRight: 20,
              }}
            />
          )}
          {isheaderTitleString && (
            <Title scheme={getBackgroundTheme(ColorKeys.primary)}>
              {(props.options?.headerTitle as string) ||
                props.options?.title ||
                props.name}
            </Title>
          )}
          {!isheaderTitleString &&
            (props.options?.headerTitle as any)?.(componentProps)}
        </IZView>
      ) : (
        <></>
      )}
    </>
  )
}

const styles = {
  container: {
    height: 75,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding: 20,
  } as ViewStyle,
}

export default Header
