import { AnimalGender } from '../../interfaces/Animal'
import addAnimalI18nKeys from './i18n/keys'

export const optionsGender = (
  t: any
): { label: string; value: AnimalGender | '' }[] => [
    {
      value: '',
      label: t(addAnimalI18nKeys.DROPDOWN_GENDER_LABEL_DEFAULT),
    },
    {
      value: 'Male',
      label: t(addAnimalI18nKeys.DROPDOWN_GENDER_LABEL_MALE),
    },
    {
      value: 'Female',
      label: t(addAnimalI18nKeys.DROPDOWN_GENDER_LABEL_FEMALE),
    },
    {
      value: 'Mixed',
      label: t(addAnimalI18nKeys.DROPDOWN_GENDER_LABEL_MIX),
    },
  ]
