import React, { useContext } from 'react'
import { View } from 'react-native'
import { FormatContext } from '../context/format.context'
import { useAutoFill } from '../hooks/useAutoFill'

interface FormProps {
  autoFillValue?: any
  nameParamOnChange?: string
}

/**
 * A higher-order component (HOC) that wraps a form input component to provide additional functionality such as auto-filling, error handling, and value propagation to the FormatContext.
 *
 * @param {React.Component} Component - The form input component to be wrapped.
 * @param {Object} formProps - Additional properties for configuring the behavior of the form.
 * @param {string} formProps.autoFillValue - The initial value to auto-fill in the form input.
 * @param {string} formProps.nameParamOnChange - The name of the prop responsible for handling value changes (e.g., 'onChangeText' for TextInput).
 * @param {Object} props - The component's properties.
 * @returns {React.Component} The wrapped form input component with added functionality.
 */
const FormWrapper = (Component: any, formProps?: FormProps) => (props: any) => {
  const { format, setFormat, error, setError } = useContext(FormatContext)
  const errorMessage = props.error ?? error?.[props.name]
  const value = format[props.name]
  const nameParamOnChange = formProps?.nameParamOnChange ?? 'onChangeText'

  ;(formProps?.autoFillValue ?? props.initialValue) &&
    useAutoFill(props.name, formProps?.autoFillValue ?? props.initialValue)
  formProps?.autoFillValue ?? props.initialValue
  const onHandleValueChange = (newValue: string) => {
    props[nameParamOnChange]?.(newValue)
    props.name && setFormat?.((format: any) => ({ ...format, [props.name!]: newValue }))
    setError((currentState: any) => {
      const newState = { ...currentState }
      delete newState[props.name]
      return newState
    })
  }

  return <Component value={value} error={errorMessage} {...props} {...{ [nameParamOnChange]: onHandleValueChange }} />
}

export default FormWrapper
