import { Image, Platform, TextStyle, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import { FONT_FAMILY } from '../../constants/fontFamily'
import { ButtonControlProps } from './components/ButtonsControl'

const styles = {
  canvasButtons: { borderRadius: 25, marginRight: 10 } as ViewStyle,
  canvasButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    height: 100,
    backgroundColor: 'white',
  } as ViewStyle,
  signTitle: { backgroundColor: '#ffffff', paddingTop: 16 } as ViewStyle,
  closeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 16,
  } as ViewStyle,
  buttonsControlContainer: {
    flexDirection: 'row',
    marginRight: 2,
    flexWrap: 'wrap',
    justifyContent: 'center',
  } as ViewStyle,
  buttonControl: ({
    button,
    length,
  }: {
    button: ButtonControlProps
    length: number
  }) => ({
    width: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: length < 3 ? 30 : 0,
    marginRight: 10,
    ...(button.mode === 'outlined' && {
      borderColor: getThemeColor(ColorKeys.primary),
    }),
  }) as ViewStyle,
  overlayPad: {
    width: '100%',
    backgroundColor: '#808080b3',
    padding: 50,
  } as ViewStyle,
  signatureCanvas: { backgroundColor: 'white', padding: 20 } as ViewStyle,
  closePadButton: {
    position: 'absolute',
    top: '3.5%',
    right: '3.5%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  signatureButton: {
    backgroundColor: getThemeColor(ColorKeys.primary),
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? 335 : 1000,
  } as ViewStyle,
  signatureButtonText: {
    letterSpacing: 0.57,
    fontFamily: FONT_FAMILY.PRINCIPAL,
    fontWeight: 'bold',
    color: 'white',
  } as TextStyle,
  signatureButtonOptionsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 40,
    width: '100%',
    marginTop: 10,
  } as ViewStyle,
  signatureButtonOption: { borderRadius: 25, marginRight: 10 } as ViewStyle,
  cardDateText: {
    fontSize: 16,
    letterSpacing: 1,
  } as TextStyle,
  itemContainer: {
    alignSelf: 'center',
  } as ViewStyle,
  treatmentMedicationLabel: {
    marginTop: 20,
    color: getThemeColor(ColorKeys.placeholder),
    overflow: 'visible',
  } as ViewStyle,
  treatmentMedicationContent: {
    overflow: 'visible',
    marginTop: 5,
  } as ViewStyle,
  closeButton: {
    alignSelf: 'flex-end',
  } as ViewStyle,
  modalContainer: {
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  modalContent: {
    borderRadius: 10,
    padding: 20,
  } as ViewStyle,
  headerModalContent: {
    backgroundColor: getThemeColor(ColorKeys.primary),
    padding: 15,
    paddingVertical: 25,
  } as ViewStyle,
  pillContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  } as ViewStyle,
  pill: {
    margin: 10,
  } as ViewStyle,
  card: {
    paddingBottom: 50,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  iconButton: { marginLeft: -20 },
  optionContainer: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  veterinarianSignatureContainer: {
    flexDirection: 'row',
  } as ViewStyle,
  veterinarianSignatureImage: {
    width: '100%',
    height: '100%',
  } as ViewStyle,
  veterinarianSignatureImageContainer: {
    marginLeft: 14,
    width: 50,
    height: 50,
  } as ViewStyle,
  veterinarianSignatureNameContainer: {
    marginLeft: 14,
  } as ViewStyle,
  optionHeaderLeftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  signImage: {
    marginTop: 24,
    marginHorizontal:'auto',
    border: '1px solid black',
    width: 150,
  } as ViewStyle,
  signDescriptionText: {margin:'auto',marginTop:-20} as TextStyle,
}
export default styles
