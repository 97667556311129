import i18next from '../../i18n.config'
import { SCREEN } from "../../constants/screens"
import { navigationScreensConfigType } from "../../navigation/types"
import es from "./i18n/es"
import NotFoundScreen from '.'

const ScreenName = SCREEN.NotFound

i18next.addResourceBundle("es", ScreenName, es)

const NotFoundConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: NotFoundScreen,
  options: { headerShown: false },
}

export default NotFoundConfig
