import { Checkbox, CheckboxProps } from 'react-native-paper'
import IZView from '../IZView'
import { DescriptionText } from '../Text/variants'
import { ViewStyle } from 'react-native'
import { View } from 'react-native'
import Dropdown from '../../../libraries/react-native/Dropdown'
import { getPrioritaryItem } from '../../../utils/objects'
import { useEffect, useState } from 'react'


export type IZDropdownProps = {
  onChange?: (b: any) => void
  label?: string
  style?: ViewStyle
  options: { value: string; label: string }[]
  value?: string
  editable?: boolean
  initialValue?: string
  multiselect?: boolean
  placeholder?: string
  description?: string
}

/**
 * IZDropdown is a dropdown component that allows users to select a value from a list of options.
 *
 * @component
 * @param {IZDropdownProps} props - The props for configuring the IZDropdown component.
 * @param {boolean} [props.value] - The selected value of the dropdown.
 * @param {Function} [props.onChange] - An optional callback function that is called when the selected value changes.
 * It receives the new selected value as a parameter.
 * @param {string} [props.label] - An optional label for the dropdown.
 * @param {ViewStyle} [props.containerStyle] - An optional style object to apply custom styles to the container of the dropdown.
 * @param {string} [props.name] - An optional name for the dropdown used to identify it.
 * @returns {JSX.Element} A dropdown element.
 */
const IZDropdown = (props: IZDropdownProps) => {
  const [stateValue, setStateValue] = useState(props.value ?? props.initialValue ?? (props.multiselect ? [] : ''))
  const editable = props.editable ===undefined || props.editable
  useEffect(()=>{
    props.value && setStateValue(props.value)
    props.initialValue && !props.value && props.onChange?.(props.initialValue)
  },[props.value])

  const { onChange, label, style, multiselect, placeholder, description, ...selectProps } = props
  const onHandleChange = (itemList: any) => {
    const item = Array.isArray(itemList)
      ? itemList.map((item: any) => item.value)
      : itemList.value
    setStateValue(item)
    props.onChange?.(item)
  }


  return (
    <View style={props.style}>
      {props.label && <DescriptionText style={{ fontSize: 12, marginBottom: 0 }}>{props.label}</DescriptionText>}
      <Dropdown {...(selectProps as any)} editable={editable} value={stateValue} onChange={onHandleChange} multiselect={props.multiselect} />
      {props.description && (
        <DescriptionText style={{ fontSize: 12, marginLeft: 16, marginTop: 4 }}>
          {props.description}
        </DescriptionText>
      )}
    </View>
  )
}

export default IZDropdown
