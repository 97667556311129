import React from 'react'
import { ViewProps, ViewStyle } from 'react-native'
import { View } from '../../../libraries/react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const IZView = (props: ViewProps) => {
  const background = getThemeColor(ColorKeys.background)
  return (
    <View
      {...props}
      style={{
        backgroundColor: background,
        ...(props.style as ViewStyle),
      }}
    />
  )
}

export default IZView
