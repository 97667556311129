import { ViewStyle } from 'react-native'

const styles = {
  title: {
    marginBottom: 30,
  } as ViewStyle,
  input: {
    marginBottom: 30,
  } as ViewStyle,
  button: {
    marginTop: 30,
  } as ViewStyle,
}
export default styles
