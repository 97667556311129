import { useEffect } from 'react'
import { useServices } from '../../../../hooks/useServices/useServices'
import { Animal, Breeder } from '../../../../interfaces/Animal'

export const useUpdateAnimals = (props: any, rega: Breeder) => {
  const animal_id = (props.contextData?.format?.animal_id as Animal[]) ?? []
  const setFormat = props.contextData.setFormat
  const { AnimalService } = useServices()

  useEffect(() => {
    ;(async () => {
      try {
        if (animal_id?.[0] && rega?.id) {
          const animal = await AnimalService.getAnimal(animal_id[0].id!)
          const isSameRegaId = animal.breeder.id === rega.id
          if (!isSameRegaId && rega.id) {
            setFormat((currentState: any) => ({
              ...currentState,
              animal_id: [],
              rega_id: rega,
            }))
          }
        }
      } catch (error) {}
    })()
  }, [rega?.id])
}
