import { Appearance, ColorSchemeName } from 'react-native'
import { DefaultTheme } from '@react-navigation/native'

const tintColorLight = '#363d58'
const tintColorDark = '#fff'
const tintColorDarkOpaque = '#fff8'

export const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#ffffff',
  },
}

const primaryLightColor = '#04a9ae'
const whiteColor = '#ffffff'

const Color = {
  light: {
    primary: primaryLightColor,
    secondary: '#363d58',
    error: '#eb5b5b',
    success: '#4a934a',
    text: '#000',
    invertedText: whiteColor,
    background: whiteColor,
    translucent: '#00000055',
    softBackground: '#fafafa',
    tabIconDefault: '#555',
    loading: tintColorDarkOpaque,
    disabled: '#e2e2e2',
    placeholder: '#aaa',
    inactiveDot: '#555',
    pillBackground: '#E0EAEB',
    pillText: '#225F5C',
    tabBackground: '#EEF9FA',
    borderInputActiveColor: '#333',
    borderInputInactiveColor: '#555',
    buttonText: primaryLightColor,
  },
  dark: {
    primary: primaryLightColor,
    secondary: '#363d58',
    error: '#eb5b5b',
    success: '#4a934a',
    text: whiteColor,
    invertedText: whiteColor,
    background: '#000',
    translucent: '#00000055',
    softBackground: '#222222',
    headerBackground: '#363d58',
    headerTitleTintColor: whiteColor,
    tabIconDefault: '#555',
    disabled: '#e2e2e2',
    placeholder: '#aaa',
    inactiveDot: '#555',
    pillBackground: '#E0EAEB',
    pillText: '#225F5C',
    tabBackground: '#EEF9FA',
    borderInputActiveColor: '#111',
    borderInputInactiveColor: '#555',
    buttonText: whiteColor,
  },
  success: '#4a934a',
  warning: '#778727',
  error: '#ab0000',
}

export default Color
