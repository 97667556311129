import { useEffect, useState } from 'react'
import { Linking } from 'react-native'

interface Props {
  onHandleUrl: (url: string) => void
  currentScreen?: string
}

const useDeepLinking = (props: Props) => {
  const [data, setData] = useState<any>()

  useEffect(() => {
    const currentURL = window.location.href

    handleUrl(currentURL)
  }, [])

  const handleUrl = async (url: string) => {
    window.history.replaceState(null, '', '/')
    const data = await new Promise((resolve) => {
      setTimeout(async () => {
        const data = await props.onHandleUrl?.(url)
        resolve(data)
      }, 500)
    })

    setData(data)
  }
  return data
}

export default useDeepLinking
