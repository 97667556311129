import React, { useEffect, useState } from 'react'
import DocumentPicker from 'react-native-document-picker'
import EvidenceService from '../../services/EvidenceService'
import { Evidence } from '../../interfaces/Evidence'
import useMessage from '../../hooks/useMessage'
import IZLoading from '../../components/atomic-components/Loading'
import { DescriptionText } from '../../components/atomic-components/Text/variants'
import styles from './styles'
import IZView from '../../components/atomic-components/IZView'
import { useTranslation } from 'react-i18next'
import { SCREEN, STACKS } from '../../constants/screens'
import EvidenceDetailI18nKeys from './i18n/keys'
import moment from 'moment'
import EvidenceCard from './components/EvidenceCard'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import EvidenceInput from './components/EvidenceInput'


export default function EvidenceDetail(props: any) {

  const { t } = useTranslation(SCREEN.EvidenceDetail)

  const { case_id } = props

  const [evidences, setEvidences] = useState<Evidence[]>([])
  const [attachedFile, setAtacchedFile] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showMessage } = useMessage()


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const data = await EvidenceService.getEvidenceList(case_id)
        setEvidences(data)
      } catch (error) {
        showMessage({ content: t(EvidenceDetailI18nKeys.ERROR_EVIDENCE_LIST), type: 'error' })
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [attachedFile])


  const handleWebSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]
    setAtacchedFile(file)
  }

  const handleNativeSelection = async () => {
    try {
      const result = await DocumentPicker?.pickSingle({
        type: [DocumentPicker.types.allFiles],
      })

      setAtacchedFile(result)
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        console.info('Document selection cancelled')
      } else {
        console.error('Error selecting documents', err)
      }
    }
  }

  const handleFileUpload = async () => {
    if (!attachedFile) {
      console.error('You have to select a file to upload')
      return
    }

    const formData = new FormData()
    formData.append('evidence_file', attachedFile)
    formData.append('case_id', case_id)
    formData.append('evidence_description', attachedFile.name)
    formData.append('timestamp', (moment().format('YYYY-MM-DD')))

    try {
      await EvidenceService.createEvidence(formData)
      showMessage({ content: t(EvidenceDetailI18nKeys.SUCCESS_CREATE_EVIDENCE), type: 'success' })
      setAtacchedFile(null)
    } catch (error) {
      showMessage({ content: t(EvidenceDetailI18nKeys.ERROR_CREATE_EVIDENCE), type: 'error' })
    }
  }

  const removeEvidence = async (id: number) => {

    try {
      await EvidenceService.deleteEvidence(id)
      showMessage({ content: t(EvidenceDetailI18nKeys.SUCCESS_DELETE_EVIDENCE), type: 'success' })
      props.navigation.navigate(STACKS.CaseDetail, {
        screen: SCREEN.EvidenceDetail,
        case_id,
      })
    } catch (error) {
      showMessage({ content: t(EvidenceDetailI18nKeys.ERROR_DELETE_EVIDENCE), type: 'error' })
    }
  }


  return (

    <IZScreenContainer
      withScroll
      withFormat
      footer={<EvidenceInput t={t} attachedFile={attachedFile} handleWebSelection={handleWebSelection} handleFileUpload={handleFileUpload} handleNativeSelection={handleNativeSelection} />
      }>
      {isLoading ? (
        <IZLoading style={{ marginTop: 20 }} />
      ) : (
        <IZView>
          <DescriptionText style={styles.title}>{t(EvidenceDetailI18nKeys.TEXT_EVIDENCES)}</DescriptionText>
          <IZView style={styles.cardContainer}>
            {evidences?.map((obj, index) => {
              return (
                <EvidenceCard
                  key={`evidence-${index}`}
                  date={moment(obj.timestamp).utcOffset('+01:00').format('DD-MM-YYYY')}
                  download={true}
                  name={obj.evidence_description}
                  url={obj.evidence_file_link}
                  onDelete={() => removeEvidence(obj?.id!)} />
              )
            })}
          </IZView>
          {attachedFile &&
            <IZView style={styles.cardContainer}>
              <DescriptionText style={{ marginTop: 5 }}>{t(EvidenceDetailI18nKeys.NEW_EVIDENCE)}</DescriptionText>
              <EvidenceCard
                date={(moment()).format('DD-MM-YYYY')}
                name={attachedFile.name}
                onDelete={() => setAtacchedFile(null)} />
            </IZView>
          }
        </IZView>
      )}
    </IZScreenContainer>
  )
}



