import React from 'react'
import { Modal } from 'react-native-paper'
import { ViewStyle } from 'react-native'
import View from '../View'
import { useOuterClick } from './hooks/useOuterClick'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const ModalWeb = (props: any) => {
  const innerRef: any = useOuterClick(props.onDismiss)
  const { modalContainerProps, contentContainerStyle, children, onDismiss, visible, modalContainerStyle } = props
  return (
    <Modal
      {...modalContainerProps}
      transparent={true}
      visible={visible}
      onRequestClose={onDismiss}
      onDismiss={onDismiss}
    >
      <View style={{ ...styles.centeredView, ...modalContainerStyle }}>
        <View
          style={{ ...styles.modalView, ...contentContainerStyle }}
          // @ts-ignore
          ref={innerRef}
        >
          {children}
        </View>
      </View>
    </Modal>
  )
}
const styles = {
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: getThemeColor(ColorKeys.translucent),
  } as ViewStyle,
  modalView: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  } as ViewStyle,
}
export default ModalWeb
