import React from 'react'
import IZButton from '../../../components/atomic-components/Button'
import prescriptionI18nKeys from '../i18n/keys'
import useModal from '../../../hooks/useModal'
import DeletePrescriptionModal from './DeletePrescriptionModal'
import useMessage from '../../../hooks/useMessage'
import PrescriptionService from '../../../services/PrescriptionService'
import { SCREEN } from '../../../constants/screens'

interface Props {
  t: any
  onAccept: any
}
const ButtonDeletePrescription = (props: Props) => {
  const { showModal } = useModal()
  const onHandlePressButtonDeletePrescription = () => {
    showModal({
      Component: (propsModal) => <DeletePrescriptionModal {...propsModal} t={props.t} />,
      title: props.t(prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_TITLE),
      headerStyle: { marginBottom: 20 },
      mode: 'normal',
      id: 'DeletePrescription',
      onAccept: props.onAccept,
    })
  }

  return (
    <IZButton onPress={onHandlePressButtonDeletePrescription} mode='outlined' style={{ minWidth: 100 }}>
      {props.t(prescriptionI18nKeys.MODAL_REMOVE_PRESCRIPTION_BUTTON_REMOVE)}
    </IZButton>
  )
}

export default ButtonDeletePrescription
