import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import AddPrescription from '.'
import { getCurrentTranslation } from '../../utils/i18n'

const ScreenName = SCREEN.AddPrescription

i18next.addResourceBundle('es', ScreenName, es)

const AddPrescriptionConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: AddPrescription,
  options: { headerTitle: getCurrentTranslation({ es }).TITLE, headerBackTitleVisible: true }
}

export default AddPrescriptionConfig
