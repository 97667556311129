import { TextStyle, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

const style = {
  pillContainer: {
    backgroundColor: getThemeColor(ColorKeys.pillBackground),
    paddingVertical: 5,
    paddingLeft: 8,
    paddingRight: 16,
    borderRadius: 5
  } as ViewStyle,
  removeIcon: {
    paddingLeft: 5,
    paddingTop: 1,
    fontWeight: 'bold'
  } as TextStyle
}
export default style
