import styled from 'styled-components'
import { FONT_FAMILY } from '../../../constants/fontFamily'
import {
  ColorKeys,
  getColorByBackground,
  getTextColorByBackground,
  getThemeColor,
} from '../../../utils/colors'
import { Button } from '../../../libraries/react-native'
import { ButtonProps } from 'react-native-paper'
import { Platform, TextStyle } from 'react-native'
export type IZButtonProps = ButtonProps
const IZButton = (props: IZButtonProps) => {
  const getBackgroundColor = () => {
    return props.disabled
      ? getThemeColor(ColorKeys.disabled)
      : props.buttonColor ?? getThemeColor(ColorKeys.primary)
  }
  const getColor = () => {
    return props.disabled
      ? getThemeColor(ColorKeys.disabled)
      : getThemeColor(ColorKeys.primary)
  }
  const buttonColor = getBackgroundColor()
  const borderColor = getColor()
  return (
    <Button
      {...props}
      mode={props.mode ?? 'contained'}
      //@ts-ignore
      style={{
        borderColor: borderColor,
        borderWidth: 1,
        maxWidth: Platform.OS === 'web' ? 335 : 1000,
        ...(props.style as any),
      }}
      labelStyle={{
        letterSpacing: 0.57,
        fontFamily: FONT_FAMILY.PRINCIPAL,
        fontWeight: 'bold',
        color: getColorByBackground(
          props.disabled ? ColorKeys.disabled : ColorKeys.buttonText,
          props.mode !== 'outlined'
            ? buttonColor
            : getThemeColor(
                ColorKeys.background
              )
        ),
        //@ts-ignore
        ...props.labelStyle,
      }}
      contentStyle={
        {
          paddingVertical: 2,
          borderRadius: 15,
          fontFamily: FONT_FAMILY.PRINCIPAL,
          fontWeight: 'bold',
          borderColor: buttonColor,
          //@ts-ignore
          ...props.contentStyle,
        } as TextStyle
      }
      //@ts-ignore
      underlineColorAndroid={'rgba(0,0,0,0)'}
      theme={{
        colors: {
          primary: buttonColor,
        },
        ...props.theme,
      }}
    />
  )
}

export default IZButton
