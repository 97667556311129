/* eslint-disable camelcase */
import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import {
  PasswordResetParams,
  EmailFormParams,
  PasswordValidateToken,
} from '../interfaces/passwordReset'
import ApiClient from './ApiClient'

class PasswordResetService {
  public static async passwordReset(props: EmailFormParams) {
    return ApiClient.post(BACKEND_BASE_URL_V1, 'password_reset', props)
  }

  public static async changePassword(props: PasswordResetParams) {
    return ApiClient.post(BACKEND_BASE_URL_V1, 'password_reset/confirm', props)
  }

  public static async validateToken(props: PasswordValidateToken) {
    return ApiClient.post(
      BACKEND_BASE_URL_V1,
      'password_reset/validate_token',
      props
    )
  }
}

export default PasswordResetService
