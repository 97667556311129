// import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from "react";
import { FONT_FAMILY } from "../constants/fontFamily";

// @ts-ignore
import Font from "../libraries/expo-font";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  async function loadResourcesAndDataAsync() {
    try {
      // SplashScreen.preventAutoHideAsync();
      // Load fonts
      await Font.loadAsync({
        [FONT_FAMILY.PRINCIPAL]: require("../assets/fonts/OpenSans.ttf"),
        [FONT_FAMILY.PRINCIPAL_600]: require("../assets/fonts/OpenSans.ttf"),
        [FONT_FAMILY.SECUNDARY]: require("../assets/fonts/Roboto.ttf"),
      });
    } catch (e) {
      // We might want to provide this error information to an error reporting service
    } finally {
      setLoadingComplete(true);
      // SplashScreen.hideAsync();
    }
  }
  useEffect(() => {
    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
