const localeES = {
    TITLE: "Evidencias",
    TEXT_EVIDENCES: "Evidencias",
    BUTTON_ADD_EVIDENCES: "Añadir evidencia",
    NEW_EVIDENCE: 'Nueva evidencia:',
    TEXT_VISITS: "Visitas",
    BUTTON_ADD_VISITS: "Añadir cita",
    CARD_EVIDENCE_DESCRIPTION: "Prueba: {{fileName}}",
    CARD_EVIDENCE_URL: "URL: {{url}}",
    ERROR_EVIDENCE_LIST: "Error obteniendo listado de evidencias",
    SUCCESS_CREATE_EVIDENCE: "Documento guardado correctamente",
    ERROR_CREATE_EVIDENCE: "Error al guardar el documento",
    SUCCESS_DELETE_EVIDENCE: "Documento eliminado correctamente",
    ERROR_DELETE_EVIDENCE: "No se ha podido eliminar el documento",
    BUTTON_SAVE_DOCUMENT: "Confirmar y guardar"
}

export default localeES
