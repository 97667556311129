import React, { useEffect, useState } from 'react'
import { View } from '../../../libraries/react-native'
import {
  Dimensions,
  ImageProps,
  ImageStyle,
  Platform,
  ViewStyle,
} from 'react-native'
import IMAGES from '../../../constants/images'
import Search from './Search'
import { Image } from 'react-native'
import IZButton from '../../../components/atomic-components/Button'
import AuthService from '../../../services/authService'
import { SCREEN, STACKS } from '../../../constants/screens'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { useNavigation } from '@react-navigation/native'
import { CommonActions } from '@react-navigation/native'
import medicalCasesI18nKeys from '../i18n/keys'
import { useTranslation } from 'react-i18next'

// Estilos para el componente HeaderPrincipal
const HeaderPrincipal = (props: any) => {
  const navigation: any = useNavigation()
  const { t } = useTranslation(SCREEN.MedicalCases)
  const onHandlePressButtonLogout = () => {
    AuthService.logout()
    const resetAction = CommonActions.reset({
      index: 0,
      routes: [{ name: STACKS.Auth }],
    })

    navigation.dispatch(resetAction)
  }
  const [width, setWidth] = useState(Dimensions.get('window').width)
  const handleDimensionsChange = ({ window }: any) => {
    const { width } = window
    setWidth(width)
  }

  useEffect(() => {
    Dimensions.addEventListener('change', handleDimensionsChange)
  }, [])

  const isMobileWeb = width < 690 && Platform.OS === 'web'
  return (
    <View
      style={{
        ...styles.container,
      }}
    >
      <Image
        style={{
          ...styles.logo,
        }}
        resizeMode="contain"
        source={IMAGES.LOGOTYPE_WHITE}
      />
      {(Platform.OS !== 'web' || width < 690) && (
        <IZButton mode="text" onPress={onHandlePressButtonLogout}>
          {t(medicalCasesI18nKeys.BUTTON_LOGOUT)}
        </IZButton>
      )}
      <Search />
      {Platform.OS === 'web' && width >= 690 && (
        <IZButton mode="text" onPress={onHandlePressButtonLogout}>
          {t(medicalCasesI18nKeys.BUTTON_LOGOUT)}
        </IZButton>
      )}
    </View>
  )
}

const styles = {
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -10,
    paddingTop: 15,
    paddingBottom: 15,
  } as ViewStyle,

  logo: {
    width: 163,
    height: 36,
    marginBottom: Platform.OS === 'web' ? 0 : 20,
    marginRight: Platform.OS === 'web' ? 0 : 20,
  } as ImageStyle,
}

export default HeaderPrincipal
