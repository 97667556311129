const localeES = {
  TITLE: 'Prescripciones',
  TEXT_PRESCRIPTION: 'Prescripciones',
  BUTTON_ADD_PRESCRIPTION: 'Añadir receta',
  PRESVET_STATE_Draft: 'Borrador',
  PRESVET_STATE_PresvetSend: 'Enviada a PresVet',
  PRESVET_STATE_Signed: 'Enviada a PresVet',
  PRESVET_STATE_PresvetOk: 'Correcta en PresVet',
  PRESVET_STATE_PresvetError: 'Error/incidencia en PresVet',
  PRESVET_STATE_PresvetRejected: 'Rechazada en PresVet',
  PRESVET_STATE_Prescribed: 'Prescrita',
  PRESVET_STATE_Dispensed: 'Dispensada ',
  PRESVET_STATE_Expired: 'Expirada',
  PRESVET_STATE_Canceled: 'Cancelada',

}

export default localeES
