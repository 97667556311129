import { Platform } from 'react-native'
//@ts-ignore
import utf8 from 'utf8'
//@ts-ignore
import base64 from 'base-64'

export const encode = (text: string) => {
  if (Platform.OS === 'web') {
    const utf8BytesCredentials = new TextEncoder().encode(text)
    const base64Credentials = btoa(String.fromCharCode(...utf8BytesCredentials))
    return base64Credentials
  } else {;
    var bytes = utf8.encode(text);
    var encoded = base64.encode(bytes);

    return encoded
  }
}
