import { useContext } from 'react'
import { MessageContext } from '../context/UserMessage.context'

/**
 * Custom hook for interacting with the modal context.
 * Provides access to modal state and methods for showing/hiding the modal.
 *
 * @returns {ModalContextProps} Object with modal state and methods.
 */
const useMessage = () => {
  const modalContext = useContext(MessageContext)

  /**
   * Show a message with the specified content and type.
   *
   * @param {Object} info - The message information.
   * @param {string} info.content - The content of the message.
   * @param {'error' | 'success' | 'warning'} info.type - The type of the message.
   */
  const showMessage = (messageProps: {
    content: string
    type: 'error' | 'success' | 'warning',
    duration?:number
  }) => {
    modalContext.showMessage(messageProps)
  }

  return {
    showMessage,
  }
}

export default useMessage
