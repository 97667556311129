import React from 'react'
import ReactSelect from 'react-select'
import { DropdownProps } from '../type'
import { FONT_FAMILY } from '../../../../constants/fontFamily'
import { ColorKeys, getThemeColor } from '../../../../utils/colors'

const DropdrownWeb = (props: DropdownProps) => {
  const editable = props.editable ?? true
  const value = props.options.filter((opt) =>
    [props.value].flat().find((val) => val === opt.value)
  )
  return (
    <ReactSelect
      value={value}
      isDisabled={!editable}
      isSearchable={editable}
      placeholder={'Selecciona'}
      options={props.options}
      onChange={props.onChange}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      isMulti={props.multiselect}
      styles={{
        control: (provided, state) => ({
          ...provided,
          fontFamily: FONT_FAMILY.PRINCIPAL,
          backgroundColor:
            props.editable === false
              ? getThemeColor(ColorKeys.disabled)
              : 'transparent',
          padding: 10,
          borderRadius: 8,
        }),
        option: (provided) => ({
          ...provided,
          zIndex: 100,
          fontFamily: FONT_FAMILY.PRINCIPAL,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontFamily: FONT_FAMILY.PRINCIPAL,
        }),
      }}
    />
  )
}

export default DropdrownWeb
