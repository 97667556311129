

export const mergeObjects = (objectA: any, objectB: any) => {
  const mergedObject = { ...objectA }

  for (const key in objectB) {
    const valueB = objectB[key]
    const valueA = mergedObject[key]

    if (typeof valueA === 'object' && typeof valueB === 'object') {
      mergedObject[key] = mergeObjects(valueA, valueB)
    } else {
      mergedObject[key] = valueB
    }
  }

  return mergedObject
}

export const fillObject = <T>(objectA: Partial<T>, objectB: T): T => {
  const objectC = { ...objectA } as any
  Object.entries(objectB as any).forEach(([key, value]) => {
    // @ts-ignore
    objectC[key] = objectA[key] ?? value
  })
  return objectC as T
}

export const filterNoUniqueObj =
  (valueExtractor: (obj: any) => any) =>
  (obj: any, index: number, arr: any[]) => {
    const extractedValue = valueExtractor(obj)
    const firstIndexFindValue = arr.findIndex(
      (arrObj) => valueExtractor(arrObj) === extractedValue
    )
    return firstIndexFindValue === index
  }

interface PrioritaryItemParams {
  value?: any
  stateValue?: any
  initialValue?: any
  defaultValue?: any
}
export const getPrioritaryItem = ({
  value,
  stateValue,
  initialValue,
  defaultValue,
}: PrioritaryItemParams) => {
  return value ?? stateValue ?? (initialValue || defaultValue)
}
export const toArray = (item: any) => {
  try {
    return [item].flat()
  } catch (error) {
    return []
  }
}

export const mergeArrayObjects = (arr: any[]) => {
  return arr.reduce((res, obj) => {
    Object.keys(obj).forEach((clave) => {
      res[clave] = obj[clave]
    })
    return res
  }, {})
}

