import { Platform, View } from "react-native"
import { Button } from "react-native-paper"
import { stylesWeb, stylesNative } from "./styles"

interface CustomHeaderProps {
  buttonText?: string
  onPress: () => void
  showNativeGoBack?: boolean
}

const CustomHeader: React.FC<CustomHeaderProps> = ({ buttonText, onPress, showNativeGoBack }) => {
  return (
    <>
      {Platform.OS === 'web' && !showNativeGoBack ? (
        <View style={stylesWeb.container}>
          <Button onPress={onPress} children={undefined} />
        </View>
      ) : Platform.OS !== 'web' && showNativeGoBack ? (
        <View style={stylesNative.container}>
          <Button onPress={onPress}>{buttonText}</Button>
        </View>
      ) : null}
    </>
  )
}

export default CustomHeader