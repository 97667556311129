import { useTranslation } from 'react-i18next'
import IZScreenContainer from '../../components/containers/IZScreenContainer'
import addDiagnosisI18nKeys from './i18n/keys'
import { SCREEN, STACKS } from '../../constants/screens'
import IZFormat from '../../components/templates/IZFormat'
import { DescriptionText } from '../../components/atomic-components/Text/variants'
import IZAdaptiveContainer from '../../components/containers/IZAdaptiveContainer'
import CaseDropboxAffectedSystems from '../../components/templates/CaseDropboxAffectedSystems'
import CaseDropboxDiagnosisType from '../../components/templates/CaseDropboxDiagnosisType'
import CaseDropboxEvolution from '../../components/templates/CaseDropboxEvolution'
import styles from './styles'
import CaseInputTimestamp from '../../components/templates/CaseInputTimestamp'
import IZCollapse from '../../components/containers/IZCollapse'
import CustomHeader from '../../components/CustomHeader'
import SaveButton from './components/SaveButton'

export default function AddDiagnosis({ navigation, route }: any) {
  const case_id = route?.params?.case_id
  const animal_id = route?.params?.animal_id
  const { t } = useTranslation(SCREEN.AddDiagnosis)


  return (
    <>
      <CustomHeader showNativeGoBack buttonText={t(addDiagnosisI18nKeys.BUTTON_GO_BACK)} onPress={() =>
        navigation.navigate(STACKS.CaseDetail, {
          screen: SCREEN.AddDiagnosis,
          case_id,
        })
      } />

      <IZScreenContainer
        withScroll
        withFormat
        footer={
          <SaveButton t={t} navigation={navigation} case_id={case_id}
          animal_id={animal_id}/>
        }
      >
        <DescriptionText style={{ marginTop: 20 }}>{t(addDiagnosisI18nKeys.NEW_DIAGNOSIS_EVOLUTION)}</DescriptionText>
        <IZAdaptiveContainer style={styles.container} itemsPerRow={3} minWidthPerItem={300}>
          <CaseInputTimestamp format="YYYY-MM-DDThh:mm:ssZ" />
        </IZAdaptiveContainer>

        <DescriptionText style={{ marginTop: 20 }}>{t(addDiagnosisI18nKeys.ADD_DIAGNOSIS)}</DescriptionText>
        <IZFormat.Input
          name='diagnosis'
          multiline
          placeholder={t(addDiagnosisI18nKeys.INPUT_PLACEHOLDER_DIAGNOSIS)}
          label={t(addDiagnosisI18nKeys.INPUT_PLACEHOLDER_DIAGNOSIS)}
          description={t(addDiagnosisI18nKeys.INPUT_DESCRIPTION_DIAGNOSIS)}
        />
        <IZCollapse headerTitle={t(addDiagnosisI18nKeys.INPUT_MORE_INFO)} initialExpanded style={styles.container}>
          <IZAdaptiveContainer minWidthPerItem={300}>
            <CaseDropboxAffectedSystems description={t(addDiagnosisI18nKeys.INPUT_DESCRIPTION_SYSTEM_AFFECTED)} label={t(addDiagnosisI18nKeys.INPUT_PLACEHOLDER_SYSTEM_AFFECTED)}/>
            <CaseDropboxDiagnosisType description={t(addDiagnosisI18nKeys.INPUT_DESCRIPTION_DIAGNOSIS_TYPE)} />
            <CaseDropboxEvolution description={t(addDiagnosisI18nKeys.INPUT_DESCRIPTION_EVOLUTION)} />
          </IZAdaptiveContainer>
        </IZCollapse>
      </IZScreenContainer>
    </>
  )
}
