import IZInputSearch from '../../../components/atomic-components/IZInputSearch'
import prescriptionI18nKeys from '../i18n/keys'
import styles from '../styles'
import TreatmentPrescribedOption from './TreatmentPrescribedOption'
import Medication from './Medication'
import { FilterProp } from '../../../components/atomic-components/IZInputSearch/types'
import { useServices } from '../../../hooks/useServices/useServices'
import { useEffect, useRef, useState } from 'react'
import useModal from '../../../hooks/useModal'
import ModalFilters from './ModalFilters'
import { Icon } from '../../../libraries/react-native'
import { Modal } from 'react-native-paper'
import { useOptionsMedicament, useOptionsSpecie } from '../../../hooks/useOptions'
import { debounce } from '../../../utils/api'

interface Props {
  t: (s: string) => string
  medication: string | undefined
  onMedicationChange: (s: any) => void
  breeder: string
  case_id: string
  specie: string
  editable?:boolean
}
const TreatmentPrescribed = (props: Props) => {
  const { t } = props

  const [filtersOptions, setFiltersOptions] = useState<any>(props.specie ? { specie: props.specie } : {})
  const { options, setSearchText, searchText } = useOptionsMedicament(filtersOptions)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const { optionsSpecie, isOptionsSpecieReady } = useOptionsSpecie()

  const onHandleSubmitFilters = (filters: any) => {
    setFiltersOptions(filters)
  }

  const {
    modal: { isVisible },
    showSecondaryModal,
  } = useModal()

  const filters: FilterProp[] = [
    {
      name: 'active_principle',
      type: 'text-input',
      label: 'Principio activo',
    },
    {
      name: 'nregister',
      type: 'text-input',
      label: 'Número de registro',
    },
    {
      name: 'indications',
      type: 'text-input',
      label: 'Indicaciones',
    },
    {
      name: 'waiting-time',
      type: 'text-input',
      label: 'Tiempo de espera',
    },
    {
      name: 'specie',
      type: 'dropdown',
      label: 'Especie',
      options: optionsSpecie,
    },
  ]

  const onHandlePressFilterIcon = (e: any) => {
    setFiltersVisible(!filtersVisible)
    props.editable && isOptionsSpecieReady &&
      showSecondaryModal({
        Component: (modalProps) => (
          <ModalFilters
            {...modalProps}
            filters={filters!}
            onSubmitFilters={onHandleSubmitFilters}
            filtersValues={filtersOptions}
            t={t}
          />
        ),
        modalContainerStyle: { justifyContent: 'center' },
        modalStyle: { alignItems: 'center' },
      })
  }

  return (
    <>
      <IZInputSearch
        options={options}
        editable={props.editable}
        searchText={searchText}
        onChangeSearchText={setSearchText}
        onChangeItem={props.onMedicationChange}
        emptyStateText={t(prescriptionI18nKeys.MEDICATION_EMPTY_STATE)}
        renderOptions={(option: any, index: any) => (
          <TreatmentPrescribedOption medication={option} t={t} index={index} />
        )}
        right={<Icon icon={'menu'} onPress={onHandlePressFilterIcon} />}
        placeholder={t(prescriptionI18nKeys.INPUT_SEARCH_PLACEHOLDER_MEDICINE)}
        description={t(prescriptionI18nKeys.INPUT_SEARCH_DESCRIPTION_MEDICINE)}
      />
      {props.medication && <Medication medication={props.medication} t={t} />}
    </>
  )
}

export default TreatmentPrescribed
