import { useEffect, useState } from 'react'
//@ts-ignore
import { IZInputProps } from '../IZInput'
import moment from 'moment'
import {
  DatePickerModal,
  TimePickerModal,
  es,
  registerTranslation,
} from 'react-native-paper-dates'
import { TouchableOpacity, View } from '../../../libraries/react-native'
import IZInput from '../IZInput'
import { ViewStyle } from 'react-native'
export type IZDatePickerProps = IZInputProps & {
  format?: string
  mode?: 'date' | 'time' | 'datetime'
  onChangeDate?: (date: string) => void
}

const IZDatePicker = (props: IZDatePickerProps) => {
  registerTranslation('es', es)
  const inputFormat = 'DD/MM/YYYY'
  const auxValue = moment(props.value, props.format).format(inputFormat)
  const value = auxValue !== 'Invalid date' ? auxValue : ''
  const [date, setDate] = useState(value)
  const isEditable = props.editable !== false

  useEffect(() => {
    setDate(value ?? '')
  }, [value])

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isDateOpen, setIsDateOpen] = useState(false)

  const handleDateConfirm = ({ date }: any) => {
    const momentDate = moment(date)
    const strDate = momentDate.format(props.format ?? 'YYYY-MM-DD')
    const dateInputStr = momentDate.format(inputFormat)
    props.onChangeDate?.(strDate)
    setSelectedDate(date)
    setDate(dateInputStr)
    setIsDateOpen(false)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{ width: '100%' }}
        onPress={() => {
          isEditable && setIsDateOpen(!isDateOpen)
        }}
      >
        <IZInput
          {...props}
          value={date}
          editable={isEditable}
          disabled={true}
        />
      </TouchableOpacity>
      <DatePickerModal
        locale="es"
        mode="single"
        visible={isDateOpen}
        onDismiss={() => setIsDateOpen(false)}
        date={selectedDate}
        onConfirm={handleDateConfirm}
      />
    </View>
  )
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
}

export default IZDatePicker
