export const SCREEN = {
  NotFound: 'NotFound',
  MedicalCases: 'MedicalCases',
  Login: 'Login',
  UpdatePassword: 'UpdatePassword',
  RecoverAccess: 'RecoverAccess',
  NewCase: 'NewCase',
  SummarizedDetail: 'SummarizedDetail',
  EvidenceDetail: 'EvidenceDetail',
  PrescriptionDetail: 'PrescriptionDetail',
  AddAnimal: 'AddAnimal',
  RegisterDetail: 'RegisterDetail',
  AddPrescription: 'AddPrescription',
  AddDiagnosis: 'AddDiagnosis',
  PrivacyPolicy: 'PrivacyPolicy',
  TermsAndConditions: 'TermsAndConditions',
} as const

export const STACKS = {
  Main: 'Main',
  Auth: 'Auth',
  CaseDetail: 'CaseDetail',
} as const
