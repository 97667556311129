import { ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'

export const styles = {
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: getThemeColor(ColorKeys.tabBackground),
  } as ViewStyle,
  contentContainer: { padding: 15, paddingVertical: 25 } as ViewStyle,
}
