import { useEffect, useState } from 'react'
import { Animal, AnimalSpecie, Breeder } from '../../interfaces/Animal'
import { useServices } from '../useServices/useServices'
import { debounce } from '../../utils/api'
import MedicamentService from '../../services/MedicamentService'
import { useTranslation } from 'react-i18next'
import { mergeArrayObjects } from '../../utils/objects'
import i18next from 'i18next'
import { DiagnosisType } from '../../interfaces/Case'
import localeES from './i18n/es'
import useOptionsI18nKeys from './i18n/keys'

const bundleUseOptions = 'useOptions'

export const useOptionsRega = () => {
  const [optionsRega, setOptionsRega] = useState<any[]>([])
  const [isOptionsRegaReady, setIsOptionsRegaReady] = useState<boolean>(false)
  const valueExtraction = (breeder: Breeder) => ({
    ...breeder,
    value: breeder?.id!,
    label: breeder?.rega_id,
  })
  const { BreederService } = useServices()
  const initFunctionRega = async () => {
    if (!optionsRega.length) {
      const breeders = await BreederService.getBreedersList()
      const formatedBreeders = breeders?.map(valueExtraction)
      setOptionsRega(formatedBreeders)
      setIsOptionsRegaReady(true)
      return formatedBreeders
    }
  }

  useEffect(() => {
    initFunctionRega()
  }, [])
  return { optionsRega, isOptionsRegaReady, initFunctionRega }
}

export const useOptionsAnimalID = () => {
  const [optionsAnimalID, setOptionsAnimalID] = useState<any[]>([])
  const [isOptionsAnimalIDReady, setIsOptionsAnimalIDReady] =
    useState<boolean>(false)
  const valueExtraction = (animal: Animal) => ({
    ...animal,
    value: animal?.id!,
    label: animal?.identifier,
  })
  const { AnimalService } = useServices()
  const initFunctionAnimalID = async () => {
    if (!optionsAnimalID.length) {
      const animals: Animal[] = await AnimalService.getAnimals()

      const formatedAnimals = animals.map(valueExtraction)
      setOptionsAnimalID(formatedAnimals)
      setIsOptionsAnimalIDReady(true)
      return formatedAnimals
    }
  }
  useEffect(() => {
    initFunctionAnimalID()
  }, [])
  return { optionsAnimalID, isOptionsAnimalIDReady, initFunctionAnimalID }
}

export const useOptionsMedicament = (filtersOptions?: any) => {
  const { AnimalService } = useServices()
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  useEffect(
    debounce(async () => {
      const { specie, ...auxFilterOptions } = filtersOptions
      if (
        searchText.length > 2 ||
        !!specie ||
        !!Object.keys(auxFilterOptions).length
      ) {
        const specieName = specie
          ? await AnimalService.getSpecieName(specie)
          : undefined
        MedicamentService.getMedicamentList({
          ...auxFilterOptions,
          name: searchText,
          ...(!!specieName ? { specie: specieName } : {}),
        }).then((response: any) => {
          setOptions(response)
        })
      } else {
        setOptions([])
      }
    }, 500),
    [searchText, filtersOptions]
  )
  return { options, setSearchText, searchText }
}

export const useOptionsSpecie = () => {
  const { AnimalService } = useServices()
  const [optionsSpecie, setOptionsSpecie] = useState<any[]>([])
  const [isOptionsSpecieReady, setIsOptionsSpecieReady] =
    useState<boolean>(false)
  useEffect(() => {
    /**
     * Fetch and format a list of breeders for the input's options.
     */
    ;(async () => {
      const species: AnimalSpecie[] = await AnimalService.getSpecies()
      const defaultSpecie = {
        value: '',
        label: '---',
      }
      const formatedBreeders = species.map((specie: AnimalSpecie) => ({
        value: `${specie.id}`,
        label: `${specie.rega_specie_name}`,
      }))
      setOptionsSpecie([defaultSpecie, ...formatedBreeders])
      setIsOptionsSpecieReady(true)
    })()
  }, [])

  return { optionsSpecie, isOptionsSpecieReady }
}

export const useOptionsAffectedSystems = () => {
  i18next.addResourceBundle('es', bundleUseOptions, localeES)
  const { t } = useTranslation(bundleUseOptions)

  const { CaseService } = useServices()
  const [options, setOptions] = useState<{ value: any; label: string }[]>([])
  useEffect(() => {
    ;(async () => {
      try {
        const unformatedOptions = await CaseService.getAffectedSystem()
        const mergedUnformatedOptions = mergeArrayObjects(unformatedOptions)
        const formatedOptions = Object.entries(mergedUnformatedOptions).map(
          ([key, value]: any) => {
            const i18nKey = `INPUT_SYSTEM_OPTION_${key.toUpperCase()}`
            return {
              value: key,
              label: (useOptionsI18nKeys as any)[i18nKey]
                ? t((useOptionsI18nKeys as any)[i18nKey])
                : value,
            }
          }
        )
        setOptions([
          {
            label: t(useOptionsI18nKeys.INPUT_SYSTEM_OPTION_DEFAULT),
            value: '',
          },
          ...formatedOptions,
        ])
      } catch (error) {}
    })()
  }, [])

  return { optionsAffectedSystems: options }
}

export const useOptionsDiagnosisType = () => {
  i18next.addResourceBundle('es', bundleUseOptions, localeES)
  const { t } = useTranslation(bundleUseOptions)
  const { CaseService } = useServices()
  const [options, setOptions] = useState<
    { value: DiagnosisType; label: string }[]
  >([])
  useEffect(() => {
    ;(async () => {
      try {
        const unformatedOptions = await CaseService.getTypeDiagnosis()
        const mergedUnformatedOptions = mergeArrayObjects(unformatedOptions)
        const formatedOptions = Object.entries(mergedUnformatedOptions).map(
          ([key, value]: any) => ({
            value: key,
            label: (useOptionsI18nKeys as any)[
              `INPUT_TYPE_DIAGNOSIS_OPTION_${key.toUpperCase()}`
            ]
              ? t(
                  (useOptionsI18nKeys as any)[
                    `INPUT_TYPE_DIAGNOSIS_OPTION_${key.toUpperCase()}`
                  ]
                )
              : value,
          })
        )
        setOptions([
          {
            label: t(useOptionsI18nKeys.INPUT_TYPE_DIAGNOSIS_OPTION_DEFAULT),
            value: '',
          },
          ...formatedOptions,
        ])
      } catch (error) {}
    })()
  }, [])
  return { optionsDiagnosisType: options }
}
