import IZCard from '../../../components/atomic-components/IZCard'
import { DescriptionText, Link } from '../../../components/atomic-components/Text/variants'
import styles from '../styles'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../../constants/screens'
import { Button } from 'react-native-paper'
import { Linking, Platform, View } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { FaDownload } from "react-icons/fa"

interface Props {
  date: string
  url?: string
  name: string
  onDelete?: () => void
  download?: boolean
}

const EvidenceCard = ({ date, name, url, onDelete, download }: Props) => {
  const { t } = useTranslation(SCREEN.EvidenceDetail)

  const showDownloadBtn = download || false

  const handleLinkPress = () => {
    Linking.openURL(url?.toString()!)
  }

  return (
    <IZCard style={styles.cardContainer}>
      <View style={styles.textContainer}>
        <View style={{ width: 300 }}>
          <DescriptionText style={styles.cardDescriptionDate}>{date}</DescriptionText>
          <Link onPress={handleLinkPress}>
            {name}
          </Link>
        </View>

        <View style={styles.buttonRemoveContainer}>
          {showDownloadBtn ? (
            <Button
              icon={Platform.OS !== 'web' ? 'arrow-down' : FaDownload}
              textColor={getThemeColor(ColorKeys.primary)}
              onPress={handleLinkPress}
              children={undefined} />
          ) : <Button disabled children={undefined} />}
          <Button
            icon={'close'}
            textColor={getThemeColor(ColorKeys.primary)}
            onPress={onDelete}
            children={undefined} />
        </View>
      </View>
    </IZCard>
  )
}

export default EvidenceCard