import React from 'react'
import IZFormat from '../../../components/templates/IZFormat'
import addDiagnosisI18nKeys from '../i18n/keys'
import { SCREEN, STACKS } from '../../../constants/screens'
import useMessage from '../../../hooks/useMessage'
import { useServices } from '../../../hooks/useServices/useServices'

interface Props {
  t: (s: string) => string
  navigation: any
  case_id: number
  animal_id: number
}
const SaveButton = (props: Props) => {
  const { showMessage } = useMessage()
  const { CaseService } = useServices()

  const onHandlePressCreateCaseDetails = async (format: any) => {
    try {
      await CaseService.createCaseDetails({
        case_id:props.case_id,
        animal_id: props.animal_id,
        ...format,
      })
      showMessage({
        content: props.t(addDiagnosisI18nKeys.CASE_DETAILS_CREATE_SUCCESS),
        type: 'success',
      })
      setTimeout(() => {
        props.navigation.navigate(STACKS.CaseDetail, {
          screen: SCREEN.SummarizedDetail,
          case_id:props.case_id,
        })
      }, 2000)
    } catch (error) {
      showMessage({
        content: props.t(addDiagnosisI18nKeys.CASE_DETAILS_CREATE_ERROR),
        type: 'error',
      })
    }
  }
  return (
    <IZFormat.Button onPress={onHandlePressCreateCaseDetails}>
      {props.t(addDiagnosisI18nKeys.BUTTON_SAVE)}
    </IZFormat.Button>
  )
}

export default SaveButton
