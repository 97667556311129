const FSWeb = {
  downloadFile: ({ fromUrl, toFile }: { fromUrl: string; toFile: string }) => {
    const a = document.createElement('a')
    a.href = fromUrl
    a.download = toFile
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  readFile: async(file: any, type?: string) => {
    return await new Promise(async(resolve)=>{
      const reader = new FileReader()
      reader.onload = async (e:any) => { 
        const text = (e.target.result)
        resolve(text)
      };
      reader.readAsBinaryString(file)

    })
  },
}

export default FSWeb
