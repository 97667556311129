import { ValueLabel } from '../types'

export const getValueExtracted = (
  value: any,
  valueExtraction: ((x: any) => ValueLabel) | undefined
) => {
  if (Array.isArray(value)) {
    return value?.map((item: any) => valueExtraction?.(item)) ?? value
  } else {
    return (value && valueExtraction?.(value)) || value
  }
}
