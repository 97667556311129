import { DescriptionText } from '../../../components/atomic-components/Text/variants'
import styles from '../styles'
import IZAdaptiveContainer from '../../../components/containers/IZAdaptiveContainer'
import AnimalInputRega from '../../../components/templates/AnimalInputRega'
import summarizedDetailI18n from '../i18n/keys'
import CaseInputTimestamp from '../../../components/templates/CaseInputTimestamp'
import CaseInputDiagnosis from '../../../components/templates/ CaseInputDiagnosis'
import AnimalIdentifierInputSearch from '../../../components/templates/AnimalIdentifierInputSearch'
import SaveButton from './SaveButton'
import { Case } from '../../../interfaces/Case'
import { useServices } from '../../../hooks/useServices/useServices'
import StateWrapper from '../../../components/wrappers/StateWrapper'
import IZCollapse from '../../../components/containers/IZCollapse'
import CaseDropboxAffectedSystems from '../../../components/templates/CaseDropboxAffectedSystems'
import CaseDropboxDiagnosisType from '../../../components/templates/CaseDropboxDiagnosisType'
import { useOptionsAnimalID, useOptionsRega } from '../../../hooks/useOptions'
import AnimalIDFooter from '../../NewCase/components/AnimalIDFooter'
import navigation from '../../../navigation'

interface Props {
  case_id: any
  t: (s: string) => string
  route: any
  navigation: any
}
const CaseDetails = (props: Props) => {
  const { case_id, t } = props
  const { CaseService } = useServices()
  const { initFunctionRega } = useOptionsRega()
  const { initFunctionAnimalID } = useOptionsAnimalID()
  const getInitialForm = async () => {
    try {
      const cases: Case = await CaseService.getCase(case_id!)
      const initialFormat = {
        animal_id: cases.animal,
        rega: cases.animal?.[0]?.breeder,
        timestamp: cases.timestamp,
        diagnosis: cases.diagnosis,
        affected_system: cases.affected_system,
        diagnosis_type: cases.diagnosis_type,
      }
      const optionsRega = await initFunctionRega()
      const optionsAnimalID = await initFunctionAnimalID()
      return { initialFormat, optionsRega, optionsAnimalID }
    } catch (error) {}
  }

  return (
    <StateWrapper initialFunction={getInitialForm}>
      {({ initialFormat, optionsRega, optionsAnimalID }) => (
        <>
          <DescriptionText style={styles.title}>
            {t(summarizedDetailI18n.TEXT_GENERAL_INFORMATION)}
          </DescriptionText>
          {initialFormat && (
            <>
              <IZAdaptiveContainer
                style={{ marginTop: 22 }}
                itemsPerRow={1}
                itemSeparation={22}
              >
                <AnimalInputRega
                  initialValue={initialFormat.rega}
                  label={t(summarizedDetailI18n.INPUT_LABEL_REGA)}
                  description=""
                  options={optionsRega}
                />

                <AnimalIdentifierInputSearch
                  initialValue={initialFormat.animal_id}
                  label={t(summarizedDetailI18n.INPUT_LABEL_ANIMAL_ID)}
                  options={optionsAnimalID}
                  footer={
                    <AnimalIDFooter
                      navigation={props.navigation}
                      route={props.route}
                    />
                  }
                />
                <CaseInputDiagnosis initialValue={initialFormat.diagnosis} />
                <CaseInputTimestamp
                  initialValue={initialFormat.timestamp}
                  format="YYYY-MM-DDThh:mm:ssZ"
                />
              </IZAdaptiveContainer>
              <IZCollapse
                headerTitle={t(summarizedDetailI18n.COLLAPSE_MORE_INFO)}
                styleContainer={{ padding: 0 }}
                style={{ marginTop: 20 }}
                initialExpanded
              >
                <IZAdaptiveContainer>
                  <CaseDropboxAffectedSystems
                    initialValue={initialFormat.affected_system}
                  />
                  <CaseDropboxDiagnosisType
                    initialValue={initialFormat.diagnosis_type}
                  />
                </IZAdaptiveContainer>
              </IZCollapse>
            </>
          )}
          <SaveButton case_id={case_id} t={t} />
        </>
      )}
    </StateWrapper>
  )
}

export default CaseDetails
