import { FONT_FAMILY } from '../../../constants/fontFamily'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import { Text } from '../../../libraries/react-native'

const getFontFamily = ({
  fontFamily,
  fontWeight,
}: {
  fontFamily?: string
  fontWeight?: string
}) => {
  if (fontFamily && fontWeight) {
    return (
      Object.values(FONT_FAMILY).find(
        (fm) => fm === `${fontFamily}-${fontWeight}`
      ) ?? fontFamily
    )
  } else if (fontFamily) {
    return fontFamily
  } else if (fontWeight) {
    return (
      FONT_FAMILY[`PRINCIPAL_${fontWeight}` as keyof typeof FONT_FAMILY] ??
      FONT_FAMILY.PRINCIPAL
    )
  } else {
    return FONT_FAMILY.PRINCIPAL
  }
}

const TextStyled = (props: any) => {
  const textStyle = {
    color: props.color
      ? props.color
      : getThemeColor(ColorKeys.text, props.scheme),
    fontSize: 14,
    //@ts-ignore
    fontFamily: getFontFamily({
      fontFamily: props.fontFamily,
      fontWeight: props.fontWeight,
    }),
    textAlign: props.alignJustify ? 'justify' : 'left',
    fontWeight:
      props.title || props.bold || props.fontWeight
        ? props.fontWeight ?? 'bold'
        : 'normal',
    ...props.style,
  }

  return <Text {...props} style={textStyle} />
}

export default TextStyled
