import { useTranslation } from 'react-i18next'
import {
  DescriptionText,
  Title,
} from '../../components/atomic-components/Text/variants'
import AuthContainer from '../../components/containers/AuthContainer'
import { SCREEN } from '../../constants/screens'
import privacyPolicyI18nKeys from './i18n/keys'
import { ScrollView } from 'react-native'
import { ColorKeys, getThemeColor } from '../../utils/colors'
import { Icon, View } from '../../libraries/react-native'
import { NavigationInjectedProps } from 'react-navigation'
import styles from './styles'

interface Props extends NavigationInjectedProps {}
export default function PrivacyPolicy(props: Props) {
  const { t } = useTranslation(SCREEN.TermsAndConditions)
  return (
    <AuthContainer>
      <View style={styles.headerContainer}>
        <View style={styles.iconContainer}>
          <Icon
            color={getThemeColor(ColorKeys.primary)}
            icon="arrow-left"
            onPress={() => props.navigation.goBack()}
          />
        </View>
        <Title
          color={getThemeColor(ColorKeys.primary)}
          style={{ fontSize: 20 }}
        >
          {t(privacyPolicyI18nKeys.TITLE)}
        </Title>
        <View />
      </View>
      <ScrollView>
        <DescriptionText>{t(privacyPolicyI18nKeys.TEXT)}</DescriptionText>
      </ScrollView>
    </AuthContainer>
  )
}
