const localeES = {
  TITLE: 'Términos y Condiciones ',
  TEXT: `

  Última actualización: 06/02/2024 
  
  Bienvenido/a a Consuvet. Estos términos y condiciones de uso rigen tu acceso y uso de la aplicación móvil Consuvet y todos los servicios relacionados, proporcionados por Manuel Fernandez con NIF 29087953Z. 
  
  1. Aceptación de los Términos 
  
  Al acceder o utilizar los Servicios de Consuvet, aceptas estos Términos en su totalidad. Si no estás de acuerdo con alguno de estos términos, no podrás acceder ni utilizar nuestros Servicios. 
  
  2. Uso de los Servicios 
  
  Alta como usuario: Para utilizar ciertas características de los Servicios, es posible que necesites registrarte y crear una cuenta. Debes proporcionar información precisa y actualizada durante el proceso de registro y mantenerla actualizada en todo momento.  
  
  Identificación/Autenticación del Usuario: Consuvet puede ofrecer servicios de identificación/autenticación del usuario para garantizar la seguridad y protección de tu cuenta. Al utilizar estos servicios, aceptas cumplir con cualquier requisito adicional y proporcionar la información necesaria para la identificación y autenticación según lo requerido por Consuvet. 
  
  Uso Adecuado: Te comprometes a utilizar los Servicios de Consuvet de manera legal, ética y de acuerdo con estos Términos, todas las leyes y regulaciones aplicables, incluyendo expresamente el Real Decreto 666/2023, de 18 de julio, por el que se regula la distribución, prescripción, dispensación y uso de medicamentos veterinarios. 
  
  Responsabilidad del Usuario: Eres responsable de mantener la confidencialidad de tu cuenta y contraseña, y aceptas ser responsable de todas las actividades que ocurran bajo tu cuenta. 
  
  3. Privacidad 
  
  El uso de los Servicios de Consuvet está sujeto a nuestra Política de Privacidad, que describe cómo recopilamos, almacenamos y utilizamos tus datos personales. Al utilizar nuestros Servicios, aceptas nuestras prácticas de privacidad según lo establecido en dicha Política de Privacidad. 
  
  4. Propiedad Intelectual 
  
  Derechos de Propiedad: Todos los derechos de propiedad intelectual de los Servicios de Consuvet y su contenido (incluyendo, pero no limitado a, software, diseño, texto, gráficos y logotipos) son propiedad de Consuvet o de sus licenciantes. 
  
  Licencia de Uso: Se te concede una licencia limitada, no exclusiva y no transferible para utilizar los Servicios de Consuvet de acuerdo con estos Términos. No tienes derecho a sublicenciar, transferir o distribuir los Servicios de ninguna manera. 
  
  5. Blockchain y Prescripciones 
  
  Registro en Blockchain: Las prescripciones emitidas a través de Consuvet se registran en blockchain utilizando la infraestructura de Alastria para garantizar la trazabilidad y autenticidad de las mismas. 
  
  Uso de Prescripciones: Las prescripciones emitidas a través de Consuvet son oficiales y deben ser utilizadas de acuerdo con las regulaciones y prácticas médicas aplicables. 
  
  Seguridad y Privacidad de los Datos: Para hacer uso de estos servicios, Consuvet emplea infraestructura y comparte datos con terceros. Nos comprometemos a garantizar en todo momento la seguridad del dato y la privacidad del mismo, cumpliendo con las normativas y estándares de protección de datos aplicables. 
  
  6. Limitación de Responsabilidad 
  
  En la máxima medida permitida por la ley, Consuvet no será responsable por ningún daño directo, indirecto, incidental, especial o consecuente, incluyendo, pero no limitado a, pérdida de beneficios, datos, uso o cualquier otro daño intangible, resultante del uso o la imposibilidad de utilizar los Servicios de Consuvet. 
  
  7. Modificaciones de los Términos 
  
  Nos reservamos el derecho de modificar o actualizar estos Términos en cualquier momento, y dichas modificaciones entrarán en vigor al ser publicadas en la aplicación. Es tu responsabilidad revisar periódicamente estos Términos para estar al tanto de cualquier cambio. 
  
  8. Ley Aplicable y Jurisdicción 
  
  Estos Términos se regirán e interpretarán de acuerdo con las leyes de España, sin tener en cuenta sus principios de conflicto de leyes. Cualquier disputa relacionada con estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales de España. 
  
  9. Contacto 
  
  Si tienes alguna pregunta o inquietud sobre estos Términos, puedes contactarnos en manuel.fernandez@consuvet.com. 
  
  Al utilizar los Servicios de Consuvet, aceptas cumplir con estos Términos. Si no estás de acuerdo con alguno de los términos establecidos aquí, por favor, no utilices nuestros Servicios. 
  
  Gracias por usar Consuvet. 
  
      `
};

export default localeES;
