import React, { useEffect, useState } from 'react'
import { TextInput } from 'react-native-paper'
import { ColorKeys, getThemeColor } from '../../../../utils/colors'
import { FONT_FAMILY } from '../../../../constants/fontFamily'
import { Icon, View } from '../../../../libraries/react-native'
import { Platform } from 'react-native'
import { IZInputProps } from '..'
import { DescriptionText } from '../../Text/variants'
import IZLoading from '../../Loading'

const HibridInput = (props: IZInputProps) => {
  const [value, setValue] = useState<string>('')
  const primaryColor = getThemeColor(ColorKeys.primary, props.scheme)
  const secondaryColor = getThemeColor(ColorKeys.secondary, props.scheme)
  const placeholderColor = getThemeColor(ColorKeys.placeholder, props.scheme)
  const errorColor = getThemeColor(ColorKeys.error, props.scheme)
  const backgroundColor = getThemeColor(
    props.editable === false ? ColorKeys.disabled : ColorKeys.background,
    props.scheme
  )

  useEffect(() => {
    !!props.value && setValue(props.value?.toString?.())
  }, [props.value])
  const inputStyles = {
    backgroundColor: backgroundColor,
    fontSize: 16,
    fontFamily: FONT_FAMILY.PRINCIPAL,
  }
  const contentStyle = {
    height: 150,
  }
  const theme = {
    colors: {
      primary: backgroundColor,
    },
    roundness: 7,
    ...props.theme,
  }
  const [showPassword, setShowPassword] = useState(true)
  const isPasswordShow = props.secureTextEntry && showPassword
  const password = props.value?.toString()?.replace(/[^ ]/g, '*')
  const isRightVisible =
    Platform.OS !== 'web' && (props.right ?? props.secureTextEntry)
  const isDiagnosis = props.name === 'diagnosis'
  const loadingConditionalRender = props.loading
    ? { render: () => <IZLoading /> }
    : {}

  return (
    <TextInput
      {...props}
      {...loadingConditionalRender}
      value={isPasswordShow && props.multiline ? password : value}
      disabled={props.disabled || props.editable === false || props.loading}
      secureTextEntry={isPasswordShow}
      selectionColor={primaryColor}
      //@ts-ignore
      errorStyle={{ color: errorColor }}
      inputStyle={inputStyles}
      placeholderTextColor={placeholderColor}
      underlineColor={placeholderColor}
      theme={theme}
      activeOutlineColor={secondaryColor}
      outlineColor={secondaryColor}
      onChangeText={(text) => {
        props.onChangeText?.(text)
        setValue(text)
      }}
      contentStyle={isDiagnosis && contentStyle}
      style={{
        paddingTop: isDiagnosis ? 10 : 0,
        backgroundColor,
        ...(props.style as any),
      }}
      mode={props.mode ?? 'outlined'}
      left={props.left}
      right={
        props.right ??
        (!!isRightVisible ? (
          //@ts-ignore
          <Icon
            icon={showPassword ? 'eye-off' : 'eye'}
            onPress={() => setShowPassword(!showPassword)}
          />
        ) : undefined)
      }
    />
  )
}

export default HibridInput
