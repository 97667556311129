import { IZInputSearchProps } from '../../../atomic-components/IZInputSearch/types'
import IZInputSearch from '../../../atomic-components/IZInputSearch'
import FormWrapper from '../wrappers/FormWrapper'

/**
 * FormatInputSearch is a formatted input search component that can be used to update formatting settings.
 *
 * @component
 * @param {IZInputProps} props - The props for configuring the FormatInputSearch component.
 * @param {string} [props.name] - An optional name for the input search used to identify it in formatting settings.
 * @param {Function} [props.onChangeText] - An optional callback function that is called when the input search text changes.
 * It receives the new text value as a parameter.
 * @returns {JSX.Element} A formatted input search element.
 */
const FormatInputSearch = (
  props: IZInputSearchProps & {
    name: string
  }
) => {
  const FormInputSearch = FormWrapper(IZInputSearch, {
    nameParamOnChange: 'onChangeItem',
    autoFillValue: props.value,
  })

  return <FormInputSearch {...props} />
}

export default FormatInputSearch
