import React from 'react'
import { View } from '../../../libraries/react-native'
import { DescriptionText, Subtitle } from '../../../components/atomic-components/Text/variants'
import { Platform, TextStyle, ViewStyle } from 'react-native'
import { ColorKeys, getThemeColor } from '../../../utils/colors'
import IZCard from '../../../components/atomic-components/IZCard'
import moment from 'moment'
import { Case } from '../../../interfaces/Case'
import { useTranslation } from 'react-i18next'
import { SCREEN } from '../../../constants/screens'
import medicalCasesI18nKeys from '../i18n/keys'
import { filterNoUniqueObj } from '../../../utils/objects'

interface Props {
  item: Case
  t: (s: string, x?: any) => string
}
const MedicalCaseDetail = ({ item: props, t }: Props) => {
  const identifier = props.animal
    ?.map((animal) => animal.identifier)
    .filter((obj) => !!obj)
    ?.join(', ')
  const batch = props.animal
    ?.map((animal) => animal.batch)
    ?.filter((obj) => !!obj)
    ?.join(', ')
  const rega_id = props.animal
    ?.map((animal) => animal.breeder?.rega_id)
    ?.filter(filterNoUniqueObj((animal) => animal.id))
    .filter((obj) => !!obj)
    ?.join(', ')

  return (
    <IZCard
      style={{
        paddingHorizontal: 0,
      }}
      key={props.id}
    >
      <View style={styles.title}>
        {identifier && (
          <DescriptionText style={styles.titleText}>
            {t(medicalCasesI18nKeys.TEXT_ID, {
              animal_identifier: identifier,
            })}
          </DescriptionText>
        )}

        {Platform.OS === 'web' && (
          <DescriptionText style={styles.dateText}>{moment(props.timestamp).format('DD-MM-YYYY')}</DescriptionText>
        )}
      </View>
      {!!batch && (
        <DescriptionText>
          {t(medicalCasesI18nKeys.TEXT_BATCH_ID, {
            batch_id: batch,
          })}
        </DescriptionText>
      )}
      <DescriptionText>
        {t(medicalCasesI18nKeys.TEXT_REGA, {
          rega_id: rega_id,
        })}
      </DescriptionText>
      {!!props?.diagnosis && (
        <DescriptionText>{t(medicalCasesI18nKeys.TEXT_DIAGNOSIS, { diagnosis: props?.diagnosis })}</DescriptionText>
      )}
      {Platform.OS !== 'web' && (
        <DescriptionText style={styles.dateText}>{moment(props.timestamp).format('DD-MM-YYYY')}</DescriptionText>
      )}
    </IZCard>
  )
}
const styles = {
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  dateText: {
    color: getThemeColor(ColorKeys.disabled),
  } as TextStyle,
  titleText: {
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
}
export default MedicalCaseDetail
