import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Signature } from '../interfaces/Signature'
import ApiClient from './ApiClient'

class SignatureService {
  public static setSignature = async (Signature: Signature, callback?: Function): Promise<Signature[]> => {
    return await ApiClient.post(BACKEND_BASE_URL_V1, 'signature/', Signature, callback)
  }
  public static sendSignature =async(prescriptionID:number, signature: string)=>{
    return await ApiClient.post(BACKEND_BASE_URL_V1, `presvet/send/${prescriptionID}`, undefined)
  }
}

export default SignatureService
