import React from 'react'
import { createContext, useState } from 'react'
import { ModalPropsAndroid, ViewStyle } from 'react-native'

const ModalContext = createContext({} as any)

export type ModalPropsWithoutIsVisible = {
  id?: string
  onAccept?: (props?: any) => void
  onCancel?: () => void
  Component?: (props: any) => JSX.Element
  title?: string
  mode?: 'fullscreen' | 'normal'
  modalProps?: any
  modalContainerProps?: ModalPropsAndroid
  modalContainerStyle?: ViewStyle
  headerStyle?: ViewStyle
  modalStyle?: ViewStyle
}
export type ModalProps = {
  isVisible: boolean
} & ModalPropsWithoutIsVisible

export type ModalContextProps = {
  modal: ModalProps
  showModal: (props?: ModalPropsWithoutIsVisible) => void
  hideModal: () => void
  secondaryModal: ModalProps
  showSecondaryModal: (props?: ModalPropsWithoutIsVisible) => void
  hideSecondaryModal: () => void
}

/**
 * Provides modal management functionality to components.
 *
 * @param {Object} props - The component properties.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The JSX element representing the modal provider.
 */
function ModalProvider(props: any) {
  const [modal, setModal] = useState<ModalProps>({
    isVisible: false,
    onAccept: () => {},
  })

  const [secondaryModal, setSecondaryModal] = useState<ModalProps>({
    isVisible: false,
    onAccept: () => {},
  })

  const showModal = (props: ModalPropsWithoutIsVisible) => {
    setModal({
      isVisible: true,
      ...props,
    })
  }

  const hideModal = () => {
    setModal({
      isVisible: false,
    })
    setSecondaryModal({
      isVisible: false,
    })
  }

  const showSecondaryModal = (props: ModalPropsWithoutIsVisible) => {
    setSecondaryModal({
      isVisible: true,
      ...props,
    })
  }

  const hideSecondaryModal = () => {
    setSecondaryModal({
      isVisible: false,
    })
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        showModal,
        hideModal,
        secondaryModal,
        showSecondaryModal,
        hideSecondaryModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
