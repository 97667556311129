import { ColorKeys, getThemeColor } from '../../../utils/colors'
import styles from '../styles'
import { Platform } from 'react-native'
import { View } from 'react-native'
import IZButton from '../../../components/atomic-components/Button'
import EvidenceDetailI18nKeys from '../i18n/keys'
import { TFunction } from 'i18next'

interface EvidenceProps {
  attachedFile: any,
  handleWebSelection: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleFileUpload: () => void,
  handleNativeSelection: () => void,
  t: TFunction
}

const EvidenceInput = ({ t, attachedFile, handleWebSelection, handleFileUpload, handleNativeSelection }: EvidenceProps) => {

  const commonButtonProps = {
    ...(!attachedFile && { buttonColor: getThemeColor(ColorKeys.background, 'light') }),
    style: styles.button,
  }

  const buttonLabel = attachedFile
    ? t(EvidenceDetailI18nKeys.BUTTON_SAVE_DOCUMENT)
    : t(EvidenceDetailI18nKeys.BUTTON_ADD_EVIDENCES)

  if (Platform.OS === 'web') {
    return !attachedFile ? (
      <View style={{ ...commonButtonProps.style }}>
        <input
          type="file"
          id="fileInput"
          onChange={handleWebSelection}
          style={{ display: 'none' }}
        />
        <label htmlFor="fileInput" style={{ maxWidth: 400 }}>
          <IZButton mode={!attachedFile ? 'outlined' : 'contained'} {...commonButtonProps} onPress={() => document.getElementById('fileInput')!.click()}>
            {buttonLabel}
          </IZButton>
        </label>
      </View>
    ) : (
      <IZButton {...commonButtonProps} onPress={handleFileUpload}>
        {t(EvidenceDetailI18nKeys.BUTTON_SAVE_DOCUMENT)}
      </IZButton>
    )
  } else {
    return (
      <IZButton {...commonButtonProps} onPress={!attachedFile ? handleNativeSelection : handleFileUpload}>
        {buttonLabel}
      </IZButton>
    )
  }
}

export default EvidenceInput