import { BACKEND_BASE_URL_V1 } from '../constants/servicesUrls'
import { Animal, Breeder } from '../interfaces/Animal'
import { fillObject } from '../utils/objects'
import { CallBackParams } from '../utils/service'
import ApiClient from './ApiClient'

class AnimalService {
  public static createAnimal = async (
    {
      batch,
      identifier,
      gender,
      batch_number,
      rega_id,
      specie_id,
    }: Omit<Animal, 'breeder'> & {
      rega_id: string
    },
    callback?: Function
  ): Promise<Animal> => {
    const breedersGetResponse: Breeder = await ApiClient.get(BACKEND_BASE_URL_V1, `breeders/${rega_id}`, callback)
    const breeder = breedersGetResponse
    const breeder_id = breeder.id

    if (batch_number == undefined || batch_number == null || batch_number == ('' as any)) {
      batch_number = 1
    } else {
      batch_number = Number(batch_number)
    }

    const animalRequest: Animal = {
      batch,
      identifier,
      gender,
      batch_number,
      breeder,
      breeder_id,
      specie_id,
    }

    return await ApiClient.post(BACKEND_BASE_URL_V1, 'animals/', animalRequest, callback)
  }

  public static getSpecies = async (callback?: Function) => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, 'specie', undefined, callback)
    return response
  }

  public static getSpecie = async (id: number, callback?: Function) => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, `specie/${id}`, undefined, callback)
    return response
  }

  public static getSpecieName = async (id: number, callback?: Function) => {
    const response = await this.getSpecie(id, callback)

    return response.rega_specie_name
  }

  public static getAnimals = async (callback?: Function) => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, 'animals', undefined, callback)
    return response
  }

  public static updateAnimal = async (
    { animal_id, ...animal }: Partial<Animal> & { animal_id: number },
    callback?: CallBackParams
  ) => {
    try {
      const animalResponse: Animal = await this.getAnimal(animal_id)
      animal = fillObject(animal, animalResponse)
      const caseDetailsList = await ApiClient.put(BACKEND_BASE_URL_V1, `animals/${animal_id}/`, animal, callback)
      return caseDetailsList
    } catch (error) {
      throw error
    }
  }

  public static getAnimal = async (animal_id: number, callback?: CallBackParams): Promise<Animal> => {
    const response = await ApiClient.get(BACKEND_BASE_URL_V1, `animals/${animal_id}`, undefined, callback)
    return response
  }
}

export default AnimalService
