import TextStyled from "..";
//@ts-ignore
import { TextStyle } from "react-native"



const Subtitle = (props:any) => {
  return (
    <TextStyled
      {...props}
      style={{ fontSize: 18, fontWeight: 'bold', ...props.style } as TextStyle}
    />
  );
};

export default Subtitle;
