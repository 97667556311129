import i18next from '../../i18n.config'
import { SCREEN } from '../../constants/screens'
import { navigationScreensConfigType } from '../../navigation/types'
import es from './i18n/es'
import NotFoundScreen from '.'
import { getCurrentTranslation } from '../../utils/i18n'

const ScreenName = SCREEN.NewCase

i18next.addResourceBundle('es', ScreenName, es)

const currentLanguage = getCurrentTranslation<typeof es>({es})
const NewCaseConfig: navigationScreensConfigType = {
  name: ScreenName,
  component: NotFoundScreen,
  options: { headerTitle: currentLanguage.TITLE, headerBackTitleVisible: true },
}

export default NewCaseConfig
