import { Animal } from '../../../../interfaces/Animal'
import IZCard from '../../../atomic-components/IZCard'
import { DescriptionText } from '../../../atomic-components/Text/variants'
import inputSearchAnimalIdentifierI18nKeys from '../i18n/keys'

interface Props {
  animal: Animal
  t: (s: string, data?: any) => string
}
const AnimalIDOption = (props: Props) => {
  return (
    <>
      <DescriptionText bold>ID: {props.animal.identifier}</DescriptionText>
      {!!props.animal.breeder.rega_id && (
        <DescriptionText>
          {props.t(inputSearchAnimalIdentifierI18nKeys.OPTION_TEXT_REGA, {
            rega_id: props.animal.breeder.rega_id,
          })}
        </DescriptionText>
      )}
    </>
  )
}

export default AnimalIDOption
